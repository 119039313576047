export const es = {
  message: {
    requiredField: 'Campo obligatorio',
    noRecords: '¡No se encontraron registros!',
    confirmDel: '¿Está seguro de que quiere borrar?',
    confirmApproval: '¿Está seguro de que desea aprobar esta ocurrencia?',
    noResultsFor: 'No hay resultados para'
  },
  login: {
    username: 'Usuario',
    password: 'Contraseña',
    login: 'Login',
    entrar: 'Iniciar sesión',
    descricao: 'Ingrese sus datos de acceso en el siguiente formulario:',
    logout: 'Cerrar sesión',
    myaccount: 'Cuenta'
  },
  exportarFicha: {
    author: 'autor',
    biodiversity_impact: 'impacto sobre la biodiversidad',
    chemical_control: 'control_químico',
    class: 'Clase',
    common_name: 'nombre_común',
    control_biological: 'control_biológico',
    data_bibliography: 'datos_bibliografía',
    economic_impact: 'impacto_económico',
    economic_use: 'uso_económico',
    family: 'familia',
    health_impact: 'impacto_salud',
    intro_data: 'datos_introducción',
    intro_description: 'descripción_introducción',
    invasion_preferential_environments: 'ambientes_preferenciales_invasión',
    kingdom: 'Reino',
    location: 'localidad',
    morphophysiology_description: 'descripción_morfofisiología',
    native_distribution_area: 'áreas_nativa_de_distribución',
    natural_environment: 'ambiente_natural',
    order: 'Órden',
    origin: 'origen',
    phyllum: 'Phyllum',
    physical_control: 'control_físico',
    prevention: 'prevención',
    scientific_name: 'nombre_científico',
    social_impact: 'impacto_social',
    species_id: 'id_especie',
    voc_cause_introduction: 'voc_causa_de_introducción',
    voc_diet: 'voc_dieta',
    voc_dispersal_routes: 'voc_rutas_dispersión',
    voc_dispersion_vectors: 'voc_vectores_dispersión',
    voc_form: 'voc_forma',
    voc_introduction_type: 'voc_tipo_introducción',
    voc_reproduction: 'voc_reproducción',
    voc_spread: 'voc_dispersión',
    world_invasive_place: 'sitios_invasión_mundo'
  },
  label: {
    voltar: 'Regresar',
    outro: 'Otro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acceda a la base de datos',
    conhecaNossaRede: 'Red Latino Americana',
    baseDadosNacional: 'Base de datos de Jamaica',
    politicaDeDados: 'Política de datos',
    comiteCientifico: 'Grupo de Trabajo',
    colabore: 'Aportar datos',
    especiesMarinhas: 'Especies marinas',
    peixesAquario: 'Peces de acuario',
    peixesAquicultura: 'Peces de acuicultura',
    pescaDesportiva: 'Pesca deportiva',
    pets: 'Animales de compañía',
    plantasForrageiras: 'Plantas forrajeras',
    plantasOrnamentais: 'Plantas ornamentales',
    usoFlorestal: 'Uso forestal',
    tutorial: 'Tutorial',
    especies: 'Especies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referencias',
    projetos: 'Proyectos',
    saibaMais: 'Más info',
    contida: 'Contenida',
    casual: 'Detectada en la naturaleza',
    estabelecida: 'Establecida',
    invasora: 'Invasor',
    exibirListagem: 'Mostrar listado',
    concluir: 'Terminar',
    fechar: 'Cerrar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clic aquí',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Mostrar 10 registros',
    exibir25Reg: 'Mostrar 25 registros',
    exibir50Reg: 'Mostrar 50 registros',
    exibir100Reg: 'Mostrar 100 registros',
    limpar: 'Limpiar',
    buscar: 'Buscar',
    addColaborador: 'Agregar colaborador/a',
    nome: 'Nombre',
    sobrenome: 'Apellido',
    aprovarColaborador: 'Aprobar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidad',
    instituicao: 'Institución',
    endereco: 'Dirección',
    estado: 'Estado',
    cidade: 'Ciudad',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Teléfono',
    telefone2: 'Teléfono 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Red social 1',
    midiaSocial2: 'Red social 2',
    midiaSocial3: 'Red social 3',
    observacoesAdicionais: 'Observaciones adicionales',
    adicionar: 'Agregar',
    editando: 'Editando',
    acoes: 'Acciones',
    usuarixs: 'Usuarios',
    adicionarUsuario: 'Agregar usuarix',
    descricao: 'Descripción',
    ativo: 'Activo',
    sim: 'Sí',
    nao: 'No',
    salvarUsuarix: 'Salvar usuarix',
    senha: 'Contraseña',
    observacoes: 'Observaciones',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrador',
    taxonomia: 'Taxonomía',
    nomeCientifico: 'Nombre científico',
    adicionarEspecie: 'Agregar especie',
    genero: 'Género',
    familia: 'Familia',
    ordem: 'Orden',
    classe: 'Clase',
    phylumDivisao: 'Phylum / División',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origen',
    descricaoInvalida: 'Descripción no válida',
    selecioneFamilia: 'Por favor, seleccione una familia.',
    selecioneOrdem: 'Por favor, seleccione un Orden.',
    selecioneClasse: 'Por favor, seleccione una Clase.',
    selecioneDivisao: 'Por favor, seleccione una División.',
    selectioneReino: 'Por favor, seleccione un Reino.',
    selectioneGenero: 'Por favor, seleccione un género.',
    addReferencia: 'Agregar referencia',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprobar referencia',
    salvarReferencia: 'Salvar referencia',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Localidad (Ex. Salvador, BA  /  Sudáfrica: Ciudad del Cabo)',
    localFiltro: 'Localidad',
    edicao: 'Edición (ex. 2 ed.)',
    editora: 'Editorial',
    revista: 'Revista',
    anoPublicacao: 'Año de publicación',
    meioDivulgacao: 'Medio de comunicación',
    volume: 'Volumen (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palabra clave',
    anoPublic: 'Año publicación',
    addProjeto: 'Agregar proyecto',
    aprovarProjeto: 'Aprobar Proyecto',
    salvarProjeto: 'Salvar proyecto',
    contato: 'Contacto',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descripción',
    dataInicio: 'Fecha de inicio',
    dataTermino: 'Fecha de finalización',
    comentarios: 'Comentarios',
    anoInicio: 'Año de inicio',
    anoTermino: 'Año de finalización',
    ocorrencias: 'Ocurrencias',
    exportarDados: 'Exportar datos',
    addOcorrencia: 'Agregar ocurrencia',
    visualizarOcorrencia: 'Ver ocurrencia',
    alterarOcorrencia: 'Modificar ocurrencia',
    ocorrenciasVinculadas: 'Ocurrencias vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Especie',
    aprovarOcorrencia: 'Aprobar ocurrencia',
    salvarOcorrencia: 'Salvar ocurrencia',
    colaborador: 'Colaborador(a)',
    municipio: 'Municipalidad',
    municipioSede: 'Municipalidad (sede)',
    municipioCentroide: 'Municipalidad (centroide)',
    referenciaLocal: 'Referencia local',
    referencia: 'Referencia',
    latitude: 'Latitud',
    longitude: 'Longitud',
    coordenadasCorrespondem: 'Las coordenadas corresponden a',
    distanciaAproximada: 'Distancia aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Cuenca hidrográfica',
    descricaoInvasao: 'Descripción de la invasión',
    abundancia: 'Abundancia',
    situacaoPopulacional: 'Situación poblacional',
    manejo: 'Manejo',
    dataEntrada: 'Fecha de ingreso',
    dataObservacao: 'Fecha de observación (ej. 2020 o 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'Es un ejemplar de museo o de herbario',
    selecioneTipo: 'Seleccione el tipo',
    herbario: 'Herbario',
    museu: 'Museo',
    numeroExemplaInstituicao: 'Número de ejemplar (institución)',
    numeroExemplarColetor: 'Número de ejemplar (colector)',
    selecioneOpcao: 'Seleccione una opción…',
    pointList1: 'A un punto de ocurrencia de uno o más individuos de la especie',
    pointList2: 'Al punto central de un grupo de organismos de la especie',
    pointList3: 'A un punto próximo de la ocurrencia, ubicado a una distancia aproximada de :',
    coordinatesSourceList1: 'A - Una unidad política o administrativa',
    coordinatesSourceList2: 'B - El sitio real de ocurrencia',
    ultimoRegistroBaseDados: 'Últimos registros en la base de datos',
    ultimosAcessos: 'Últimos accesos',
    fichaTecnicaEspecie: 'Ficha técnica de las especies',
    fichaTecnicaEspecieMMA: 'Ficha técnica de las especies MMA',
    fichaTecnicaEspecieMMA2: 'Ficha técnica de las especies MMA versión 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contenida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Datos insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponibles para exportación:',
    biologiaEcologia: 'Biología y ecología',
    usoEconomico: 'Uso económico',
    usoEconomicoDescricao: 'Uso económico - descripción',
    invasaoBiologica: 'Invasión biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nombre científico + sinónimos',
    nomesPopulares: 'Nombres comunes',
    reproducao: 'Reproducción',
    dispersao: 'Dispersión',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes preferenciales de invasión',
    tipoIntroducao: 'Tipo de introducción',
    causaIntroducao: 'Causa de introducción',
    localIntroducao: 'Sitio de introducción',
    anoIntroducao: 'Año de introducción',
    marinho: 'Marino',
    dulcicola: 'Agua dulce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar especie',
    salvarEspecie: 'Salvar especie',
    data: 'Data',
    confiabilidade: 'Confiabilidad',
    resultadosAnalisesRisco: 'Resultados del análisis de riesgo',
    risco: 'Riesgo',
    areaOrigem: 'Área de orígen',
    areaDistribuicaoNatural: 'Área de distribución natural',
    ambientesNaturais: 'Ambientes naturales',
    descricaoEspecie: 'Descripción de la especie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes preferenciales de invasión - descripción',
    outrosLocaisOndeEspecieInvasora: 'Otros sitios donde la especie se comporta como invasora',
    impactosEcologicos: 'Impactos ambientales',
    impactosEconomicos: 'Impactos económicos',
    impactosSociais: 'Impactos sociales',
    impactosSaude: 'Impactos sobre la salud',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nivel de confianza de la evaluación EICAT',
    referenciaEICAT: 'Referencia EICAT',
    dataEICAT: 'Fecha EICAT (ej. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nivel de confianza de la evaluación SEICAT',
    referenciaSEICAT: 'Referencia SEICAT',
    dataSEICAT: 'Fecha EICAT (ej. 2021)',
    digitarSomenteAno: 'Ingresar solamente el año - 2021',
    viasCDBCategorias: 'Vías CDB - categorías',
    viasIntroducao: 'Vías de introducción y dispersión',
    viasVetoresCDBSubcategorias: 'Vías y vectores CDB - subcategorías',
    vetoresIntroducao: 'Vectores de introducción y dispersión',
    introducao: 'Introducción',
    adicionarIntroducao: 'Agregar introducción',
    ano: 'Año',
    descricaoIntroducao: 'Descripción de la introducción',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Eliminar',
    medidasPreventivas: 'Medidas de prevención',
    medidasPreventivasMMA: 'Medidas de prevención MMA',
    medidasDeteccaoPrecoce: 'Medidas de detección temprana',
    controleMecanico: 'Control mecánico',
    controleQuimico: 'Control químico',
    controleBiologico: 'Control biológico',
    distribuicaoPais: 'Distribución en el país',
    vejaMapaOcorrencias: 'Vea el mapa de ocurrencias',
    ecologiaUso: 'Ecología y uso',
    introducaoDispersao: 'Introducción y dispersión',
    analiseRisco: 'Análisis de riesgo',
    nomeComum: 'Nombre común',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinónimos',
    autor: 'Autor',
    subespecie: 'Subespecie',
    autorSubespecie: 'Autor - subespecie',
    pendenteAnalise: 'Pendiente de análisis',
    cancelarVoltar: 'Cancelar / Volver',
    versaoAnterior: 'Versión anterior',
    versaoAtual: 'Versión actual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomía - Lista completa de especies',
    consultaEspeciesOrigemAmbientes: 'Especies, origen, ambientes de invasión e impactos',
    consultaEspeciesManejo: 'Especies y manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica de la especie sin ocurrencias',
    consultaEspeciesOcorrencias: 'Especies y ocurrencias',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocurrencias APP',
    dataCadastro: 'Fecha de registro',
    criadoPor: 'Creado por',
    nivelCerteza: 'Nivel de certidumbre',
    byApp: 'Por aplicación móvil',
    verDados: 'Ver datos',
    appuser: 'APPUser',
    profissao: 'Profesión',
    colobadorJaCadastrado: 'Colaborador ya registrado',
    especialidadeApp: 'Especialidad APP',
    cep: 'Código postal',
    categoriaCDB: 'Categoría CBD',
    subCategoriaCDB: 'Subcategoría CDB',
    mensagemEnviada: 'Mensaje enviado correctamente.',
    detectadaNatureza: 'Detectada en la naturaleza',
    erradicada: 'Erradicada',
    exportar: 'Exportar'
  },
  home: {
    destaque1: 'Base de Datos Nacional de Especies Exóticas Invasoras de Jamaica',
    destaqueDescricao1: 'Este sistema de bases de datos recibe aportes de personas comprometidas con la conservación de la biodiversidad. Lxo invitamos a recorrer nuestra página!',
    destaque2: 'Desde 2006 documentando especies exóticas invasoras y compartiendo la información online!',
    destaqueDescricao2: 'Este sistema de bases de datos fue desarrollado y se mantiene actualizado con recursos nacionales e internacionales y cuenta con un Grupo de Trabajo para apoyo.',
    destaque3: 'Qué incluye este sistema de información?',
    destaqueDescricao3: 'Administramos cuatro bases de datos interconectadas sobre especies y ocurrencias, colaboradorxs, referencias, y proyectos.',
    destaque4: 'Como funciona?',
    destaqueDescricao4: 'Puede consultar sobre especies exóticas invasoras que impactan el medio ambiente, sus puntos de ocurrencia en Ecuador, especialistas, proyectos y referencias.',
    titulo: 'Busque especies, filtre y exporte datos',
    conteudo: 'Listas de especies, características ecológicas, rango nativo de distribución, lugares donde la especie invade a nivel global, vectores y rutas de dispersión, análisis de riesgo, usos, opciones de manejo, localidades de ocurrencia en Brasil, referencias.',
    conteudoEspecies: 'Registro de informaciones detalladas acerca de las especies exóticas invasoras y potencialmente invasoras, su ecología, vías de introducción y dispersión, impactos, control y las publicaciones de referencia.',
    conteudoColaboradores: 'Registro de proveedores de datos. Si quiere entrar en contacto con quien proveyó la información sobre alguna especie o un sitio de ocurrencia en particular busque aquí.',
    conteudoReferencias: 'Lista de las referencias relacionadas con las especies incluidas en la base de datos.',
    conteudoProjetos: 'Proyectos de investigación y de manejo de especies exóticas invasoras en en Ecuador.',
    titulo1: 'Sepa más sobre algunas de las especies exóticas invasoras del Jamaica',
    conteudo1: 'Las especies exóticas invasoras pertenecen a todos los grupos de organismos y son introducidas de forma accidental o voluntaria por una variedad de razones, sus efectos también son muy diversos.'
  },
  areaParceiros: {
    titulo: 'Red de Bases de Datos sobre Especies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Red Latino Americana',
    paragrafo1: 'El origen de esta red de bases de datos se remonta al año 2004, como parte de la Red Temática sobre Especies Exóticas Invasoras de la Red Interamericana de Información sobre Biodiversidad (I3N-IABIN), establecida como una iniciativa de la Cumbre de las Américas.',
    paragrafo2: 'Cada país integrante de I3N designó un líder nacional cuya función era desarrollar una base de datos nacional sobre estándares comunes y compartir información sobre especies exóticas invasoras:',
    paragrafo2_1: '',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Profesor de la Universidad Nacional del Sur e investigador del CONICET (desde 2004);',
    marcador2: 'Brasil: Dra. Sílvia R. Ziller, Fundadora y Directora Ejecutiva del Instituto Horus de Desarrollo y Conservación Ambiental (desde 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción.',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Profesor de la Universidad de Costa Rica. Investigador en el Centro de Investigación en Biodiversidad y Biología Tropical. Co-coordinador de Flora Invasive and Naturalized of Central America.',
    marcador5: 'Ecuador: Dr. Diego Inclán, Director Ejecutivo del Instituto Nacional de Biodiversidad (desde 2021) y Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Jamaica: Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica;',
    marcador5_3: 'Paraguay: Hugo Fernando del Castillo, Guyra Paraguay (desde 2006);',
    marcador5_4: 'Uruguay: Dr. Ernesto Brugnoli, Profesor de la Universidad de la República, Montevideo (desde 2005), y el MSc. Marcelo Iturburu, Coordinador del Comité Nacional de Especies Exóticas Invasoras del Ministerio de Ambiente (desde 2018).',
    paragrafo3: 'La iniciativa IABIN se extendió hasta el año 2011 con financiamiento del Fondo para el Ambiente Mundial (GEF). Más allá del final de este proyecto, algunos de los líderes nacionales de la red mantuvieron las bases de datos nacionales en funcionamiento (Argentina, Brasil, Jamaica, Paraguay y Uruguay).',
    paragrafo4: 'En el año 2021, se recibió el apoyo de la iniciativa BioBridge, de la Secretaría de la Convención sobre Diversidad Biológica (CDB), para reforzar y actualizar las bases de datos de Argentina, Brasil, Paraguay y Uruguay. La interfase fue actualizada y se incorporaron nuevas funcionalidades.',
    paragrafo5: 'Las personas que han desarrollado la Base de Dados son:',
    marcador6: 'el Programador João Scucato, de Curitiba – PR, Brasil, que desarrolló la primera versión en Microsoft Access, 2004-2005;',
    marcador7: 'el Programador Alejandro Moreno, de Argentina, ha proveído soporte a las bases de datos muchas veces desde el 2006. Mantuvo copias de los archivos y rescató datos de Paraguay y Uruguay para esta nueva edición, además de desarrollar la',
    marcador7_1: 'Plataforma Común',
    marcador7_2: 'de los países de la red.',
    marcador8: 'el Diseñador Rafael Moura y por el Programador Thiago Lôbo, de Brasil, a través de la',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'en Florianópolis – SC, Brasil;',
    marcador9: 'el programador Leonardo Rotondano, responsable del desarrollo de la aplicación para teléfono celular utilizado para el aporte de ocurrencias de especies exóticas invasoras.',
    paragrafo6: 'La red espera ampliar su cobertura territorial incorporando otros países de la región de manera de ampliar la disponibilidad de información sobre especies exóticas invasoras para el conocimiento público, la gestión gubernamental y el manejo.',
    paragrafo7: 'Rede de Bases de Datos sobre Especies Exóticas Invasoras:'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de datos de Jamaica',
    titulo: 'Breve historiaórico de la Base de Datos de Espeécies Exóticas Invasoras de Jamaica (JISD)',
    paragrafo1: 'La base de datos de especies invasoras de Jamaica se originó a partir de la Red Interamericana de Información sobre Biodiversidad (IABIN), resultado de la Cumbre de las Américas de 1996 de la Organización de Estados Americanos. El Gobierno de Jamaica se unió a IABIN a fines de la década de 1990. Posteriormente, el Mecanismo de Intercambio de Información de Jamaica (CHM), ubicado en el Museo de Historia Natural de Jamaica, dependiente del Instituto de Jamaica, participó activamente en el Grupo de Trabajo Temático sobre Especies Invasoras de IABIN. A través de un proyecto de IABIN, el CHM de Jamaica recibió fondos para desarrollar la primera base de datos nacional de especies invasoras en 2001 - 2002. Hasta entonces la primera versión de la base de datos existía como un archivo de Excel.',
    paragrafo2: 'En 2006 - 2007, el financiamiento de IABIN y los fondos de contrapartida del Instituto de Jamaica facilitaron la creación de contenido de la base de datos y la migración de los registros a una versión de Microsoft Access. Además, se aplicaron a la base de datos los estándares para el ingreso de información desarrollados por IABIN.',
    paragrafo3: 'Posteriormente IABIN desarrolló una plataforma de base de datos de código abierto que los Estados miembros podían personalizar de acuerdo con las necesidades nacionales. Bajo el Proyecto del Plan de Acción y Estrategia Nacional de Biodiversidad (NBSAP), el CHM de Jamaica adoptó esa plataforma y se asoció con la División Nacional de Gestión de Datos Espaciales para organizar la administración de contenidos y el alojamiento de la base de datos. Antes del lanzamiento formal del JISD en 2015, nueve entidades acordaron contribuir periódicamente a la creación de contenido nacional para la base de datos. Además, el CHM de Jamaica organizó un nuevo taller de capacitación en ingreso de datos en 2018. El Programa de las Naciones Unidas para el Desarrollo proporcionó subvenciones a través del Proyecto NBSAP. En ese momento, la Base de Datos fue el primer portal completo de su tipo en la isla.',
    paragrafo4: 'Desde 2015, el JISD ha brindado información para ayudar a Jamaica a cumplir con sus obligaciones para con la Convención de las Naciones Unidas sobre la Diversidad Biológica. La participación de cada entidad en la red JISD aportó directamente información relevante para:',
    paragrafo5: 'La Meta Nacional de Biodiversidad 2016 - 2021 9: "Para 2021, se identificarán las vías de introducción y dispersión y las especies exóticas invasoras...";',
    paragrafo6: 'El Objetivo de Desarrollo Sostenible, Meta 15.8: "Prevenir las especies exóticas invasoras en los ecosistemas terrestres y acuáticos".',
    paragrafo7: 'Esta versión más reciente de la base de datos orienta al JISD para mejorar la creación de contenido, especialmente con imágenes y datos espaciales.',
    paragrafo8: 'Parceiros'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de datos',
    titulo: 'Conceptos y referencias',
    criterio: 'Criterios para la inclusión de especies',
    avaliacao: 'Evaluación y revisión de datos',
    validacao: 'Validación de datos',
    paragrafo1: 'A los efectos de este sistema de datos se adoptan las siguientes definiciones, de acuerdo con el Convenio de Diversidad Biológica:',
    marcador1: 'especie nativa: aquella que se encuentra dentro del área de distribución geográfica donde evolucionó y forma parte de una comunidad biológica que se considera natural o seminatural;',
    marcador2: 'especie exótica: aquella que se encuentra fuera de su área de distribución natural pasada o presente;',
    marcador3: 'especie exótica invasora: toda especie exótica que afecta o amenaza hábitats, ecosistemas o especies causando alteraciones en los ambientes naturales;',
    paragrafo2: '',
    paragrafo3: '',
    paragrafo4: '',
    paragrafo5_1: 'The controlled vocabulary on habitats that are more susceptible to invasion, pathways and vectors, biological habit, impacts, and control methods were defined in accordance with the terms used in the Global Invasive Species Database managed by the Invasive Species Specialist Group linked to the IUCN',
    paragrafo5_2: 'The main taxonomic references are taken from the systems proposed by the Missouri Botanical Garden',
    paragrafo5_3: ', and the Integrated Taxonomic Information System',
    paragrafo5_4: 'Supplementary references include the World Register of Marine Species',
    paragrafo5_5: 'the Global Invasive Species Database',
    paragrafo5_6: 'and the CABI Invasive Species Compendium',
    paragrafo6: 'Para cada punto de ocurrencia las especies se califican como:',
    marcador4: 'cuando su presencia se limita o está restringida a sistemas de cultivo o cría o vive dependiendo de manera directa de las personas, por ejemplo como animal de laboratorio, de compañía o de acuario, planta de jardín, sin evidencias de escape de individuos en ese sitio;',
    marcador5: 'cuando la especie fue vista en ambientes naturales o seminaturales, fuera de sistemas de cultivo o cría, todavía sin evidencias de haber establecido un núcleo poblacional;',
    marcador6: 'cuando la especie se reproduce de manera efectiva, conformando una población autosostenible pero se mantiene en un área limitada, próxima al sitio de introducción local;',
    marcador7: 'cuando la especie avanza, ya sea de manera autónoma o auxiliada por vector antrópicos, hacia otros sitios más allá del punto de introducción local;',
    marcador7_1: 'cuando la especie ya no existe en ese sitio a partir de intervenciones de manejo.',
    paragrafo7: 'Es importante notar que el estado de invasión es un atributo de la localidad de ocurrencia, no de las especies. De este modo, la misma especie puede comportarse como invasora en una localidad, estar contenida en otro sitio y haber sido detectada en la naturaleza en un tercero.',
    paragrafo8: 'Cada información de ocurrencia recibida queda siempre vinculada a la fuente, o sea, a la persona que la proveyó y/o a un trabajo científico, incluyendo unx o más autorxs. Otros datos son referenciados en los campos descriptivos a la medida de lo posible. En el caso de dudas, cuestionamientos o necesidad de más información sobre algún dato, la fuente puede ser indicada o consultada. El vínculo entre las informaciones y los proveedores de datos es, más que todo, reconocer la autoría y valorizar el trabajo y el apoyo de lxs colaboradorxs.',
    paragrafo8_en: '',
    paragrafo9_1: 'En el caso que tengas datos sobre especies exóticas invasoras para contribuir con la base de datos, por favor utilice la hoja Excel disponible en nuestro sitio web y envíela para ',
    paragrafo9_2: ' o haga contacto con nosostrxs.',
    paragrafo10: 'En caso de dudas o cuestionamientos, por favor envíe un correo para ',
    paragrafo11: 'La base de datos de especies exóticas invasoras de Jamaica incluye especies exóticas y criptogénicas detectadas en ambientes naturales o seminaturales del país, así como aquellas que, manteniéndose contenidas en sistemas de cultivo o cría cuentan con antecedentes como invasoras en otros países. Se incluyen, asimismo, especies que siendo nativas de alguna región del país hayan sido traslocadas a otras regiones, cuencas o ecosistemas. Todas las especies se encuentran asociadas a, por lo menos una localidad de ocurrencia en el país. En algunos casos esa localidad es el aeropuerto o el puerto de ingreso, cuando no se cuenta con datos posteriores a su importación. El foco de este sistema es la conservación de la diversidad biológica y, por ello, solo se incluyen en la base de datos especies exóticas que se comporten como malezas, plagas o patógenos de las personas o de los animales domésticos cuando, además, hayan sido detectadas todavía en la naturaleza o afectando a especies de la flora y fauna nativa y no tengan antecedentes internacionales en ese sentido. Más allá de eso, es muy frecuente que las especies que afectan la estructura, composición y funcionamiento de los ecosistemas naturales y seminaturales tengan también impactos sobre la economía, la salud y los valores culturales.',
    paragrafo12: '',
    paragrafo13: 'Las bases de datos de este sistema se mantienen actualizadas gracias a los aportes de información de colaboradorxs voluntarixs distribuidxs en todo el país. Nuestrxs colaboradorxs pueden contribuir con información sobre nuevas especies, nuevas localidades de ocurrencia de especies ya detectadas en el territorio nacional, sobre proyectos, especialistas o publicaciones en la materia. Cada información permanece vinculada a los datos de quien la proveyó, como un reconocimiento acerca de sus aportes al conocimiento del estado del problema de las invasiones biológicas en el país y como una forma de promover la comunicación directa entre personas con conocimiento e interés en la materia. . La información también se carga a partir de publicaciones y de ejemplares de museo y de herbario. Cada nueva localidad de ocurrencia es evaluada siguiendo estándares de validación compartidos con la Red Latinoamericana y del Caribe de bases de datos . Los datos se asocian con coordenadas geográficas que permiten mapear la distribución registrada para cada especie.',
    paragrafo14: 'Los administradores de la base de datos se ocupan de completar la información respecto de las características biológicas de cada especie, los ambientes preferenciales de invasión, vectores y vías de introducción y dispersión, impactos y técnicas de control, entro otros.',
    paragrafo15: 'La confiabilidad de los datos es una de las principales preocupaciones de los administradores de este sistema. La validación de la información se evalúa en dos niveles principales: respecto de la correcta identificación de cada taxón y en referencia con la exactitud del sitio al que se refiere cada dato. Para eso se utilizan estándares y criterios consensuados con el resto de los sistemas que conforman la Red de información sobre EEI.',
    paragrafo16: 'Criterios para validar la identidad taxonómica de cada taxón',
    marcador8: 'El proveedor del dato (autor de la publicación o colector del espécimen) puede considerarse un especialista del grupo en cuestión o tiene una experiencia equivalente?',
    marcador9: 'La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del organismo en cuestión?',
    marcador10: 'La ocurrencia está documentada en una publicación indexada y con evaluación de pares o en un documento técnico realizado o revisado por especialistas?',
    marcador11: 'La información se corresponde con un ejemplar de herbario o con un espécimen depositado en una colección de museo?',
    marcador12: 'La ocurrencia corresponde a una especie que ya estaba registrada para el país?',
    marcador13: 'El grupo al que pertenece la especie tiene una taxonomía estable y bien conocida?',
    marcador14: 'Las características de la especie permiten una determinación relativamente simple?',
    paragrafo17: 'Una respuesta positiva a al menos una de las preguntas 1, 2, 3 o 4 lleva directamente a la recomendación de considerar al dato como validado. En caso de responder negativamente a estas cuatro preguntas, la validación se obtendrá solamente con respuestas positivas a las tres últimas (5, 6 y 7).',
    paragrafo18: 'Criterios para validar la ubicación asociada a del sitio donde decimos que se detectó una especie:',
    marcador15: 'El dato incluye coordenadas geográficas? Se consideran tanto coordenadas tomadas directamente en el sitio de la observación como otras que correspondan a alguna referencia geográfica cercana (en el caso que esto se indique).',
    marcador16: 'Se incluye una descripción del sitio que permite su identificación confiable?',
    marcador17: 'La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del sitio?',
    marcador18: 'Las coordenadas geográficas provistas coinciden con la ubicación del sitio descripto y/o fotografiado?',
    marcador19: 'La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión?',
    marcador20: 'Las condiciones ecológicas del sitio, inferidas en función de la localización indicada, resultan compatibles con los requerimientos generales de nicho de la especie?',
    paragrafo19: 'El dato se considera validado, desde el punto de vista de su ubicación geográfica, si:',
    marcador21: 'se responde de manera afirmativa a las preguntas 1, 2 y/o 3, y 4 (el dato incluye coordenadas geográficas, se brinda una referencia inequívoca del sitio y/o se provee una fotografía que permite la identificación confiable de lugar y las coordenadas provistas coinciden con la ubicación del sitio descripto o fotografiado, o,',
    marcador22: 'se asigna una respuesta positiva o negativa a la pregunta 1 (se brindan o no coordenadas geográficas), una respuesta negativa a las preguntas 2 y 3 (no se describe en detalle el sitio ni se adjunta una fotografía que permita ubicarlo), pero se responde de manera positiva a las preguntas 5 y 6 (la especie ya fue citada para el país y la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, y las condiciones ecológicas del sitio resultan compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo20: 'La localización se considerará no validada si: ',
    marcador23: 'a- se responde de manera positiva a la pregunta 1 y a las preguntas 2 y/o 3, pero negativa a la 4 (las coordenadas no coinciden con el sitio descripto o fotografiado), o.  ',
    marcador24: 'b- se responde de manera negativa a las preguntas 2 y 3, y negativa a al menos una de las preguntas 5 y 6 (el dato no cumple con una o con ninguna de estas dos condiciones: 1- La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, 2- el sitio reúne condiciones ecológicas compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo21: 'Finalmente, el dato se considera validado en caso de pasar el análisis de validación de los dos criterios (taxonomía y ubicación). ',
    paragrafo22: 'La validación no se propone como una regla de inclusión o no de un dato, sino como un descriptor que acompaña a cada localidad de ocurrencia. Cada localidad de ocurrencia de cada especie se asocia al descriptor “dato validado” o “dato no validado”. Es importante entender que esta clasificación es temporaria, ya que un dato puede confirmarse y pasar a estar validado si cumple los criterios planteados en algún momento luego de haber sido cargado en el sistema. Para facilitar la visualización del estado de los datos, el sistema de mapeo de distribución de ocurrencias muestra las localidades validadas en color verde y no validadas en color gris.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Grupo de Trabajo',
    titulo: 'Grupo de Trabajo sobre Especies Exóticas Invasoras de Jamaica',
    paragrafo1: 'El Grupo de Trabajo sobre Especies Exóticas Invasoras se estableció para ayudar a Jamaica a cumplir con sus obligaciones en virtud del Convenio sobre la Diversidad Biológica (CDB) de las Naciones Unidas, específicamente el artículo 8 (h), que establece: "Prevenir la introducción, controlar o erradicar aquellas especies exóticas que amenazan los ecosistemas, hábitats o especies”.',
    paragrafo2: 'El Grupo de Trabajo adopta la Sección IV, (a)10 de la Decisión VI/23 del CDB y el anexo de la Decisión VI/23 sobre Principios Rectores para la Prevención, Introducción y Mitigación de Impactos de Especies Exóticas que Amenazan Ecosistemas, Hábitats o Especies (ver ',
    paragrafo3: 'Los principales objetivos del Grupo de trabajo sobre especies exóticas invasoras (IASWG, por sus siglas en inglés) incluyen:',
    marcador1: 'Proporcionar un foro de discusión sobre especies exóticas invasoras (EEI) y sus impactos potenciales o reales, principalmente en Jamaica pero también a nivel global;',
    marcador2: 'Preparar y mantener listas de especies exóticas i) invasoras y ii) potencialmente invasoras en Jamaica);',
    marcador3: 'Identificar y recomendar las mejores prácticas en cuanto a medidas de prevención, detección temprana, control y/o erradicación de EEI;',
    marcador4: 'Identificar brechas y hacer recomendaciones con respecto a las necesidades de investigación sobre EEI;',
    marcador4b: 'Revisar y hacer recomendaciones con respecto al desarrollo o revisión de políticas nacionales, estrategias, legislación, lineamientos, planes de manejo y proyectos relacionados con EEI, según se requiera ;',
    marcador4c: 'Asistir en el desarrollo de proyectos relacionados con EEI.',
    marcador4d: 'Asistir en la promoción de la educación pública y la concientización relacionada con los impactos reales y potenciales de las EEI, incluidos aquellos relacionados con la salud humana y con las medidas de control y erradicación',
    paragrafo4: 'La base de datos sobre especies invasoras de Jamaica (JISD, por sus siglas en inglés) avanza continuamente gracias a los aportes del Grupo de Trabajo sobre Especies Exóticas Invasoras de Jamaica (IASWG). La lista nacional de especies exóticas invasoras desarrollada por ese grupo orienta la carga de información en la Base de Datos sobre Especies Invasoras de Jamaica (JISD). El IASWG también ayuda en la validación de la información ingresada en la JISD',
    marcador5: '',
    marcador6: '',
    marcador7: '',
    marcador8: '',
    marcador9: '',
    marcador10: '',
    marcador11: ''
  },
  especiesInstitucional: {
    titulo: 'Especies',
    paragrafo1: 'Esta es la parte de la base de datos que contiene información acerca de las especies exóticas invasoras. Esos datos se están recopilando desde el año 2004, cuando se realizó el primer relevamiento nacional por parte del Ministerio de Medio Ambiente. La información es enviada por colaboradores diversos, incluyendo especialistas en distintos grupos biológicos que también ayudan a validar datos, y compiladas a partir de artículos científicos y publicaciones técnicas.',
    paragrafo2_1: 'Si tuviera datos de ocurrencias de esas especies o sobre especies exóticas invasoras aún no incluidas, ',
    paragrafo2_2: 'contribuya para actualizar esta base de dados',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Jamaica. Institute of Jamaica. Consultado en DD/MM/AAA.',
    paragrafo4_2: ' ',
    tutorialTitulo1: 'Como citar la base de datos',
    tutorialDescricao1: 'Este es el formato de referencia para la citación de la base de datos en publicaciones.',
    tutorialTitulo2: 'Use los filtros',
    tutorialDescricao2: 'Aquí tenemos algunas opciones para filtrar contenido.',
    tutorialTitulo3: 'Use los filtros: seleccione datos',
    tutorialDescricao3: 'Elija filtros y haga combinaciones para seleccionar datos por lugar, forma biológica, uso, grupo taxonómico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este es el número de especies exóticas invasoras en la base de datos resultante de las búsquedas con filtros.',
    tutorialDescricao5: 'Aquí puede elegir cuántos registros ver por página.',
    tutorialTitulo6: 'Lista de especies exóticas invasoras',
    tutorialDescricao6: 'Lista de todas las especies o resultados del uso de filtros. Haga clic sobre el nombre de la especie para ver los datos completos.',
    tutorialTitulo6_1: 'Exportar datos',
    tutorialDescricao6_1: 'Aquí puede filtrar datos y elegir el formato para exportar.',
    tutorialTitulo7: 'Taxonomía',
    tutorialDescricao7: 'Clasificación taxonómica de la especie seleccionada, nombres comunes, sinónimos, y una foto cuando fuera posible!',
    tutorialTitulo8: 'Ecología y uso',
    tutorialDescricao8: 'Descripción, biología y ecología de la especie seleccionada, de dónde viene, que ambientes invade y usos económicos.',
    tutorialTitulo9: 'Introducción y dispersión',
    tutorialDescricao9: 'Cómo llegó la especie al sitio donde es invasora, por qué fue introducida y cómo se dispersa a otros sitios.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientales, económicos, a la salud y culturales. Clasificación de impactos ambientales por el protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detección temprana, control mecánico, químico y biológico para la especie seleccionada.',
    tutorialTitulo12: 'Análisis de Riesgo',
    tutorialDescricao12: 'Resultados de análisis de riesgo para invasión biológica realizadas para la especie seleccionada.',
    tutorialTitulo13: 'Ocurrencias',
    tutorialDescricao13: 'Sitios, ambientes, áreas protegidas, estados donde la especie ocurre en el país y estadio de invasión – vea el mapa!',
    tutorialDescricao14: 'Lista de referencias usadas para compilar datos sobre la especie seleccionada y otras publicaciones sobre la especie.',
    tutorialTitulo15: 'Proyectos',
    tutorialDescricao15: 'Lista de proyectos de investigación y manejo que incluyen la especie seleccionada.'

  },
  colaboradoresInstitucional: {
    titulo: 'Busca por colaboradorxs',
    paragrafo1: 'Lxs colaboradorxs incluyen a quienes aportan datos para este sistema de información así como a quienes colectaron especímenes de museo o ejemplares de herbario, son autores de publicaciones que se utilizaron como fuentes de datos o ayudan en la identificación de especies o la validación de informaciones sobre las especies exóticas invasoras.',
    paragrafo2: 'La contribución de información sobre las ocurrencias de especies ya listadas es extremadamente relevante para mantener registros de distribución actualizados y detectar nuevos sitios de invasión, contribuyendo con iniciativas de gestión o manejo, así como para la divulgación del conocimiento científico.',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Jamaica. Institute of Jamaica. Consultado en DD/MM/AAA.',
    paragrafo4_2: ''
  },
  especiesMarinhasInstitucional: {
    titulo: 'Especies marinas',
    paragrafo1: 'Los ecosistemas marinos son ecosistemas acuáticos relacionados con el mar. El agua en estos ecosistemas tiene altas concentraciones de sal, como es el caso para los océanos y los mares, y para ecosistemas costeros como los manglares y los estuarios.',
    paragrafo2: 'Las vías de introducción más comunes para las especies marinas son el agua de lastre, las incrustaciones en cascos de buques y otras estructuras acuáticas, la liberación intencional y no intencional de animales de acuario y el comercio de animales ornamentales y de compañía.',
    paragrafo3: 'El mejillón verde asiático (Perna viridis) y el pez león (Pterois volitans) son dos ejemplos de especies marinas invasoras. El pez león no cuenta con predadores agresivos en las áreas que invade por lo que puede sobrepasar a las poblaciones de especies nativas con las que compite, amenazando la diversidad de peces de arrecife de Jamaica.',
    paragrafo3_1: 'El mejillón verde asiático, por su parte, obstruye y corroe cañerías de agua utilizadas en complejos industriales, se adhiere a equipamientos marinos, y amenaza la sustentabilidad de la producción de otras especies de moluscos de interés comercial.',
    paragrafo4: 'Lionfish - Pterois volitans',
    paragrafo4b: 'Foto: Anthony Chang'
  },
  especiesAguaDoceInstitucional: {
    titulo: 'Especies de agua dulce',
    paragrafo1: 'Los ecosistemas de agua dulce incluyen lagos, estanques, ríos, arroyos, manantiales, pantanos y marismas. En contraste con los ecosistemas marinos, sus aguas tienen bajos contenidos de sal.',
    paragrafo2: 'Una de las vías principales de introducción de especies de agua dulce es a través de la liberación intencional o el escape desde las instalaciones dedicadas a la acuicultura.',
    paragrafo3: 'La langosta australiana de pinzas rojas (Cherax quadricarinatus) y la tilapia del Nilo (Oreochromis niloticus niloticus) son dos especies inicialmente introducidas para la producción acuícola que pueden encontrarse ahora en los ambientes naturales.',
    paragrafo3_1: 'Otras vías de introducción incluyen la liberación de animales de acuario y el escape de plantas acuáticas ornamentales, como por ejemplo el camalote (Eichhornia crassipes).',
    paragrafo4: 'Black River - Upper Morass',
    paragrafo4b: 'Foto: Keron Campbell'
  },
  especiesTerrestresInstitucional: {
    titulo: 'Especies terrestres',
    paragrafo1: 'Los ecosistemas terrestres se encuentran en tierra firme y contrastan con los ecosistemas acuáticos porque en ellos predomina el suelo por sobre el agua superficial. Incluyen bosques, pastizales y desiertos, entre otros.',
    paragrafo2: 'Existen diversas vías de introducción de especies terrestres, entre ellas:',
    paragrafo3: 'El escape de plantas ornamentales desde las áreas de cultivo, como en el caso del jengibre silvestre (Alpinia nigra), que fue activamente introducido como especie ornamental para parques y jardines.',
    paragrafo3_1: 'La introducción de agentes de control biológico como la mangosta de la India (Herpestes auropunctatus), que fue introducida de manera deliberada para el control de ratas en cultivos de caña, y cuya presencia se asocia con la extinción de cinco especies de vertebrados endémicos, un lagarto, una serpiente, dos aves y un roedor.',
    paragrafo4: 'Wild coffee - Pittosporum undulatum in Blue Mountains',
    paragrafo4b: 'Foto: S. Otuokon, JCDT',
    paragrafo5: 'Red bush - Persicaria chinensis in Blue Mountains',
    paragrafo5b: 'Foto: S. Otuokon, JCDT'
  },
  especiesAgricolasInstitucional: {
    titulo: 'Especies agrícolas',
    paragrafo1: 'Este grupo de especies invasoras amenaza o afecta de manera significativa a los cultivos, la actividad forestal y la ganadería. Varias de ellas son malezas o plagas de interés económico y sus poblaciones están sujetas a acciones de control . Un ejemplo es la mariposa One such pest in Jamaica is the mariposa asiática de los cítricos (Papilio demoleus) que afecta de producción de estos árboles frutales, atacando especialmente a las plántulas y a los árboles jóvenes en viveros.',
    paragrafo2: 'El manejo de esta especie se realiza mediante la remoción manual de las larvas, la aplicación de insecticidas y la promoción de la actividad de avispas predadoras.',
    paragrafo3: 'Lime Swallowtail Butterfly - Papilio demoleus',
    paragrafo3b: 'Foto: NHMJ collection'
  },
  peixesAquarioInstitucional: {
    titulo: 'Peixes de acuario / acuariofilia',
    paragrafo1: 'Los peces de acuario son las "mascotas" más numerosas del mundo en la actualidad. Brasil es un gran proveedor de peces ornamentales debido a la riqueza de la cuenca del Amazonas y el Pantanal. Sin embargo, la mayoría de los peces comercializados son exóticos en los lugares de venta. Estos animales nunca deben ser liberados en lagos, ríos o el mar porque pueden depredar especies autóctonas, alterar el ambiente acuático o transmitir enfermedades y parásitos.',
    paragrafo2: 'Además, pueden convertirse en invasores, como es el caso del pez león, que escapó de un acuario de Florida (EE.UU.) y ahora está en todo el Mar Caribe y corre el peligro de llegar a la costa brasileña.',
    paragrafo3: 'Es un gran riesgo para los arrecifes de coral, como en Abrolhos y Fernando de Noronha. ¡Nunca sueltes mascotas en la naturaleza!',
    paragrafo4: 'Más info',
    marcador1: 'Los peces ornamentales exóticos amenazan la biodiversidad',
    marcador2: 'Invasores exóticos',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - carazinho',
    paragrafo5b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenil)',
    paragrafo6b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae carazinho',
    paragrafo7b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - mato-grosso',
    paragrafo8b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - oscar',
    paragrafo9b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - paulistinha',
    paragrafo10b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - pez león',
    paragrafo11b: 'Foto: Instituto Horus'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Peces de acuicultura',
    paragrafo1: 'La acuicultura es la principal causa de introducción de peces de agua dulce en Brasil. Muchas especies son muy agresivas y tienen antecedentes de haber extinguido especies de peces nativas en varios países del mundo, como la tilapia-del-Nilo y el bagre africano. El panga es un pez asiático que se está empezando a criar en Brasil sin la autorización del IBAMA, con un alto potencial de invasión e impacto en la fauna nativa. Intenta informarte antes de decidir qué pescado vas a consumir, para no contribuir a los impactos que puedan causar.',
    paragrafo2: 'El problema es que las estructuras de cría -presas y tanques- no son seguras y los peces se escapan al medio natural, o incluso se liberan intencionadamente durante la manipulación de los tanques. Pueden depredar especies autóctonas, alterar el ambiente acuático o transmitir enfermedades y parásitos.',
    paragrafo3: 'Más información en este artículo.',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo4: 'Cichla kelberi - tucunaré',
    paragrafo5: 'Coptodon rendalli - tilapia',
    paragrafo6: 'Cyprinus carpio - carpa comun',
    paragrafo7: 'Micropterus salmoides - black bass',
    paragrafo7b: 'Foto: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus - tilapia nilótica'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Peces para pesca deportiva',
    paragrafo1: 'La pesca deportiva es una actividad de recreación que a menudo implica la introducción de especies exóticas, generalmente depredadores voraces. Aunque la introducción de especies y la transposición de peces a cuencas hidrográficas donde no se encuentren está prohibida sin autorización del IBAMA (Portaria IBAMA 145-N/1998), muchos peces han sido sacados de sus cuencas hidrográficas originales y llevados a otras donde no son nativos. Este proceso genera impactos en los peces nativos por depredación, competencia y transmisión de parásitos y enfermedades, además de graves desequilibrios ambientales.',
    paragrafo2: 'La pesca deportiva lleva asociada la introducción de especies exóticas utilizadas como carnada viva, que pueden escapar del anzuelo o incluso ser arrojadas a los ríos al final de la actividad de recreación. Los peces y otras especies que se utilicen como carnada viva sólo deben emplearse si son autóctonos del río donde se desarrolla la actividad pesquera para evitar la introducción de especies exóticas y los impactos sobre las especies autóctonas.',
    paragrafo3: '¡Nunca sueltes animales exóticos en la naturaleza!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Foto: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - tucunaré',
    paragrafo5b: 'Foto: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - tucunaré',
    paragrafo6b: 'Foto: LEPIB - UEL PR',
    paragrafo7: 'Más información en esta publicación'
  },
  petsInstitucional: {
    titulo: 'Mascotas',
    paragrafo1_1: 'El término ',
    pet: 'mascota',
    paragrafo1_2: ' se refiere a los animales domésticos, desde los peces de acuario hasta los perros y gatos. Algunos animales incluidos en la lista, especialmente los perros y los gatos, sólo se consideran problemáticos cuando se encuentran dentro de áreas naturales donde depredan la fauna autóctona y causan graves impactos en la conservación de la biodiversidad.',
    paragrafo2: '¡No deje a su mascota suelta en zonas naturales!',
    paragrafo3: 'Trachemys scripta – tortuga de orejas rojas ',
    paragrafo3b: 'Foto: Marcos Tortato',
    paragrafo4: 'Felis catus - gato doméstico',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Canis familiaris - perro doméstico',
    paragrafo5b: 'Foto: Marcos Tortato',
    paragrafo6: 'Trachemys dorbignyi - tigre-d´água',
    paragrafo6b: 'Foto: Marcos Tortato'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Plantas forrajeras',
    paragrafo1: 'Muchas plantas se introdujeron para su uso como forraje, es decir, para alimentar al ganado como bueyes, vacas, cabras, caballos y burros. Se trata principalmente de gramíneas, entre ellas la brachiaria, que es una invasora muy agresiva en áreas naturales y degradadas, incluso en humedales y pequeños arroyos. Pero también hay arbustos como la leucena y árboles como la algarroba, cuyas vainas se utilizan como alimento para las cabras.',
    paragrafo2: 'Estas especies se han extendido ampliamente en el mundo tropical y causan importantes impactos en el ambiente al modificar los ciclos naturales del fuego, reducir el volumen de agua y desplazar a las especies vegetales nativas, formando agrupaciones densas y dominantes.',
    paragrafo3: 'Más información en esta publicación',
    paragrafo4: 'Urochloa decumbens - brachiaria',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Prosopis juliflora - algarrobo',
    paragrafo5b: 'Foto: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - pasto miel',
    paragrafo6b: 'Foto: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - pasto jaragua',
    paragrafo7b: 'Foto: Instituto Horus'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentales',
    paragrafo1: 'Las plantas ornamentales representan alrededor del 50% de todas las plantas exóticas invasoras presentes en Brasil. La elección de las plantas para jardinería, sombra y arborización urbana puede contribuir a la conservación de la naturaleza, lo que abarca el suministro de agua, la estabilidad climática y muchos otros "servicios" que la naturaleza presta al ser humano.',
    paragrafo2_1: '¡Cultive plantas nativas de su región!',
    paragrafo2_2: 'Conozca, evite y reemplace las plantas exóticas invasoras de su jardín.',
    paragrafo3: 'Consulte plantas alternativas para su región en la página de ',
    arquiflora: 'Arquiflora',
    paragrafo4: 'Tecoma stans - ipê-de-jardim',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Furcraea foetida - piteira',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - comigo-ninguém-pode',
    paragrafo6b: 'Foto: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - aspargo',
    paragrafo7b: 'Foto: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - castanheira, amendoeira',
    paragrafo8b: 'Foto: Instituto Horus'
  },
  projetosInstitucional: {
    titulo: 'Proyectos',
    paragrafo1: 'En esta sección se incluye información acerca de los proyectos actuales o anteriores enfocados en el estudio y el manejo de las especies exóticas invasoras presentes en Jamaica. Si participa o participó de algún proyecto referido a estas especies, por favor ayude a incorporarlo enviando la información correspondiente a',
    paragrafo1_1: '',
    paragrafo2_1: 'Si participa o participo de algún proyecto sobre especies exóticas invasoras, contribuya con la base de datos ',
    paragrafo2_2: ', enviando informação sobre o projeto ',
    paragrafo2_3: 'para que podamos registrarlo. Esperamos que la divulgación beneficie su trabajo.',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Jamaica. Institute of Jamaica. Consultado en DD/MM/AAA.',
    paragrafo4_2: ''
  },
  referenciasInstitucional: {
    titulo: 'Referencias',
    paragrafo1: 'Las publicaciones e informes técnicos citados aquí fueron utilizados para sumar información acerca de las especies presentes en el territorio nacional, su comportamiento y efectos. Algunos incluyen datos acerca de las opciones de manejo de las distintas especies, así como puntos de ocurrencia en el país. Si es autor o conoce publicaciones en la materia que aún no están incluidas en esta sección, por favor ayude a incorporarlas enviando las citas correspondientes a',
    paragrafo2: 'Si conoce publicaciones sobre especies exóticas invasoras que no están contempladas aquí, contribuya para la actualización de la Base de Datos Nacional a través del envío de esas referencias.',
    paragrafo3: 'Contribuya con datos',
    paragrafo4: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo5_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Jamaica. Institute of Jamaica. Consultado en DD/MM/AAA.',
    paragrafo5_2: ''
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso forestal',
    paragrafo1: 'Varias especies de árboles exóticos se introdujeron en Brasil a partir de la década de 1950 por iniciativa del gobierno para desarrollar la industria forestal con el fin de producir papel, pulpa, madera y otros subproductos. Algunas de estas especies se han convertido en invasoras, y los pinos están especialmente distribuidos en numerosos ambientes. Estas especies requieren una gestión que incluya el control continuo y la contención en las masas forestales para evitar el impacto ambiental y paisajístico. La certificación forestal exige el control de las especies exóticas invasoras y es necesario eliminar estos árboles de las áreas naturales, los bordes de las carreteras, las vías férreas, los bosques de ribera y otros ambientes donde no estén cultivados.',
    paragrafo2: 'El género Pinus se considera el género de plantas con mayor área de invasión en el hemisferio sur. El pino desplaza a las especies nativas en los ecosistemas abiertos y es un gran consumidor de agua, más que las especies nativas.',
    paragrafo3: 'Más información en este artículo.',
    paragrafo4: 'Pínus sp. - pino',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Acacia mearnsii - acacia-negra',
    paragrafo5b: 'Foto: Instituto Horus',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Foto: Instituto Horus'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contacto',
    contato: 'Contacto',
    p1: 'Rellene todos los campos del siguiente formulario:',
    nome: 'Nombre',
    email: 'E-mail',
    instituicao: 'Institución',
    mensagem: 'Mensaje',
    enviar: 'Enviar'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Aportar datos',
    paragrafo1: '',
    paragrafo2: 'Nuestras bases de datos se mantienen actualizadas gracias a los aportes de especialistas y observadores de la naturaleza distribuidos en todo el territorio nacional. Antes de publicarse, la información provista es validada siguiendo criterios acordados a nivel regional. Usted puede aportar datos sobre especies aún no incluidas en este sistema o sobre nuevas localidades de ocurrencia descargando nuestra app (Invasoras JM) o enviando un e-mail a',
    paragrafo2b: '',
    paragrafo3: 'En ese mail podrá, además, informarnos acerca de publicaciones o proyectos sobre invasiones biológicas en Jamaica. Los proveedores de datos que realizan aportes de manera regular reciben un usuario y una contraseña para la carga de información. Ayúdenos a enfrentar el desafío de las especies exóticas invasoras sobre la base de información completa, actualizada y confiable!',
    paragrafo4: 'Muchas gracias!'
  }
}
