<template>
  <div class="tab-pane fade " :class="{ 'show active': aba===2 }" id="ecologia" role="tabpanel" aria-labelledby="ecologia-tab">
    <div class="container-fluid  mt-3">
      <div class="row">
          <div class="col-md-6">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisOrigin }">
              <label for="ecologia-area-de-origem" class="form-label">{{ $t('label.areaOrigem') }}</label>
              <button v-if="isPendingAnalysisOrigin" class="btn" type="button" @click="$emit('analysis', $t('label.areaOrigem'), 'origin', origins)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select :appendToBody="true" :options="origins" v-model="form.origin" label="value" index="id"
                :reduce="r => r.id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisNativeDistributionArea }">
              <label for="ecologia-area-de-distribuicao-natural" class="form-label">{{ $t('label.areaDistribuicaoNatural') }}</label>
              <button v-if="isPendingAnalysisNativeDistributionArea" class="btn" type="button" @click="$emit('analysis', $t('label.areaDistribuicaoNatural'), 'native_distribution_area')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
              <textarea v-model="form.native_distribution_area" rows="5" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisNaturalEnvironment }">
              <label for="ecologia-ambientes-naturais" class="form-label">{{ $t('label.ambientesNaturais') }}</label>
              <button v-if="isPendingAnalysisNaturalEnvironment" class="btn" type="button" @click="$emit('analysis', $t('label.ambientesNaturais'), 'natural_environment')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
              <textarea v-model="form.natural_environment" rows="5" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisMorphophysiologyDescription }">
              <label for="ecologia-descricao-da-especie" class="form-label">{{ $t('label.descricaoEspecie') }}</label>
              <button v-if="isPendingAnalysisMorphophysiologyDescription" class="btn" type="button" @click="$emit('analysis', $t('label.descricaoEspecie'), 'morphophysiology_description')" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
              <textarea v-model="form.morphophysiology_description" rows="5" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelPrefInvEnv }">
              <label for="ecologia-ambientes-preferenciais-de-invasao" class="form-label">{{ $t('label.ambientesPreferenciaisInvasao') }}</label>
              <button v-if="isPendingAnalysisRelPrefInvEnv" class="btn" type="button" @click="$emit('analysis', $t('label.ambientesPreferenciaisInvasao'), 'relPrefInvEnvSelected', vocprefenvironmentsList, relPrefInvEnvSelected, relOldPrefInvEnvSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis"><i class="fa fa-search" aria-hidden="true"></i></button>
              <v-select multiple :appendToBody="true" :options="vocprefenvironmentsList" v-model="relPrefInvEnvSelected" label="value" index="pref_inv_env_id"
                :reduce="r => r.pref_inv_env_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisInvasionPreferentialEnvironments }">
              <label for="ecologia-ambientes-preferenciais-de-invasao-descricao" class="form-label">{{ $t('label.ambientesPreferenciaisInvasaoDescricao') }}</label>
              <button v-if="isPendingAnalysisInvasionPreferentialEnvironments" class="btn" type="button" @click="$emit('analysis', $t('label.ambientesPreferenciaisInvasaoDescricao'), 'invasion_preferential_environments')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.invasion_preferential_environments" rows="5" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
          </div>
          <div class="col-md-6 border-start">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisHabitat }">
              <label for="ecologia-area-de-origem" class="form-label">{{ $t('label.habitat') }}</label>
              <button v-if="isPendingAnalysisHabitat" class="btn" type="button" @click="$emit('analysis', $t('label.habitat'), 'habitat', habitats)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select :appendToBody="true" :options="habitats" v-model="form.habitat" label="value" index="id"
                :reduce="r => r.id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>

            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisWorldInvasivePlaces }">
              <label for="ecologia-outros-locais" class="form-label">{{ $t('label.outrosLocaisOndeEspecieInvasora') }}</label>
              <button v-if="isPendingAnalysisWorldInvasivePlaces" class="btn" type="button" @click="$emit('analysis', $t('label.outrosLocaisOndeEspecieInvasora'), 'world_invasive_places')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.world_invasive_places" rows="5" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelBiologicalForm }">
              <label for="ecologia-forma-biologica" class="form-label">{{ $t('label.formaBiologica') }}</label>
              <button v-if="isPendingAnalysisRelBiologicalForm" class="btn" type="button" @click="$emit('analysis', $t('label.formaBiologica'), 'relBiologicalFormSelected', vocFormList, relBiologicalFormSelected, relOldBiologicalFormSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocFormList" v-model="relBiologicalFormSelected" label="value" index="form_id"
                :reduce="r => r.form_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelReproduction }">
              <label for="ecologia-reproducao" class="form-label">{{ $t('label.reproducao') }}</label>
              <button v-if="isPendingAnalysisRelReproduction" class="btn" type="button" @click="$emit('analysis', $t('label.reproducao'), 'relReproductionSelected', vocReproductionList, relReproductionSelected, oldRelReproductionSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocReproductionList" v-model="relReproductionSelected" label="value" index="reproduction_id"
                :reduce="r => r.reproduction_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelSpread }">
              <label for="ecologia-dispersao" class="form-label">{{ $t('label.dispersao') }}</label>
              <button v-if="isPendingAnalysisRelSpread" class="btn" type="button" @click="$emit('analysis', $t('label.dispersao'), 'relSpreadSelected', vocSpreadList, relSpreadSelected, oldRelSpreadSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocSpreadList" v-model="relSpreadSelected" label="value" index="spread_id"
                :reduce="r => r.spread_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelDiet }">
              <label for="ecologia-dieta" class="form-label">{{ $t('label.dieta') }}</label>
              <button v-if="isPendingAnalysisRelDiet" class="btn" type="button" @click="$emit('analysis', $t('label.dieta'), 'relDietSelected', vocDietList, relDietSelected, oldRelDietSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocDietList" v-model="relDietSelected" label="value" index="diet_id"
                :reduce="r => r.diet_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelEconomicUse }">
              <label for="ecologia-uso-economico" class="form-label">{{ $t('label.usoEconomico') }}</label>
              <button v-if="isPendingAnalysisRelEconomicUse" class="btn" type="button" @click="$emit('analysis', $t('label.usoEconomico'), 'relEconomicUseSelected', vocEconomicalUseList, relEconomicUseSelected, oldRelEconomicUseSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocEconomicalUseList" v-model="relEconomicUseSelected" label="value" index="economical_use_id"
                :reduce="r => r.economical_use_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisEconomicUse }">
              <label for="ecologia-ambientes-preferenciais-de-invasao-descricao" class="form-label">{{ $t('label.usoEconomicoDescricao') }}</label>
              <button v-if="isPendingAnalysisEconomicUse" class="btn" type="button" @click="$emit('analysis', $t('label.usoEconomicoDescricao'), 'economic_use')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.economic_use" rows="5" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EspeciesTabEcologiaEUso',
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorEcologiaEUso: Boolean,
    onlyLoadValidator: Boolean,
    pendingAnalysis: Boolean
  },
  data () {
    return {
      origins: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 'Exotic', value: this.$i18n.locale === 'en' ? 'Not native in the country' : this.$i18n.locale === 'es' ? 'No nativa del país' : 'Não nativa do país' },
        { id: 'Native', value: this.$i18n.locale === 'en' ? 'Native in the country' : this.$i18n.locale === 'es' ? 'Nativa del país' : 'Nativa do país' },
        { id: 'Cripto', value: this.$i18n.locale === 'en' ? 'Origin unknown' : this.$i18n.locale === 'es' ? 'Origen desconocido' : 'Origem desconhecida' }
      ],
      habitats: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.marinho') },
        { id: 2, value: this.$t('label.dulcicola') },
        { id: 3, value: this.$t('label.terrestre') }
      ],
      vocprefenvironmentsList: [],
      relPrefInvEnvSelected: [],
      relOldPrefInvEnvSelected: [],
      vocFormList: [],
      relBiologicalFormSelected: [],
      relOldBiologicalFormSelected: [],
      vocReproductionList: [],
      relReproductionSelected: [],
      oldRelReproductionSelected: [],
      vocSpreadList: [],
      relSpreadSelected: [],
      oldRelSpreadSelected: [],
      vocDietList: [],
      relDietSelected: [],
      oldRelDietSelected: [],
      vocEconomicalUseList: [],
      relEconomicUseSelected: [],
      oldRelEconomicUseSelected: []
    }
  },
  validations: {
    form: {}
  },
  watch: {
    'loadValidatorEcologiaEUso' (newValue) {
      if (newValue) {
        this.$v.$touch()
        const obj = {
          relPrefInvEnvSelected: this.relPrefInvEnvSelected,
          relBiologicalFormSelected: this.relBiologicalFormSelected,
          relReproductionSelected: this.relReproductionSelected,
          relSpreadSelected: this.relSpreadSelected,
          relDietSelected: this.relDietSelected,
          relEconomicUseSelected: this.relEconomicUseSelected
        }
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v, obj)
      }
    },
    'form.relPrefInvEnv' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getVocPrefSelected()
        this.getOldVocPrefSelected()
      }
    },
    'form.relBiologicalForm' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelBiologicalFormSelected()
        this.getOldRelBiologicalFormSelected()
      }
    },
    'form.relReproduction' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelReproductionSelected()
        this.getOldRelReproductionSelected()
      }
    },
    'form.relSpread' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelSpreadSelected()
        this.getOldRelSpreadSelected()
      }
    },
    'form.relDiet' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelDietSelected()
        this.getOldRelDietSelected()
      }
    },
    'form.relEconomicUse' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelEconomicUseSelected()
        this.getOldRelEconomicUseSelected()
      }
    },
    'aba' () {
      if (this.aba === 2) {
        if (this.vocprefenvironmentsList.length === 0 ||
          this.vocFormList.length === 0 ||
          this.vocReproductionList.length === 0 ||
          this.vocSpreadList.length === 0 ||
          this.vocDietList.length === 0 ||
          this.vocEconomicalUseList.length === 0) {
          this.$store.dispatch('showPreload')
          this.getVocPrefEnvironments()
          this.getVocForm()
          this.getVocReproduction()
          this.getVocSpread()
          this.getVocDiet()
          this.getVocEconomicalUse()
        }
      }
    }
  },
  computed: {
    isPendingAnalysis: function () {
      return !this.byInstitucional && this.pendingAnalysis && this.form.log_update
    },
    isPendingAnalysisOrigin: function () {
      const result = this.isPendingAnalysis && this.form.origin !== this.form.log_update.Origin
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisNativeDistributionArea: function () {
      const result = this.isPendingAnalysis && this.form.native_distribution_area !== this.form.log_update.Native_distribution_area
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisInvasionPreferentialEnvironments: function () {
      const result = this.isPendingAnalysis && this.form.invasion_preferential_environments !== this.form.log_update.Invasion_preferential_environments
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisNaturalEnvironment: function () {
      const result = this.isPendingAnalysis && this.form.natural_environment !== this.form.log_update.Natural_environment
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisMorphophysiologyDescription: function () {
      const result = this.isPendingAnalysis && this.form.morphophysiology_description !== this.form.log_update.Morphophysiology_description
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisRelPrefInvEnv: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelPrefInvEnv
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisHabitat: function () {
      const result = this.isPendingAnalysis && this.form.habitat !== this.form.log_update.Habitat
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisWorldInvasivePlaces: function () {
      const result = this.isPendingAnalysis && this.form.world_invasive_places !== this.form.log_update.World_invasive_places
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisRelBiologicalForm: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelBiologicalForm
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisRelReproduction: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelReproduction
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisRelSpread: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelSpread
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisRelDiet: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelDiet
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisRelEconomicUse: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelEconomicUse
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    isPendingAnalysisEconomicUse: function () {
      const result = this.isPendingAnalysis && this.form.economic_use !== this.form.log_update.Economic_use
      this.$emit('especiesTabEcologiaEUsoPending', result)
      return result
    },
    pendingRelPrefInvEnv: function () {
      let result = false
      if (this.relPrefInvEnvSelected.length !== this.relOldPrefInvEnvSelected.length) {
        return true
      }
      this.relOldPrefInvEnvSelected.forEach(e => {
        if (!this.relPrefInvEnvSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelBiologicalForm: function () {
      let result = false
      if (this.relBiologicalFormSelected.length !== this.relOldBiologicalFormSelected.length) {
        return true
      }
      this.relOldBiologicalFormSelected.forEach(e => {
        if (!this.relBiologicalFormSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelReproduction: function () {
      let result = false
      if (this.relReproductionSelected.length !== this.oldRelReproductionSelected.length) {
        return true
      }
      this.oldRelReproductionSelected.forEach(e => {
        if (!this.relReproductionSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelSpread: function () {
      let result = false
      if (this.relSpreadSelected.length !== this.oldRelSpreadSelected.length) {
        return true
      }
      this.oldRelSpreadSelected.forEach(e => {
        if (!this.relSpreadSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelDiet: function () {
      let result = false
      if (this.relDietSelected.length !== this.oldRelDietSelected.length) {
        return true
      }
      this.oldRelDietSelected.forEach(e => {
        if (!this.relDietSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelEconomicUse: function () {
      let result = false
      if (this.relEconomicUseSelected.length !== this.oldRelEconomicUseSelected.length) {
        return true
      }
      this.oldRelEconomicUseSelected.forEach(e => {
        if (!this.relEconomicUseSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  methods: {
    getRelSpreadSelected: function () {
      this.form.relSpread.forEach(e => {
        if (!this.relSpreadSelected.includes(Number(e.spread_id))) {
          this.relSpreadSelected.push(Number(e.spread_id))
        }
      })
    },
    getOldRelSpreadSelected: function () {
      if (this.form.log_update && this.form.log_update.RelSpread) {
        this.form.log_update.RelSpread.forEach(e => {
          if (!this.oldRelSpreadSelected.includes(Number(e.Spread_id))) {
            this.oldRelSpreadSelected.push(Number(e.Spread_id))
          }
        })
      }
    },
    getRelDietSelected: function () {
      this.form.relDiet.forEach(e => {
        if (!this.relDietSelected.includes(Number(e.diet_id))) {
          this.relDietSelected.push(Number(e.diet_id))
        }
      })
    },
    getOldRelDietSelected: function () {
      if (this.form.log_update && this.form.log_update.RelDiet) {
        this.form.log_update.RelDiet.forEach(e => {
          if (!this.oldRelDietSelected.includes(Number(e.Diet_id))) {
            this.oldRelDietSelected.push(Number(e.Diet_id))
          }
        })
      }
    },
    getRelEconomicUseSelected: function () {
      this.form.relEconomicUse.forEach(e => {
        if (!this.relEconomicUseSelected.includes(Number(e.economic_use_id))) {
          this.relEconomicUseSelected.push(Number(e.economic_use_id))
        }
      })
    },
    getOldRelEconomicUseSelected: function () {
      if (this.form.log_update && this.form.log_update.RelEconomicUse) {
        this.form.log_update.RelEconomicUse.forEach(e => {
          if (!this.oldRelEconomicUseSelected.includes(Number(e.Economic_use_id))) {
            this.oldRelEconomicUseSelected.push(Number(e.Economic_use_id))
          }
        })
      }
    },
    getRelReproductionSelected: function () {
      this.form.relReproduction.forEach(e => {
        if (!this.relReproductionSelected.includes(Number(e.reproduction_id))) {
          this.relReproductionSelected.push(Number(e.reproduction_id))
        }
      })
    },
    getOldRelReproductionSelected: function () {
      if (this.form.log_update && this.form.log_update.RelReproduction) {
        this.form.log_update.RelReproduction.forEach(e => {
          if (!this.oldRelReproductionSelected.includes(Number(e.Reproduction_id))) {
            this.oldRelReproductionSelected.push(Number(e.Reproduction_id))
          }
        })
      }
    },
    getRelBiologicalFormSelected: function () {
      this.form.relBiologicalForm.forEach(e => {
        if (!this.relBiologicalFormSelected.includes(Number(e.biological_form_id))) {
          this.relBiologicalFormSelected.push(Number(e.biological_form_id))
        }
      })
    },
    getOldRelBiologicalFormSelected: function () {
      if (this.form.log_update && this.form.log_update.RelBiologicalForm) {
        this.form.log_update.RelBiologicalForm.forEach(e => {
          if (!this.relOldBiologicalFormSelected.includes(Number(e.Biological_form_id))) {
            this.relOldBiologicalFormSelected.push(Number(e.Biological_form_id))
          }
        })
      }
    },
    getVocPrefSelected: function () {
      this.form.relPrefInvEnv.forEach(e => {
        if (!this.relPrefInvEnvSelected.includes(Number(e.pref_inv_env_id))) {
          this.relPrefInvEnvSelected.push(Number(e.pref_inv_env_id))
        }
      })
    },
    getOldVocPrefSelected: function () {
      if (this.form.log_update && this.form.log_update.RelPrefInvEnv) {
        this.form.log_update.RelPrefInvEnv.forEach(e => {
          if (!this.relOldPrefInvEnvSelected.includes(Number(e.Pref_inv_env_id))) {
            this.relOldPrefInvEnvSelected.push(Number(e.Pref_inv_env_id))
          }
        })
      }
    },
    getVocPrefEnvironments: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocprefenvironments/getall', { params })
        .then(response => {
          this.vocprefenvironmentsList = response.data.items.map(i => {
            const item = {
              pref_inv_env_id: i.pref_inv_env_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocForm: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocform/getall', { params })
        .then(response => {
          this.vocFormList = response.data.items.map(i => {
            const item = {
              form_id: i.form_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocReproduction: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocreproduction/getall', { params })
        .then(response => {
          this.vocReproductionList = response.data.items.map(i => {
            const item = {
              reproduction_id: i.reproduction_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocSpread: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocspread/getall', { params })
        .then(response => {
          this.vocSpreadList = response.data.items.map(i => {
            const item = {
              spread_id: i.spread_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocDiet: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocDiet/getall', { params })
        .then(response => {
          this.vocDietList = response.data.items.map(i => {
            const item = {
              diet_id: i.diet_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocEconomicalUse: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/voceconomicaluse/getall', { params })
        .then(response => {
          this.vocEconomicalUseList = response.data.items.map(i => {
            const item = {
              economical_use_id: i.economical_use_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    }
  }
}
</script>
