<template>
  <nav id="sidebar" :class="isMenuCollapsed ? 'sidebar collapsed' : 'sidebar'">
    <simplebar data-simplebar-auto-hide="false">
      <router-link class="sidebar-brand" to="/">
        <img src="./../../assets/institucional/img/logo/logo-the-institute-of-jamaica.png" alt="Rede de bases de dadods" width="175">
      </router-link>
      <ul class="sidebar-nav">
        <li class="sidebar-item icon-dashboard " :class="$route.name === 'AdminHome' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin"><span class="align-middle">Dashboard</span></router-link>
        </li>
        <li class="sidebar-item icon-especie" :class="$route.name === 'AdminEspecies' || $route.name === 'AdminEspeciesForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/especies"><span class="align-middle">{{ $t('label.especies') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-ocorrencia" :class="$route.name === 'AdminOcorrencias' || $route.name === 'AdminOcorrenciasForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/ocorrencias"><span class="align-middle">{{ $t('label.ocorrencias') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-ocorrencia" :class="$route.name === 'AdminOcorrenciasApp' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/ocorrencias-app"><span class="align-middle">{{ $t('label.ocorrenciasApp') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-referencia" :class="$route.name === 'AdminReferencias' || $route.name === 'AdminReferenciasForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/referencias"><span class="align-middle">{{ $t('label.referencias') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-colaborar" :class="$route.name === 'AdminColaboradores' || $route.name === 'AdminColaboradoresForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/colaboradores"><span class="align-middle">{{ $t('label.colaboradores') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-projetos" :class="$route.name === 'AdminProjetos' || $route.name === 'AdminProjetosForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/projetos"><span class="align-middle">{{ $t('label.projetos') }}</span></router-link>
        </li>
        <template v-if="user.perfilUser === 'administrator'">
          <li class="sidebar-item">
            <hr>
          </li>
          <li class="sidebar-item icon-usuario" :class="$route.name === 'AdminUsuarios' || $route.name === 'AdminUsuariosForm' ? 'active' : ''">
            <router-link class="sidebar-link" to="/admin/usuarios"><span class="align-middle">{{ $t('label.usuarixs') }}</span></router-link>
          </li>
          <li class="sidebar-item icon-taxonomia" :class="$route.name === 'AdminTaxonomia' ? 'active' : ''">
            <router-link class="sidebar-link" to="/admin/taxonomia"><span class="align-middle">{{ $t('label.taxonomia') }}</span></router-link>
          </li>
          <li class="sidebar-item icon-exportar" :class="$route.name === 'Fichas' || $route.name === 'FichasMMA' || $route.name === 'FichasMMA2' ? 'active' : ''">
            <a data-target="#exportar" data-toggle="collapse" class="sidebar-link" :class="{ 'collapsed': !expanded }" :aria-expanded="expanded" @click="expanded=!expanded">
              <span class="align-middle">{{ $t('label.exportarDados') }}</span>
            </a>
            <ul id="exportar" class="sidebar-dropdown list-unstyled collapse exportar" :class="{ 'show': expanded }" data-parent="#sidebar">
              <li class="sidebar-item"  :class="$route.name === 'Consultas' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/exportar/consultas"><span class="align-middle">Consultas</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'Fichas' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/exportar/fichas"><span class="align-middle">Fichas técnicas</span></router-link>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </simplebar>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import simplebar from 'simplebar-vue'
export default {
  name: 'AdminSidebar',
  components: {
    simplebar
  },
  data () {
    return {
      expanded: true
    }
  },
  computed: {
    ...mapState(['isMenuCollapsed', 'user'])
  }
}
</script>
