<template>
  <div class="tab-pane fade" :class="{ 'show active': aba===5 }" id="manejo" role="tabpanel" aria-labelledby="manejo-tab">
    <div class="container-fluid  mt-3">
      <div class="row">
          <div class="col-md-6">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisPrevention }">
              <label for="manejo-medidas-preventivas" class="form-label">{{ $t('label.medidasPreventivas') }}</label>
              <button v-if="isPendingAnalysisPrevention" class="btn" type="button" @click="$emit('analysis', $t('label.medidasPreventivas'), 'prevention')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.prevention" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisEarlyDetectionMeasures }">
              <label for="manejo-medidas-deteccao-precoce" class="form-label">{{ $t('label.medidasDeteccaoPrecoce') }}</label>
              <button v-if="isPendingAnalysisEarlyDetectionMeasures" class="btn" type="button" @click="$emit('analysis', $t('label.medidasDeteccaoPrecoce'), 'early_detection_measures')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.early_detection_measures" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisPhysicalControl }">
              <label for="manejo-controle-mecanico" class="form-label">{{ $t('label.controleMecanico') }}</label>
              <button v-if="isPendingAnalysisPhysicalControl" class="btn" type="button" @click="$emit('analysis', $t('label.controleMecanico'), 'physical_control')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.physical_control" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
          </div>
          <div class="col-md-6 border-start">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisPhysicalChemicalControl }">
              <label for="manejo-controle-quimico" class="form-label">{{ $t('label.controleQuimico') }}</label>
              <button v-if="isPendingAnalysisPhysicalChemicalControl" class="btn" type="button" @click="$emit('analysis', $t('label.controleQuimico'), 'chemical_control')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.chemical_control" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisPhysicalControlBiological }">
              <label for="manejo-controle-biologico" class="form-label">{{ $t('label.controleBiologico') }}</label>
              <button v-if="isPendingAnalysisPhysicalControlBiological" class="btn" type="button" @click="$emit('analysis', $t('label.controleBiologico'), 'control_biological')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <textarea v-model="form.control_biological" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EspeciesTabManejo',
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorManejo: Boolean,
    onlyLoadValidator: Boolean,
    pendingAnalysis: Boolean
  },
  computed: {
    isPendingAnalysis: function () {
      return !this.byInstitucional && this.pendingAnalysis && this.form.log_update
    },
    isPendingAnalysisPrevention: function () {
      const result = this.isPendingAnalysis && this.form.prevention !== this.form.log_update.Prevention
      this.$emit('especiesTabManejoPending', result)
      return result
    },
    isPendingAnalysisPreventionMMA: function () {
      const result = this.isPendingAnalysis && this.form.prevention_MMA !== this.form.log_update.Prevention_MMA
      this.$emit('especiesTabManejoPending', result)
      return result
    },
    isPendingAnalysisEarlyDetectionMeasures: function () {
      const result = this.isPendingAnalysis && this.form.early_detection_measures !== this.form.log_update.Early_detection_measures
      this.$emit('especiesTabManejoPending', result)
      return result
    },
    isPendingAnalysisPhysicalControl: function () {
      const result = this.isPendingAnalysis && this.form.physical_control !== this.form.log_update.Physical_control
      this.$emit('especiesTabManejoPending', result)
      return result
    },
    isPendingAnalysisPhysicalChemicalControl: function () {
      const result = this.isPendingAnalysis && this.form.chemical_control !== this.form.log_update.Chemical_control
      this.$emit('especiesTabManejoPending', result)
      return result
    },
    isPendingAnalysisPhysicalControlBiological: function () {
      const result = this.isPendingAnalysis && this.form.control_biological !== this.form.log_update.Control_biological
      this.$emit('especiesTabManejoPending', result)
      return result
    }
  },
  validations: {
    form: {}
  },
  watch: {
    'loadValidatorManejo' (newValue) {
      if (newValue) {
        this.$v.$touch()
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v)
      }
    }
  }
}
</script>
