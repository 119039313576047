<template>
  <div class="wrapper">
    <AdminSidebar />
    <div class="main dashboard">
      <AdminNavbar />
      <main class="content">
        <router-view></router-view>
      </main>
      <AdminFooter />
    </div>
  </div>
</template>

<script>
import AdminSidebar from '@/components/admin/AdminSidebar'
import AdminNavbar from '@/components/admin/AdminNavbar'
import AdminFooter from '@/components/admin/AdminFooter'
export default {
  name: 'AdminContent',
  components: { AdminSidebar, AdminNavbar, AdminFooter }
}
</script>
