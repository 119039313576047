<template>
  <div class="container-fluid p-0">
    <PageTitle :title="$t('label.usuarixs')" :titleAdd="$t('label.adicionarUsuario')" :isAdd="true"/>
    <!-- filtro -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body p-0 box-dashboard">
            <div class="align-self-center">
              <form class="row g-3 p-3 pb-0 filtro_especie" @submit.prevent="search">
                <div class="col-12 col-lg-4">
                  <input type="text" class="form-control bg-laranja" :placeholder="$t('label.nome')" v-model="formSearch.name"/>
                </div>
                <div class="col-12 col-lg-4">
                  <input type="text" class="form-control bg-laranja" :placeholder="$t('label.descricao')" v-model="formSearch.description"/>
                </div>
                <div class="col-12 col-lg-2">
                  <label for="usuarios-ativo" class="form-label" style="margin-bottom: 0px;">{{ $t('label.ativo') }}</label>
                  <div class="mt-0 d-flex">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" value="Y" v-model="formSearch.active">
                        <label class="form-check-label" >{{ $t('label.sim') }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" value="N" v-model="formSearch.active">
                        <label class="form-check-label" >{{ $t('label.nao') }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-2 ml-auto text-right pt-1">
                  <button type="button" @click="clear" class="btn btn-outline-secondary mt-1 mb-2 mr-1">
                    {{ $t('label.limpar') }}
                  </button>
                  <button type="submit" class="btn bt-filtrar mt-1 mb-2">
                    {{ $t('label.buscar') }}
                  </button>
                </div>
              </form>

              <div class="row mt-1 mt-xl-2 p-3 bg_table_header">
                <div class="col-auto d-none d-sm-block">
                  <p class="mb-0 mt-2">
                    {{ $t('label.numRegistrosEncontrados') }}
                    <span class="laranja">{{$store.state.countList}}</span>
                  </p>
                </div>

                <div class="col-auto ml-auto text-right select_registro">
                  <select class="selectpicker" ref="select" v-model="$store.state.perPage" @change="search">
                    <option selected value="10">{{ $t('label.exibir10Reg') }}</option>
                    <option value="25">{{ $t('label.exibir25Reg') }}</option>
                    <option value="50">{{ $t('label.exibir50Reg') }}</option>
                    <option value="100">{{ $t('label.exibir100Reg') }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /filtro -->

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <div class="table-responsive full_table mt-0">
                <table class="table caption-top">
                  <thead class="bg_table_header">
                    <tr>
                      <th class="col-md-4 py-3 pl-4">{{ $t('login.username') }}</th>
                      <th class="col-md-5 py-3">{{ $t('label.descricao') }}</th>
                      <th class="col-md-1 py-3">{{ $t('label.ativo') }}</th>
                      <th class="col-md-2 py-3 pl-2 pr-4 text-right">{{ $t('label.acoes') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="$store.state.countList === 0"><td colspan="4" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                    <tr v-else v-for="item in items" :key="item.name">
                      <td class="py-3 pl-4">{{item.name}}</td>
                      <td class="py-3">{{item.description}}</td>
                      <td class="py-3">
                        <span v-if="item.active === 'Y'" class="badge bg-success">{{ $t('label.sim') }}</span>
                        <span v-else class="badge bg-danger">{{ $t('label.nao') }}</span>
                      </td>
                      <td class="py-3">
                        <ul class="acoes text-right pr-1">
                          <li>
                            <a @click="action($actionEdit, item)"><span class="editar"></span></a>
                          </li>
                          <li>
                            <a @click="action($actionDelete, item)" v-if="$store.state.user && $store.state.user.perfilUser === 'administrator'"><span class="apagar"></span></a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <TablePagination v-on:searchTable="search()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
  </div>
</template>

<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'
import PageTitle from '@/components/shared/PageTitle'
export default {
  name: 'Usuarios',
  components: { TablePagination, PageTitle },
  data: function () {
    return {
      formSearch: {},
      items: [],
      listPagination: [],
      rangePagination: [],
      countPages: 0
    }
  },
  methods: {
    clear: function () {
      this.formSearch = {}
    },
    action: function (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        this.$router.push({ name: 'AdminUsuariosForm', params: { id: item.name } })
      }
    },
    delete: function (item) {
      axios.delete(`/User/${item.name}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search: function () {
      this.$store.dispatch('showPreload')

      const params = this.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/User/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
        })
    }
  },
  created: function () {
    this.search()
  }
}
</script>
