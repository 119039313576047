<template>
    <div :class="classProp">
      <div class="container  h-100">
        <div class="row align-items-end  h-100">
          <div class="col-sm ">
            <h1 class="align-bottom">{{title}}</h1>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'InstitucionalHeaderSingle',
  props: {
    title: String,
    classProp: {
      type: String,
      default: 'header-single'
    }
  }
}
</script>

<style>
.header-single {
height: 200px;
background: #f8f8f8 url(../../assets/institucional/img/header-sobre.jpg) center center;
background-size: cover;
}

.header-single h1 {
  color: #fff;
  text-shadow: 0px 0px 4px rgba(150, 150, 150, 1);
}
</style>
