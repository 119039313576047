<template>
  <div class="modal fade" id="mapaOcorrencias" refs="mapaOcorrencias" tabindex="-1" aria-labelledby="mapaOcorrencias" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl" style="max-width: 100%;">
      <div class="modal-content">
        <div class="modal-header" style="padding-bottom: 0px;padding-top: 10px;">
          <h5 class="modal-title" id="mapaOcorrenciasTitle">{{ scientificName }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body d-flex box_scroll_y">
          <div class="row">
            <div class="col">
              <LMap style="height: 620px" v-if="showMap"
                :zoom="zoom"
                :center="currentCenter"
                @update:center="centerUpdate">
                <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
                <LMarker :lat-lng="[m.lat, m.lng]" v-for="(m, index) in markers" :key="index">
                  <LTooltip><div>{{m.municipio}} - {{m.state}} <br /> {{m.protected_area}}<br />{{m.latitude}} <br /> {{m.longitude}}</div></LTooltip>
                  <LIcon v-if="m.pending_analysis" icon-url="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGc+CiAgICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgICA8cGF0aCBpZD0ic3ZnXzEiIHN0cm9rZS13aWR0aD0iMS4xIiBzdHJva2UtbGluZWNhcD0icm91bmQiIGQ9Im0xMi41OTM5OSwxLjMyMTk5Yy02LjU3MywwIC0xMi4wNDQsNS42OTEgLTEyLjA0NCwxMS44NjZjMCwyLjc3OCAxLjU2NCw2LjMwOCAyLjY5NCw4Ljc0Nmw5LjMwNiwxNy44NzJsOS4yNjIsLTE3Ljg3MmMxLjEzLC0yLjQzOCAyLjczOCwtNS43OTEgMi43MzgsLTguNzQ2YzAsLTYuMTc1IC01LjM4MywtMTEuODY2IC0xMS45NTYsLTExLjg2NnptMCw3LjE1NWE0LjcxNCw0LjcxNCAwIDAgMSA0LjY3OSw0LjcxYzAsMi41ODggLTIuMDk1LDQuNjYzIC00LjY3OSw0LjY3OWMtMi41ODQsLTAuMDE3IC00LjY3OSwtMi4wOSAtNC42NzksLTQuNjc5YTQuNzE0LDQuNzE0IDAgMCAxIDQuNjc5LC00LjcxeiIgc3R5bGU9ImZpbGw6IHJnYigxNzQsIDE4MCwgMTc0KTsgcGFpbnQtb3JkZXI6IGZpbGw7IHN0cm9rZTogcmdiKDEzNCwgMTQxLCAxMzQpOyIvPgogICAgPHBhdGggaWQ9InN2Z18yIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS4xIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1vcGFjaXR5PSIwLjEyMiIgZD0ibTEyLjU4MSwyLjQzYy01Ljk0NCwwIC0xMC45MzgsNS4yMTkgLTEwLjkzOCwxMC43NWMwLDIuMzU5IDEuNDQzLDUuODMyIDIuNTYzLDguMjVsMC4wMzEsMC4wM2w4LjMxMywxNS45N2w4LjI1LC0xNS45N2wwLjAzMSwtMC4wM2MxLjEzNSwtMi40NDggMi42MjUsLTUuNzA2IDIuNjI1LC04LjI1YzAsLTUuNTM4IC00LjkzMSwtMTAuNzUgLTEwLjg3NSwtMTAuNzV6bTAsNC45NjljMy4xNjgsMC4wMiA1Ljc4MSwyLjYgNS43ODEsNS43OHMtMi42MTMsNS43NjIgLTUuNzgxLDUuNzgyYy0zLjE2OCwtMC4wMiAtNS43NSwtMi42MSAtNS43NSwtNS43ODFjMCwtMy4xNzIgMi41ODIsLTUuNzYxIDUuNzUsLTUuNzgxeiIgc3R5bGU9ImZpbGw6IHJnYigxNzQsIDE4MCwgMTc0KTsgcGFpbnQtb3JkZXI6IGZpbGw7Ii8+CiAgPC9nPgo8L3N2Zz4="></LIcon>
                  <LIcon v-else icon-url="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4xIiBoZWlnaHQ9IjQwLjIyNyIgdmlld0JveD0iMCAwIDI1LjEgNDAuMjI3Ij4NCiAgPGcgaWQ9Im1hcmtlci52ZXJkZS4ubWluIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjc3MikiPg0KICAgIDxwYXRoIGlkPSJDYW1pbmhvXzg4OCIgZGF0YS1uYW1lPSJDYW1pbmhvIDg4OCIgZD0iTTQxNi41NDQsNTAzLjYxMkExMi4yNjEsMTIuMjYxLDAsMCwwLDQwNC41LDUxNS40NzhjMCwyLjc3OCwxLjU2NCw2LjMwOCwyLjY5NCw4Ljc0Nkw0MTYuNSw1NDIuMWw5LjI2Mi0xNy44NzJjMS4xMy0yLjQzOCwyLjczOC01Ljc5MSwyLjczOC04Ljc0NmExMi4xODIsMTIuMTgyLDAsMCwwLTExLjk1Ni0xMS44NjZabTAsNy4xNTVhNC42OTUsNC42OTUsMCwxLDEtNC42NzksNC43MSw0LjcxNCw0LjcxNCwwLDAsMSw0LjY3OS00LjcxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQwMy45NSAtNTAyLjI5KSIgZmlsbD0iIzIxYjczZCIgc3Ryb2tlPSIjMTI2MzIxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMS4xIi8+DQogICAgPHBhdGggaWQ9IkNhbWluaG9fODg5IiBkYXRhLW5hbWU9IkNhbWluaG8gODg5IiBkPSJNMTIuNTgxLDIuNDNBMTEuMTg0LDExLjE4NCwwLDAsMCwxLjY0MywxMy4xOGMwLDIuMzU5LDEuNDQzLDUuODMyLDIuNTYzLDguMjVsLjAzMS4wM0wxMi41NSwzNy40MywyMC44LDIxLjQ2bC4wMzEtLjAzYy4xNzQtLjM3NS4zNTYtLjc2OC42NjgtMS40NjMuODg4LTEuOTY1LDEuOTU3LTQuNjMyLDEuOTU3LTYuNzg3QTExLjEyMiwxMS4xMjIsMCwwLDAsMTIuNTgxLDIuNDNabTAsNC45NjlhNS43ODEsNS43ODEsMCwxLDEtNS43NSw1Ljc4MUE1LjgxNyw1LjgxNywwLDAsMSwxMi41ODEsNy40WiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzhkMjU1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMS4xIi8+DQogIDwvZz4NCjwvc3ZnPg0K"></LIcon>
                </LMarker>
              </LMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LTooltip, LIcon } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
export default {
  name: 'MapaOcorrencias',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon
  },
  props: {
    markers: Array,
    viewMap: Boolean,
    scientificName: String
  },
  data () {
    return {
      showMap: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      myCoordinates: {
        lat: 18.0174475,
        lng: -76.7959638
      },
      currentCenter: latLng(18.0174475, -76.7959638),
      zoom: 6,
      markerLatLng: [18.0174475, -76.7959638]
    }
  },
  watch: {
    'viewMap' (value) {
      setTimeout(() => {
        this.showMap = value
      }, 500)
    },
    'markers' () {
      if (this.markers.length > 0) {
        this.currentCenter = latLng(this.markers[0].lat, this.markers[0].lng)
      }
    }
  },
  methods: {
    centerUpdate (center) {
      this.currentCenter = center
    }
  },
  created () {
    this.$getLocation({})
      .then(myCoordinates => {
        this.myCoordinates = myCoordinates
        this.currentCenter = latLng(myCoordinates.lat, myCoordinates.lng)
      })
  }
}
</script>
