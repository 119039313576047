<template>
  <span class="edit-label edit-field">
      <input type="text" class="edit-label-input" v-model="item.oorder" ref="order">
      <v-select :appendToBody="true" :options="list" v-model="item.class_id" label="class" index="class_id"
        :reduce="r => r.class_id" :clearable="false" style="min-width: 150px;">
        <template v-slot:selected-option="option">
          {{ `${option.class}` }}
        </template>
        <template v-slot:option="option">
          {{ `${option.class}` }}
        </template>
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
          </template>
        </template>
      </v-select>
      <ul class="acoes d-flex justify-content-end">
        <li><a @click="salve"><span class="salvar mt-1"></span></a></li>
        <li><a @click="cancel"><span class="cancelar mt-1"></span></a></li>
      </ul>
  </span>
</template>

<script>
export default {
  name: 'EditTaxonomyOrder',
  props: {
    order: {},
    list: Array
  },
  data: function () {
    return {
      item: {
        order_id: 0,
        oorder: ''
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancelEdit')
    },
    salve: function () {
      this.$emit('salveEdit', this.item)
    }
  },
  mounted () {
    this.item = this.order
    this.$refs.order.focus()
  }
}
</script>
