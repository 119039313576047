<template>
  <div class="container-fluid p-0">
    <PageTitle v-if="!byInstitucional" :title="pageTitle" :titleSave="pendingAnalysis ? $t('label.validarEspecie') : $t('label.salvarEspecie')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit" :pendingAnalysis="pendingAnalysis" :permissionAllAdd="false"/>
    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
                <div class="align-self-center">
                  <!-- abas-->
                  <EspeciesTabs @setAba="setAba" :aba="aba" :action="action" :tabManejoPending="tabManejoPending" :tabTaxonomiaPending="tabTaxonomiaPending" :tabEcologiaEUsoPending="tabEcologiaEUsoPending" :tabIntroducaoDispersaoPending="tabIntroducaoDispersaoPending" :tabImpactoPending="tabImpactoPending"
                   :tabAnaliseDeRiscoPending="tabAnaliseDeRiscoPending" :tabOcorrenciasPending="tabOcorrenciasPending || placesPendingAnalysis" :tabReferenciasPending="tabReferenciasPending" :tabProjetosPending="tabProjetosPending"/>
                  <!-- /abas -->
                  <!-- conteudo abas -->
                  <div class="tab-content" id="id-tabs-especies">
                      <!-- conteudo aba taxonomia -->
                      <EspeciesTabTaxonomia :aba="aba" :form="form" :loadValidatorTaxonomia="loadValidatorTaxonomia" :startUpload="startUpload" :pendingAnalysis="pendingAnalysis"  @especiesTabTaxonomiaPending="especiesTabTaxonomiaPending"
                      @continueSetAba="continueSetAba" @continueSave="continueSave" @setTaxonomiaGenus="setTaxonomiaGenus" :onlyLoadValidator="onlyLoadValidator" @analysis="analysis"
                      :byInstitucional="byInstitucional"/>
                      <!-- conteudo aba taxonomia -->
                      <!-- conteudo aba ecologia e uso -->
                      <EspeciesTabEcologiaEUso :aba="aba" :form="form" :loadValidatorEcologiaEUso="loadValidatorEcologiaEUso" :pendingAnalysis="pendingAnalysis"  @especiesTabEcologiaEUsoPending="especiesTabEcologiaEUsoPending"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" @analysis="analysis" :byInstitucional="byInstitucional" />
                      <!-- /conteudo aba ecologia e uso -->
                      <!-- conteudo aba introdução e dispersão -->
                      <EspeciesTabIntroducaoDispersao :aba="aba" :form="form" :loadValidatorIntroducaoDispersao="loadValidatorIntroducaoDispersao" :pendingAnalysis="pendingAnalysis"  @especiesTabIntroducaoDispersaoPending="especiesTabIntroducaoDispersaoPending"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" :dataBibliographyList="dataBibliographyList" @analysis="analysis" :byInstitucional="byInstitucional"/>
                      <!-- /conteudo aba introdução e dispersão -->
                      <!-- conteudo aba impacto -->
                      <EspeciesTabImpacto :aba="aba" :form="form" :loadValidatorImpacto="loadValidatorImpacto" :dataBibliographyList="dataBibliographyList" :pendingAnalysis="pendingAnalysis"  @especiesTabImpactoPending="especiesTabImpactoPending"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" @analysis="analysis" :byInstitucional="byInstitucional"/>
                      <!-- /conteudo aba impacto -->
                      <!-- conteudo aba manejo -->
                      <EspeciesTabManejo :aba="aba" :form="form" :loadValidatorManejo="loadValidatorManejo" :pendingAnalysis="pendingAnalysis"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" @analysis="analysis" :byInstitucional="byInstitucional" @especiesTabManejoPending="especiesTabManejoPending"/>
                      <!-- /conteudo aba manejo -->
                      <!-- conteudo aba análise de risco -->
                      <EspeciesTabAnaliseDeRisco :aba="aba" :form="form" :loadValidatorAnaliseDeRisco="loadValidatorAnaliseDeRisco" :dataBibliographyList="dataBibliographyList" :pendingAnalysis="pendingAnalysis" @especiesTabAnaliseDeRiscoPending="especiesTabAnaliseDeRiscoPending"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" @analysis="analysis" :byInstitucional="byInstitucional"/>
                      <!-- /conteudo aba análise de risco -->
                      <!-- conteudo aba ocorrências -->
                      <EspeciesTabOcorrencias :aba="aba" :form="form" :loadValidatorOcorrencias="loadValidatorOcorrencias" :pendingAnalysis="pendingAnalysis" @especiesTabOcorrenciasPending="especiesTabOcorrenciasPending"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" :dataBibliographyList="dataBibliographyList" @analysis="analysis" :byInstitucional="byInstitucional"/>
                      <!-- /conteudo aba ocorrências -->
                      <!-- conteudo aba referências -->
                      <EspeciesTabReferencias :aba="aba" :form="form" :loadValidatorReferencias="loadValidatorReferencias" :pendingAnalysis="pendingAnalysis"  @especiesTabReferenciasPending="especiesTabReferenciasPending"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" :byInstitucional="byInstitucional"/>
                      <!-- /conteudo aba referências -->
                      <!-- conteudo aba projetos -->
                      <EspeciesTabProjetos :aba="aba" :form="form" :loadValidatorProjetos="loadValidatorProjetos" :pendingAnalysis="pendingAnalysis"  @especiesTabProjetosPending="especiesTabProjetosPending"
                        :onlyLoadValidator="onlyLoadValidator" @continueSetAba="continueSetAba" @continueSave="continueSave" :byInstitucional="byInstitucional"/>
                      <!-- conteudo aba projetos -->
                  </div>
                  <!-- /conteudo abas -->
                </div>
            </div>
          </div>
      </div>
    </div>
    <!-- /tabela -->
    <!-- Modal -->
    <AdminModalReview :form="form" :modalValues="modalValues" v-if="!byInstitucional"/>
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import EspeciesTabs from './EspeciesTabs'
import EspeciesTabTaxonomia from './EspeciesTabTaxonomia'
import EspeciesTabEcologiaEUso from './EspeciesTabEcologiaEUso'
import EspeciesTabIntroducaoDispersao from './EspeciesTabIntroducaoDispersao'
import EspeciesTabImpacto from './EspeciesTabImpacto'
import EspeciesTabManejo from './EspeciesTabManejo'
import EspeciesTabAnaliseDeRisco from './EspeciesTabAnaliseDeRisco'
import EspeciesTabOcorrencias from './EspeciesTabOcorrencias'
import EspeciesTabReferencias from './EspeciesTabReferencias'
import EspeciesTabProjetos from './EspeciesTabProjetos'
import AdminModalReview from '@/components/admin/AdminModalReview'
export default {
  name: 'EspeciesForm',
  props: {
    byInstitucional: Boolean,
    speciesId: Number,
    viewEspecie: Boolean
  },
  components: {
    PageTitle,
    EspeciesTabs,
    EspeciesTabTaxonomia,
    EspeciesTabEcologiaEUso,
    EspeciesTabIntroducaoDispersao,
    EspeciesTabImpacto,
    EspeciesTabManejo,
    EspeciesTabAnaliseDeRisco,
    EspeciesTabOcorrencias,
    EspeciesTabReferencias,
    EspeciesTabProjetos,
    AdminModalReview
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.especies')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      aba: 1,
      nextAba: 0,
      form: {
        species_type: 1,
        dataCommonNames: [],
        dataSynonyms: [],
        dataIntroduction: [],
        riskAnalysis: [],
        taxonomyGenus: {
          taxonomyFamily: { taxonomyOrder: { taxonomyClass: { taxonomyPhyllum: { taxonomyKingdom: {} } } } }
        },
        log_update: {}
      },
      dataBibliographyList: [],
      onlyLoadValidator: true,
      loadValidatorTaxonomia: false,
      validatorTaxonomia: {},
      loadValidatorEcologiaEUso: false,
      validatorEcologiaEUso: {},
      loadValidatorIntroducaoDispersao: false,
      validatorIntroducaoDispersao: {},
      loadValidatorImpacto: false,
      validatorImpacto: {},
      loadValidatorManejo: false,
      validatorManejo: {},
      loadValidatorAnaliseDeRisco: false,
      validatorAnaliseDeRisco: {},
      loadValidatorOcorrencias: false,
      validatorOcorrencias: {},
      loadValidatorReferencias: false,
      validatorReferencias: {},
      loadValidatorProjetos: false,
      validatorProjetos: {},
      startUpload: false,
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      tabManejoPending: false,
      tabTaxonomiaPending: false,
      tabEcologiaEUsoPending: false,
      tabIntroducaoDispersaoPending: false,
      tabImpactoPending: false,
      tabAnaliseDeRiscoPending: false,
      tabOcorrenciasPending: false,
      tabReferenciasPending: false,
      tabProjetosPending: false
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    },
    placesPendingAnalysis: function () {
      return this.form.places_pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    }
  },
  methods: {
    especiesTabManejoPending: function (value) {
      if (!this.tabManejoPending) {
        this.tabManejoPending = value
      }
    },
    especiesTabTaxonomiaPending: function (value) {
      if (!this.tabTaxonomiaPending) {
        this.tabTaxonomiaPending = value
      }
    },
    especiesTabEcologiaEUsoPending: function (value) {
      if (!this.tabEcologiaEUsoPending) {
        this.tabEcologiaEUsoPending = value
      }
    },
    especiesTabIntroducaoDispersaoPending: function (value) {
      if (!this.tabIntroducaoDispersaoPending) {
        this.tabIntroducaoDispersaoPending = value
      }
    },
    especiesTabImpactoPending: function (value) {
      if (!this.tabImpactoPending) {
        this.tabImpactoPending = value
      }
    },
    especiesTabAnaliseDeRiscoPending: function (value) {
      if (!this.tabAnaliseDeRiscoPending) {
        this.tabAnaliseDeRiscoPending = value
      }
    },
    especiesTabOcorrenciasPending: function (value) {
      if (!this.tabOcorrenciasPending) {
        this.tabOcorrenciasPending = value
      }
    },
    especiesTabReferenciasPending: function (value) {
      if (!this.tabReferenciasPending) {
        this.tabReferenciasPending = value
      }
    },
    especiesTabProjetosPending: function (value) {
      if (!this.tabProjetosPending) {
        this.tabProjetosPending = value
      }
    },
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/Specie/${this.form.species_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/Specie/${id}`)
        .then(response => {
          this.form = response.data
          this.nameEdit = ` ${this.form.scientific_name}`
          this.form.log_update = JSON.parse(this.form.log_update)
          const listLang = this.form.dataCommonNames.filter(e => {
            if (!e.vocLanguage) { return false }
            if (this.$i18n.locale === 'en') { return e.vocLanguage.pt === 'Inglês' }
            if (this.$i18n.locale === 'es') { return e.vocLanguage.pt === 'Espanhol' }
            if (this.$i18n.locale === 'pt') { return e.vocLanguage.pt === 'Português' }
          })
          const list = this.form.dataCommonNames.filter(e => {
            if (!e.vocLanguage) { return true }
            if (this.$i18n.locale === 'en') { return e.vocLanguage.pt !== 'Inglês' }
            if (this.$i18n.locale === 'es') { return e.vocLanguage.pt !== 'Espanhol' }
            if (this.$i18n.locale === 'pt') { return e.vocLanguage.pt !== 'Português' }
          })
          this.form.dataCommonNames = listLang.concat(list)
        })
    },
    loadValidator: function (value) {
      switch (this.aba) {
        case 1:
          this.loadValidatorTaxonomia = value
          break
        case 2:
          this.loadValidatorEcologiaEUso = value
          break
        case 3:
          this.loadValidatorIntroducaoDispersao = value
          break
        case 4:
          this.loadValidatorImpacto = value
          break
        case 5:
          this.loadValidatorManejo = value
          break
        case 6:
          this.loadValidatorAnaliseDeRisco = value
          break
        case 7:
          this.loadValidatorOcorrencias = value
          break
        case 8:
          this.loadValidatorReferencias = value
          break
        case 9:
          this.loadValidatorProjetos = value
          break
        default:
          break
      }
    },
    setAba: function (num) {
      this.nextAba = num
      this.onlyLoadValidator = true
      this.loadValidator(true)
    },
    continueSetAba: function (v, obj) {
      this.loadValidator(false)
      if (!v.form.$invalid) {
        this.setObjects(obj)
        this.aba = this.nextAba
      }
    },
    save: function () {
      this.onlyLoadValidator = false
      this.loadValidator(true)
    },
    continueSave: function (v, obj) {
      this.setObjects(obj)
      this.form.eicat_date = this.form.eicat_date ? Number(this.form.eicat_date) : null
      this.form.seicat_date = this.form.seicat_date ? Number(this.form.seicat_date) : null
      this.loadValidator(false)
      if (!v.form.$invalid) {
        this.$store.dispatch('showPreload')
        this.form.log_update = JSON.stringify(this.form.log_update)
        if (this.action === this.$actionNew) {
          this.form.species_type = 1
          axios.post('/specie', this.form)
            .then(response => {
              this.action = this.$actionEdit
              this.form.species_id = response.data.species_id
              this.startUpload = true
            })
            .finally(() => {
              this.startUpload = false
            })
        } else {
          axios.put('/specie', this.form)
            .then(response => {
              this.startUpload = true
            })
            .finally(() => {
              this.startUpload = false
              this.form.log_update = JSON.parse(this.form.log_update)
              this.tabProjetosPending = false
              this.tabReferenciasPending = false
              this.tabOcorrenciasPending = false
              this.tabAnaliseDeRiscoPending = false
              this.tabImpactoPending = false
              this.tabIntroducaoDispersaoPending = false
              this.tabEcologiaEUsoPending = false
              this.tabTaxonomiaPending = false
              this.tabManejoPending = false
            })
        }
      }
    },
    setObjects: function (obj) {
      if (obj && obj.relPrefInvEnvSelected) {
        this.form.relPrefInvEnv = []
        obj.relPrefInvEnvSelected.forEach(element => {
          this.form.relPrefInvEnv.push({ pref_inv_env_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relBiologicalFormSelected) {
        this.form.relBiologicalForm = []
        obj.relBiologicalFormSelected.forEach(element => {
          this.form.relBiologicalForm.push({ biological_form_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relReproductionSelected) {
        this.form.relReproduction = []
        obj.relReproductionSelected.forEach(element => {
          this.form.relReproduction.push({ reproduction_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relSpreadSelected) {
        this.form.relSpread = []
        obj.relSpreadSelected.forEach(element => {
          this.form.relSpread.push({ spread_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relDietSelected) {
        this.form.relDiet = []
        obj.relDietSelected.forEach(element => {
          this.form.relDiet.push({ diet_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relEconomicUseSelected) {
        this.form.relEconomicUse = []
        obj.relEconomicUseSelected.forEach(element => {
          this.form.relEconomicUse.push({ economic_use_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relRouteSelected) {
        this.form.relRoute = []
        obj.relRouteSelected.forEach(element => {
          this.form.relRoute.push({ route_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relPathwaysCdbSelected) {
        this.form.relPathwaysCdb = []
        obj.relPathwaysCdbSelected.forEach(element => {
          this.form.relPathwaysCdb.push({ pathways_cdb_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relVectorsCdbSelected) {
        this.form.relVectorsCdb = []
        obj.relVectorsCdbSelected.forEach(element => {
          this.form.relVectorsCdb.push({ vectors_cdb_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relVectorSelected) {
        this.form.relVector = []
        obj.relVectorSelected.forEach(element => {
          this.form.relVector.push({ vector_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relImpactsSelected) {
        this.form.relImpacts = []
        obj.relImpactsSelected.forEach(element => {
          this.form.relImpacts.push({ impact_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relEicatSelected) {
        this.form.relEicat = []
        obj.relEicatSelected.forEach(element => {
          this.form.relEicat.push({ eicat_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relSeicatSelected) {
        this.form.relSeicat = []
        obj.relSeicatSelected.forEach(element => {
          this.form.relSeicat.push({ seicat_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relImpactMechanismEicatSelected) {
        this.form.relImpactMechanismEicat = []
        obj.relImpactMechanismEicatSelected.forEach(element => {
          this.form.relImpactMechanismEicat.push({ impact_mechanism_eicat_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relImpactMechanismSeicatSelected) {
        this.form.relImpactMechanismSeicat = []
        obj.relImpactMechanismSeicatSelected.forEach(element => {
          this.form.relImpactMechanismSeicat.push({ impact_mechanism_seicat_id: element, species_id: this.form.species_id })
        })
      }
      if (obj && obj.relDistributionSelected) {
        this.form.relDistribution = []
        obj.relDistributionSelected.forEach(element => {
          this.form.relDistribution.push({ distribution_id: element, species_id: this.form.species_id })
        })
      }
    },
    cancel: function () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/especies')
      }
    },
    setTaxonomiaGenus: function (genus) {
      this.form.taxonomyGenus = genus
    },
    getDataBibliography: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/bibliography/getallcombo', { params })
        .then(response => {
          this.dataBibliographyList = response.data.items
        })
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: function (label, input, data, data1, data2) {
      let newValue = ''
      let oldValue = ''
      this.modalValues.label = label
      switch (input) {
        case 'scientific_name':
          this.modalValuesSimple(this.form.scientific_name, this.form.log_update.Scientific_name, label)
          break
        case 'dataCommonNames':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.form.dataCommonNames.forEach(e => {
            this.modalValues.newValue.push({ id: e.common_name_id, value: e.common_name })
          })
          this.modalValues.oldValue = []
          this.form.log_update.DataCommonNames.forEach(e => {
            this.modalValues.oldValue.push({ id: e.Common_name_id, value: e.Common_name })
          })
          break
        case 'image_credit':
          this.modalValuesSimple(this.form.image_credit, this.form.log_update.Image_credit, label)
          break
        case 'dataSynonyms':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.form.dataSynonyms.forEach(e => {
            this.modalValues.newValue.push({ id: e.synonymous_id, value: e.synonymous })
          })
          this.modalValues.oldValue = []
          this.form.log_update.DataSynonyms.forEach(e => {
            this.modalValues.oldValue.push({ id: e.Synonymous_id, value: e.Synonymous })
          })
          break
        case 'author':
          this.modalValuesSimple(this.form.author, this.form.log_update.Author, label)
          break
        case 'subspecies':
          this.modalValuesSimple(this.form.subspecies, this.form.log_update.Subspecies, label)
          break
        case 'sub_author':
          this.modalValuesSimple(this.form.sub_author, this.form.log_update.Sub_author, label)
          break
        case 'genus_id':
          newValue = data.filter(l => l.genus_id === this.form.genus_id)[0]
          oldValue = data.filter(l => l.genus_id === this.form.log_update.Genus_id)[0]
          this.modalValuesSimple(newValue.genus, oldValue.genus, label)
          break
        case 'origin':
          newValue = data.filter(l => l.id === this.form.origin)[0]
          oldValue = data.filter(l => l.id === this.form.log_update.Origin)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'habitat':
          newValue = data.filter(l => l.id === this.form.habitat)[0]
          oldValue = data.filter(l => l.id === this.form.log_update.Habitat)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'native_distribution_area':
          this.modalValuesSimple(this.form.native_distribution_area, this.form.log_update.Native_distribution_area, label)
          break
        case 'natural_environment':
          this.modalValuesSimple(this.form.natural_environment, this.form.log_update.Natural_environment, label)
          break
        case 'morphophysiology_description':
          this.modalValuesSimple(this.form.morphophysiology_description, this.form.log_update.Morphophysiology_description, label)
          break
        case 'relPrefInvEnvSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.pref_inv_env_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.pref_inv_env_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.pref_inv_env_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.pref_inv_env_id, value: oldValue.value })
          })
          break
        case 'invasion_preferential_environments':
          this.modalValuesSimple(this.form.invasion_preferential_environments, this.form.log_update.Invasion_preferential_environments, label)
          break
        case 'world_invasive_places':
          this.modalValuesSimple(this.form.world_invasive_places, this.form.log_update.World_invasive_places, label)
          break
        case 'relBiologicalFormSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.form_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.form_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.form_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.form_id, value: oldValue.value })
          })
          break
        case 'relReproductionSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.reproduction_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.reproduction_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.reproduction_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.reproduction_id, value: oldValue.value })
          })
          break
        case 'relSpreadSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.spread_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.spread_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.spread_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.spread_id, value: oldValue.value })
          })
          break
        case 'relDietSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.diet_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.diet_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.diet_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.diet_id, value: oldValue.value })
          })
          break
        case 'relEconomicUseSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.economical_use_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.economical_use_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.economical_use_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.economical_use_id, value: oldValue.value })
          })
          break
        case 'economic_use':
          this.modalValuesSimple(this.form.economic_use, this.form.log_update.Economic_use, label)
          break
        case 'pathways_cdb_id':
          newValue = data.filter(l => l.pathways_cdb_id === this.form.pathways_cdb_id)[0]
          oldValue = data.filter(l => l.pathways_cdb_id === this.form.log_update.Pathways_cdb_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'relRouteSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.route_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.route_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.route_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.route_id, value: oldValue.value })
          })
          break

        case 'relPathwaysCdbSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.pathways_cdb_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.pathways_cdb_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.pathways_cdb_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.pathways_cdb_id, value: oldValue.value })
          })
          break
        case 'relVectorsCdbSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.vectors_cdb_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.vectors_cdb_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.vectors_cdb_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.vectors_cdb_id, value: oldValue.value })
          })
          break

        case 'vectors_cdb_id':
          newValue = data.filter(l => l.vectors_cdb_id === this.form.vectors_cdb_id)[0]
          oldValue = data.filter(l => l.vectors_cdb_id === this.form.log_update.Vectors_cdb_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'relVectorSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.vector_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.vector_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.vector_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.vector_id, value: oldValue.value })
          })
          break
        case 'relImpactsSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.impact_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.impact_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.impact_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.impact_id, value: oldValue.value })
          })
          break
        case 'relEicatSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.eicat_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.eicat_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.eicat_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.eicat_id, value: oldValue.value })
          })
          break
        case 'relSeicatSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.seicat_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.seicat_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.seicat_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.seicat_id, value: oldValue.value })
          })
          break
        case 'relImpactMechanismEicatSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.impact_mechanism_eicat_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.impact_mechanism_eicat_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.impact_mechanism_eicat_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.impact_mechanism_eicat_id, value: oldValue.value })
          })
          break
        case 'relImpactMechanismSeicatSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.impact_mechanism_seicat_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.impact_mechanism_seicat_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.impact_mechanism_seicat_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.impact_mechanism_seicat_id, value: oldValue.value })
          })
          break
        case 'biodiversity_impact':
          this.modalValuesSimple(this.form.biodiversity_impact, this.form.log_update.Biodiversity_impact, label)
          break
        case 'economic_impact':
          this.modalValuesSimple(this.form.economic_impact, this.form.log_update.Economic_impact, label)
          break
        case 'social_impact':
          this.modalValuesSimple(this.form.social_impact, this.form.log_update.Social_impact, label)
          break
        case 'health_impact':
          this.modalValuesSimple(this.form.health_impact, this.form.log_update.Health_impact, label)
          break
        case 'eicat':
          this.modalValuesSimple(this.form.eicat, this.form.log_update.Eicat, label)
          break
        case 'eicat_bibliography_id':
          newValue = data.filter(l => l.bibliography_id === this.form.eicat_bibliography_id)[0]
          oldValue = data.filter(l => l.bibliography_id === this.form.log_update.Eicat_bibliography_id)[0]
          this.modalValuesSimple(newValue ? newValue.title : '', oldValue ? oldValue.title : '', label)
          break
        case 'eicat_date':
          this.modalValuesSimple(this.form.eicat_date, this.form.log_update.Eicat_date, label)
          break
        case 'seicat':
          this.modalValuesSimple(this.form.seicat, this.form.log_update.Seicat, label)
          break
        case 'impact_mechanism_eicat':
          this.modalValuesSimple(this.form.impact_mechanism_eicat, this.form.log_update.Impact_mechanism_eicat, label)
          break
        case 'impact_mechanism_seicat':
          this.modalValuesSimple(this.form.impact_mechanism_seicat, this.form.log_update.Impact_mechanism_seicat, label)
          break
        case 'certainty_eicat':
          newValue = data.filter(l => l.confidence_id === this.form.eicat_confidence_id)[0]
          oldValue = data.filter(l => l.confidence_id === this.form.log_update.Eicat_confidence_id)[0]
          this.modalValuesSimple(newValue.valor, oldValue.valor, label)
          break
        case 'certainty_seicat':
          newValue = data.filter(l => l.confidence_id === this.form.seicat_confidence_id)[0]
          oldValue = data.filter(l => l.confidence_id === this.form.log_update.Seicat_confidence_id)[0]
          this.modalValuesSimple(newValue.valor, oldValue.valor, label)
          break
        case 'seicat_bibliography_id':
          newValue = data.filter(l => l.bibliography_id === this.form.seicat_bibliography_id)[0]
          oldValue = data.filter(l => l.bibliography_id === this.form.log_update.Seicat_bibliography_id)[0]
          this.modalValuesSimple(newValue ? newValue.title : '', oldValue ? oldValue.title : '', label)
          break
        case 'seicat_date':
          this.modalValuesSimple(this.form.seicat_date, this.form.log_update.Seicat_date, label)
          break
        case 'prevention':
          this.modalValuesSimple(this.form.prevention, this.form.log_update.Prevention, label)
          break
        case 'preventionMMA':
          this.modalValuesSimple(this.form.prevention_MMA, this.form.log_update.Prevention_MMA, label)
          break
        case 'early_detection_measures':
          this.modalValuesSimple(this.form.early_detection_measures, this.form.log_update.Early_detection_measures, label)
          break
        case 'physical_control':
          this.modalValuesSimple(this.form.physical_control, this.form.log_update.Physical_control, label)
          break
        case 'chemical_control':
          this.modalValuesSimple(this.form.chemical_control, this.form.log_update.Chemical_control, label)
          break
        case 'control_biological':
          this.modalValuesSimple(this.form.control_biological, this.form.log_update.Control_biological, label)
          break
        case 'risk_analysis_date':
          this.modalValuesSimple(this.form.risk_analysis_date, this.form.log_update.Risk_analysis_date.split('T')[0], label)
          break
        case 'risk_analysis_confidence_id':
          newValue = data.filter(l => l.confidence_id === this.form.risk_analysis_confidence_id)[0]
          oldValue = data.filter(l => l.confidence_id === this.form.log_update.Risk_analysis_confidence_id)[0]
          this.modalValuesSimple(newValue.valor, oldValue.valor, label)
          break
        case 'risk_analysis_comments':
          this.modalValuesSimple(this.form.risk_analysis_comments, this.form.log_update.Risk_analysis_comments, label)
          break
        case 'risk_analysis_bibliography_id':
          newValue = data.filter(l => l.bibliography_id === this.form.risk_analysis_bibliography_id)[0]
          oldValue = data.filter(l => l.bibliography_id === this.form.log_update.Risk_analysis_bibliography_id)[0]
          this.modalValuesSimple(newValue.title, oldValue.title, label)
          break
        case 'risk_analysis_url':
          this.modalValuesSimple(this.form.risk_analysis_url, this.form.log_update.Risk_analysis_url, label)
          break
        case 'relDistributionSelected':
          this.modalValues.isList = true
          this.modalValues.newValue = []
          data1.forEach(e => {
            newValue = data.filter(l => l.distribution_id === e)[0]
            this.modalValues.newValue.push({ id: newValue.distribution_id, value: newValue.value })
          })
          this.modalValues.oldValue = []
          data2.forEach(e => {
            oldValue = data.filter(l => l.distribution_id === e)[0]
            this.modalValues.oldValue.push({ id: oldValue.distribution_id, value: oldValue.value })
          })
          break
      }
    }
  },
  created: async function () {
    if (this.viewEspecie) {
      this.action = this.$actionEdit
      await this.getById(this.speciesId)
    } else {
      this.action = this.$route.params.id !== this.$actionNew ? this.$actionEdit : this.$actionNew
      if (this.action === this.$actionEdit) {
        this.pageTitle = `${this.$t('label.especies')} > ${this.$t('label.editando')}:`
        await this.getById(this.$route.params.id)
      }
    }

    this.getDataBibliography()
  }
}
</script>
