export const ptBR = {
  message: {
    requiredField: 'Campo obrigatório',
    noRecords: 'Nenhum registro encontrado!',
    confirmDel: 'Tem certeza de que deseja excluir?',
    confirmApproval: 'Tem certeza de que deseja aprovar esta ocorrência?',
    noResultsFor: 'Nenhum resultado para'
  },
  login: {
    username: 'Usuário',
    password: 'Senha',
    login: 'Login',
    entrar: 'Entrar',
    descricao: 'Entre com seus dados de acesso no formulário abaixo:',
    logout: 'Sair',
    myaccount: 'Minha conta'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Voltar',
    outro: 'Outro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acesse a base de dados',
    conhecaNossaRede: 'Rede Latino Americana',
    baseDadosNacional: 'Base de dados da Jamaica',
    politicaDeDados: 'Política de dados',
    comiteCientifico: 'Grupo de Trabalho',
    colabore: 'Colabore',
    especiesMarinhas: 'Espécies marinhas',
    peixesAquario: 'Peixes de aquário',
    peixesAquicultura: 'Peixes de aquicultura',
    pescaDesportiva: 'Pesca desportiva',
    pets: 'Pets',
    plantasForrageiras: 'Plantas forrageiras',
    plantasOrnamentais: 'Plantas ornamentais',
    usoFlorestal: 'Uso florestal',
    tutorial: 'Tutorial',
    especies: 'Espécies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referências',
    projetos: 'Projetos',
    saibaMais: 'Saiba mais',
    contida: 'Contida',
    casual: 'Casual',
    estabelecida: 'Estabelecida',
    invasora: 'Invasora',
    exibirListagem: 'Exibir listagem',
    concluir: 'Concluir',
    fechar: 'Fechar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clique aqui',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Exibir 10 registros',
    exibir25Reg: 'Exibir 25 registros',
    exibir50Reg: 'Exibir 50 registros',
    exibir100Reg: 'Exibir 100 registros',
    limpar: 'Limpar',
    buscar: 'Buscar',
    addColaborador: 'Adicionar colaborador/a',
    nome: 'Nome',
    sobrenome: 'Sobrenome',
    aprovarColaborador: 'Aprovar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidade',
    instituicao: 'Instituição',
    endereco: 'Endereço',
    estado: 'Estado',
    cidade: 'Cidade',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Telefone 1',
    telefone2: 'Telefone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Mídia social 1',
    midiaSocial2: 'Mídia social 2',
    midiaSocial3: 'Mídia social 3',
    observacoesAdicionais: 'Observações adicionais',
    adicionar: 'Adicionar',
    editando: 'Editando',
    acoes: 'Ações',
    usuarixs: 'Usuárixs',
    adicionarUsuario: 'Adicionar usuárix',
    descricao: 'Descrição',
    ativo: 'Ativo',
    sim: 'Sim',
    nao: 'Não',
    salvarUsuarix: 'Salvar usuárix',
    senha: 'Senha',
    observacoes: 'Observações',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrator',
    taxonomia: 'Taxonomia',
    nomeCientifico: 'Nome científico',
    adicionarEspecie: 'Adicionar espécie',
    genero: 'Gênero',
    familia: 'Família',
    ordem: 'Ordem',
    classe: 'Classe',
    phylumDivisao: 'Phylum / Divisão',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origem',
    descricaoInvalida: 'Descrição inválida',
    selecioneFamilia: 'Por favor, selecione uma Família.',
    selecioneOrdem: 'Por favor, selecione uma Ordem.',
    selecioneClasse: 'Por favor, selecione uma Classe.',
    selecioneDivisao: 'Por favor, selecione um Phylum / Divisão.',
    selectioneReino: 'Por favor, selecione um Reino.',
    selectioneGenero: 'Por favor, selecione um Gênero.',
    addReferencia: 'Adicionar referência',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprovar referência',
    salvarReferencia: 'Salvar referência',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Local (Ex. Salvador, BA  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Local',
    edicao: 'Edição (ex. 2 ed.)',
    editora: 'Editora',
    revista: 'Revista',
    anoPublicacao: 'Ano da publicação',
    meioDivulgacao: 'Meio de divulgação',
    volume: 'Volume (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palavra-chave',
    anoPublic: 'Ano publicação',
    addProjeto: 'Adicionar projeto',
    aprovarProjeto: 'Aprovar Projeto',
    salvarProjeto: 'Salvar projeto',
    contato: 'Contato',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descrição',
    dataInicio: 'Data início',
    dataTermino: 'Data término',
    comentarios: 'Comentários',
    anoInicio: 'Ano de início',
    anoTermino: 'Ano de término',
    ocorrencias: 'Ocorrências',
    exportarDados: 'Exportar dados',
    addOcorrencia: 'Adicionar ocorrência',
    visualizarOcorrencia: 'Visualizar ocorrência',
    alterarOcorrencia: 'Alterar ocorrência',
    ocorrenciasVinculadas: 'Ocorrências vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Espécie',
    aprovarOcorrencia: 'Aprovar ocorrência',
    salvarOcorrencia: 'Salvar ocorrência',
    colaborador: 'Colaborador/a',
    municipio: 'Município',
    municipioSede: 'Município (sede)',
    municipioCentroide: 'Município (centróide)',
    referenciaLocal: 'Referência local',
    referencia: 'Referência',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'As coordenadas correspondem',
    distanciaAproximada: 'Distância aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Bacia hidrográfica',
    descricaoInvasao: 'Descrição da invasão',
    abundancia: 'Abundância',
    situacaoPopulacional: 'Situação populacional',
    manejo: 'Manejo',
    dataEntrada: 'Data de entrada',
    dataObservacao: 'Data de observação (ex. 2020 ou 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'É um espécime de coleção zoológica ou herbário',
    selecioneTipo: 'Selecione o tipo',
    herbario: 'Herbário',
    museu: 'Coleção zoológica',
    numeroExemplaInstituicao: 'Número de exemplar (Instituição)',
    numeroExemplarColetor: 'Número de exemplar (Coletor)',
    selecioneOpcao: 'Selecione uma opção...',
    pointList1: 'A um ponto de ocorrência de um ou mais indivíduos da espécie',
    pointList2: 'Ao centro de um grupo de indivíduos da espécie',
    pointList3: 'A um ponto próximo da ocorrência, a uma distância aproximada de:',
    coordinatesSourceList1: 'A - Uma unidade política ou administrativa',
    coordinatesSourceList2: 'B - O local real da ocorrência',
    ultimoRegistroBaseDados: 'Último registros na base de dados',
    ultimosAcessos: 'Últimos acessos',
    fichaTecnicaEspecie: 'Ficha técnica das espécies',
    fichaTecnicaEspecieMMA: 'Ficha técnica das espécies MMA versão 1',
    fichaTecnicaEspecieMMA2: 'Ficha técnica das espécies MMA versão 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Dados insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponíveis para exportação:',
    biologiaEcologia: 'Biologia e ecologia',
    usoEconomico: 'Uso econômico',
    usoEconomicoDescricao: 'Uso econômico - descrição',
    invasaoBiologica: 'Invasão biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nome científico + sinônimos',
    nomesPopulares: 'Nomes populares',
    reproducao: 'Reprodução',
    dispersao: 'Dispersão',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes mais suscetíveis à invasão',
    tipoIntroducao: 'Tipo de introdução',
    causaIntroducao: 'Causa de introdução',
    localIntroducao: 'Local de introdução',
    anoIntroducao: 'Ano da introdução',
    marinho: 'Marinho',
    dulcicola: 'Água doce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar espécie',
    salvarEspecie: 'Salvar espécie',
    data: 'Data',
    confiabilidade: 'Confiabilidade',
    resultadosAnalisesRisco: 'Resultados de análises de risco',
    risco: 'Risco',
    areaOrigem: 'Área de origem',
    areaDistribuicaoNatural: 'Área de distribuição natural',
    ambientesNaturais: 'Ambientes naturais',
    descricaoEspecie: 'Descrição da espécie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes mais suscetíveis à invasão - descrição',
    outrosLocaisOndeEspecieInvasora: 'Outros locais onde a espécie é invasora',
    impactosEcologicos: 'Impactos ecológicos',
    impactosEconomicos: 'Impactos econômicos',
    impactosSociais: 'Impactos sociais',
    impactosSaude: 'Impactos à saúde',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nível de confiança da avaliação EICAT',
    referenciaEICAT: 'Referência EICAT',
    dataEICAT: 'Data EICAT (ex. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nível de confiança da avaliação SEICAT',
    referenciaSEICAT: 'Referência SEICAT',
    dataSEICAT: 'Data SEICAT (ex. 2021)',
    digitarSomenteAno: 'Digitar somente o ano - 2021',
    viasCDBCategorias: 'Vias/vetores CDB - categorias',
    viasIntroducao: 'Vias de introdução e dispersão',
    viasVetoresCDBSubcategorias: 'Vias/vetores CDB - subcategorias',
    vetoresIntroducao: 'Vetores de introdução e dispersão',
    introducao: 'Introdução',
    adicionarIntroducao: 'Adicionar introdução',
    ano: 'Ano',
    descricaoIntroducao: 'Descrição da introdução',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Excluir',
    medidasPreventivas: 'Medidas preventivas',
    medidasPreventivasMMA: 'Medidas preventivas MMA',
    medidasDeteccaoPrecoce: 'Medidas de detecção precoce',
    controleMecanico: 'Controle mecânico',
    controleQuimico: 'Controle químico',
    controleBiologico: 'Controle biológico',
    distribuicaoPais: 'Distribuição no país',
    vejaMapaOcorrencias: 'Veja o mapa de ocorrências',
    ecologiaUso: 'Ecologia e uso',
    introducaoDispersao: 'Introdução e dispersão',
    analiseRisco: 'Análise de risco',
    nomeComum: 'Nome comum',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinônimos',
    autor: 'Autor/a',
    subespecie: 'Subespécie',
    autorSubespecie: 'Autor/a da subespécie',
    pendenteAnalise: 'Pendente de análise',
    cancelarVoltar: 'Cancelar / Voltar',
    versaoAnterior: 'Versão anterior',
    versaoAtual: 'Versão atual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomia - Lista completa de espécies',
    consultaEspeciesOrigemAmbientes: 'Espécies, origem, ambientes de invasão e impactos',
    consultaEspeciesManejo: 'Espécies e alternativas de manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica da espécie sem ocorrências',
    consultaEspeciesOcorrencias: 'Espécies e locais de ocorrência, com coordenadas',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocorrências App',
    dataCadastro: 'Data de cadastro',
    criadoPor: 'Criado por',
    nivelCerteza: 'Nível de certeza',
    byApp: 'Por aplicativo móvel',
    verDados: 'Ver dados',
    appuser: 'App user',
    profissao: 'Profissão',
    colobadorJaCadastrado: 'Colaborador já cadastrado',
    especialidadeApp: 'Especialidade App',
    cep: 'CEP',
    categoriaCDB: 'Categoria CDB',
    subCategoriaCDB: 'Subcategoria CDB',
    mensagemEnviada: 'Mensagem enviada com sucesso.',
    detectadaNatureza: 'Detectada na natureza',
    erradicada: 'Erradicada',
    exportar: 'Exportar'
  },
  home: {
    destaque1: 'Banco de Dados Nacional de Espécies Exóticas Invasoras da Jamaica',
    destaqueDescricao1: 'Este sistema de banco de dados recebe contribuições de pessoas comprometidas com a conservação da biodiversidade. Lxo convidá-lo a visitar a nossa página!',
    destaque2: 'Desde 2006 documentando espécies exóticas invasoras e compartilhando as informações online!',
    destaqueDescricao2: 'Este sistema de banco de dados foi desenvolvido e se mantém atualizado com recursos nacionais e internacionais e conta com um Grupo de Trabalho para apoio.',
    destaque3: 'O que inclui este sistema de informação?',
    destaqueDescricao3: 'Gerenciamos quatro bancos de dados interligados sobre espécies e ocorrências, colaboradores, referências e projetos.',
    destaque4: 'Como funciona?',
    destaqueDescricao4: 'Você pode consultar sobre espécies exóticas invasoras que impactam o meio ambiente, seus pontos de ocorrência na Jamaica, especialistas, projetos e referências.',
    titulo: 'Consulte as espécies, filtre e exporte dados',
    conteudo: 'Aqui você encontra informações sobre a área de origem das espécies exóticas invasoras, características ecológicas e biológicas, ambientes invadidos, onde são invasoras no mundo e na Jamaica, incluindo um mapa de ocorrências já registradas, histórico de introdução e uso, vetores e vias de introdução e dispersão, informações sobre manejo e referências. Encontra filtros para seleção de informações e pode exportar os dados de interesse para diferentes formatos.',
    conteudoEspecies: 'Lista de espécies, características ecológicas, área de origem, onde é invasora mundo afora, vias e vetores de introdução, análise de risco, usos, opções de manejo, ocorrência no Brasil, referências.',
    conteudoColaboradores: 'Cadastro de pessoas que já enviaram dados para a base de dados. Se você precisar falar com um especialista ou saber mais sobre uma ocorrência, busque contatos aqui.',
    conteudoReferencias: 'Listagem completa de referências utilizadas como base para o preenchimento das informações sobre espécies exóticas invasoras na base de dados.',
    conteudoProjetos: 'Projetos de pesquisa e de manejo desenvolvidos para espécies exóticas invasoras no Brasil. Se você tem um projeto em andamento, escreva-nos para que possamos fazer a inclusão.',
    titulo1: 'Saiba mais sobre algumas das espécies exóticas invasoras da Jamaica',
    conteudo1: 'Espécies exóticas invasoras pertencem a todos os grupos de organismos e são introduzidas acidentalmente ou voluntariamente por uma variedade de razões, seus efeitos também são muito diversos.'
  },
  areaParceiros: {
    titulo: 'Rede de Bases de Dados de Espécies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguai',
    uruguai: 'Uruguai'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Red Latino Americana',
    paragrafo1: 'Esta rede de bases de dados tem origem no ano de 2005, como parte da rede temática sobre espécies exóticas invasoras I3N (IABIN Invasives Information Network), que era parte da Rede Inter-Americana de Informação sobre Biodiversidade (IABIN), estabelecida pelos governos dos países das Américas no ano de 2001.',
    paragrafo2: 'Cada país tinha um/a Líder na rede, cuja função era desenvolver uma base de dados nacional a fim de compartilhar informação sobre espécies exóticas invasoras.',
    paragrafo2_1: 'As pessoas na liderança das bases de dados nacionais são:',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (desde 2003);',
    marcador2: 'Brasil: Dra. Sílvia R. Ziller, Fundadora e Diretora Executiva do Instituto Hórus de Desenvolvimento e Conservação Ambiental (desde 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Professor da Universidade da Costa Rica. Pesquisadora do Centro de Pesquisas em Biodiversidade e Biologia Tropical. Co-coordenador da Flora Invasiva e Naturalizada da América Central;',
    marcador5: 'Equador: Dr. Diego Inclán, Diretor Executivo do Instituto Nacional de Biodiversidade (desde 2021) e Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras (desde 2023).',
    marcador5_2: 'Jamaica: Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (desde 2007).',
    marcador5_3: 'Paraguai: Hugo Fernando del Castillo, Guyra Paraguay Conservação de Aves (desde 2006);',
    marcador5_4: 'Uruguai: Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (desde 2005), e o M.Sc. Marcelo Ituruburu, Coordenador do Comitê de Espécies Exóticas Invasoras Ministério do Meio Ambiente (desde 2018);',
    paragrafo3: 'A IABIN foi financiada por um projeto do Banco Mundial (GEF) entre os anos de 2004 e 2011, então extinta. Apesar do término da iniciativa, alguns dos Líderes da rede seguem mantendo as bases de dados nacionais (Brasil, Argentina, Uruguai, Paraguai e Jamaica).',
    paragrafo4: 'No ano de 2021, recebemos um recurso da Secretaria da Convenção sobre Diversidade Biológica, como parte da iniciativa BioBridge, para revitalizar e atualizar as bases de dados da Argentina, do Brasil, do Paraguai e do Uruguai. A interface foi atualizada e novas funcionalidades foram embutidas nas bases de dados.',
    paragrafo5: 'Os desenvolvedores da Base de Dados são:',
    marcador6: 'o programador João Scucato, de Curitiba – PR, que desenvolveu a primeira versão em Microsoft Access, 2004-2005;',
    marcador7: 'o programador Alejandro Moreno, da Argentina, está envolvido com a manutenção da rede desde 2006. Manteve cópias dos arquivos e resgatou conteúdos do Paraguai e Uruguai para esta nova edição, além de desenvolver a',
    marcador7_1: 'Plataforma Comum',
    marcador7_2: 'dos países da rede.',
    marcador8: 'o Designer Rafael Moura e pelo Programador Thiago Lôbo, vinculados à',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'em Florianópolis – SC, Brasil; ',
    marcador9: 'o programador Leonardo Rotondano, responsável pelo desenvolvimento do aplicativo para telefone celular usado para aporte de ocorrências de espécies exóticas invasoras.',
    paragrafo6: 'Esperamos voltar a ampliar a rede para mais países das Américas a fim de que mais dados sobre espécies exóticas invasoras fiquem disponíveis para fins de conhecimento público, gestão governamental e manejo aplicado.',
    paragrafo7: 'Rede de Bases de Dados sobre Espécies Exóticas:'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de dados da Jamaica',
    titulo: 'Breve histórico da Base de Dados de Espécies Exóticas Invasoras da Jamaica (JISD)',
    paragrafo1: 'A Base de Dados de Espécies Exóticas Invasoras da Jamaica teve origem na Rede InterAmericana de Informação sobre Biodiversidade (IABIN), formada como resultado da reunião da Cúpula dos Países das Américas de 1996. O Governo da Jamaica endossou a IABIN no final dos anos 1990. A partir desse momento, o Clearing-House Mechanism (CHM) da Jamaica, sediado no Museu de História Natural da Jamaica, no Instituto da Jamaica (Institute of Jamaica), participou ativamente do Grupo de Trabalho Temático da IABIN sobre espécies exóticas invasoras. Através de um projeto da IABIN, o CHM da Jamaica recebeu financiamento para desenvolver a primeira base de dados nacional em 2001 - 2002. A primeira versão dessa base de dados era um arquivo em formato Microsot Excel.',
    paragrafo2: 'Em 2006 - 2007, com recursos da IABIN e do Institute of Jamaica, foi desenvolvido conteúdo para a construção da Base de Dados e os dados foram migrados para o formato Microsoft Access. Além disso, foram estaelecidos padrões para o registro de dados em conformidade com a IABIN.',
    paragrafo3: 'Em 2011, foi desenvolvida uma nova versão da base de dados pela IABIN, em formato de software livre, para uso dos países membros e adaptação às necessidades nacionais. O CHM da Jamaica adotou a plataforma de informação no âmbito do projeto de elaboração da Estratégia Nacional de Biodiversidade e respectivo Plano de Ação (NBSAP), em parceria com o Departamento Nacional de Gestão de Dados Espaciais, a fim de organizar a gestão de conteúdos e a hospedagem da base de dados. Antes do lançamento formal da Base de Dados Nacional (JISD) em 2015, nove (9) entidades aceitaram contribuir periodicamente para desenvolver conteúdos para a base de dados em nível nacional. Além disso, um seminário para treinamento de uso da base de dados e registro de informações foi co-organizado pelo CHM, com um segundo treinamento em 2018. Para tanto, o Programa de Desenvolvimento das Nações Unidas (PNUD) proveu recursos através do projeto NBSAP. À época, a base de dados era o único portal abrangente sobre espécies exóticas invasoras na ilha.',
    paragrafo4: 'Desde 2015, a base de dados (JISD) tem provido informação de suporte para que a Jamaica possa cumprir suas obrigações perante a Convenção sobre Diversidade Biológica das Nações Unidas. A participação de cada entidade na rede da base de dados da Jamaica contribuiu diretamente com dados relevantes para:',
    paragrafo5: 'A Meta Nacional de Biodiversidade número 9, de 2016 - 2021: "Até 2021, espécies exóticas invasoras e vias de introdução e dispersão serão identificadas...", e',
    paragrafo6: 'A Meta de Desenvolvimento Sustentável 15.8: "Prevenir espécies exóticas invasoras em ecossistemas terrestres e aquáticos ".',
    paragrafo7: '',
    paragrafo8: 'Parceiros'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de dados',
    titulo: 'Conceitos e referências',
    criterio: 'Critérios para a inclusão de espécies',
    avaliacao: 'Construção colaborativa  ',
    validacao: 'Validação de dados',
    paragrafo1: 'Para efeitos deste sistema de dados, são adotadas as seguintes definições, de acordo com a Convenção sobre Diversidade Biológica:',
    marcador1: 'espécie nativa: aquela que se encontra dentro da área de distribuição geográfica onde evoluiu e faz parte de uma comunidade biológica considerada natural ou semi-natural;',
    marcador2: 'espécie exótica: aquela que está fora de sua área natural passada ou presente;',
    marcador3: 'espécie exótica invasora: qualquer espécie exótica que afeta ou ameaça habitats, ecossistemas ou espécies, causando alterações em ambientes naturais;',
    marcador3_1: 'espécie criptogênica: aquela espécie cuja origem não pode ser estabelecida com certeza e, portanto, não pode ser classificada como nativa ou exótica.',
    paragrafo2: '',
    paragrafo3: '',
    paragrafo4: '',
    paragrafo5_1: 'The controlled vocabulary on habitats that are more susceptible to invasion, pathways and vectors, biological habit, impacts, and control methods were defined in accordance with the terms used in the Global Invasive Species Database managed by the Invasive Species Specialist Group linked to the IUCN',
    paragrafo5_2: 'The main taxonomic references are taken from the systems proposed by the Missouri Botanical Garden',
    paragrafo5_3: ', and the Integrated Taxonomic Information System',
    paragrafo5_4: 'Supplementary references include the World Register of Marine Species',
    paragrafo5_5: 'the Global Invasive Species Database',
    paragrafo5_6: 'and the CABI Invasive Species Compendium',
    paragrafo6: 'Para cada ponto de ocorrência as espécies são classificadas como:',
    marcador4: 'a presença é limitada ou restrita a sistemas de cultivo ou reprodução, ou vive dependente diretamente de pessoas, por exemplo como num laboratório, um animal de estimação ou de aquário ou uma planta de jardim, sem evidência de escape de indivíduos naquele local;',
    marcador5: 'quando a espécie foi avistada em ambientes naturais ou seminaturais, fora de sistemas de cultivo ou reprodução, ainda sem evidências de ter estabelecido um núcleo populacional;',
    marcador6: 'quando a espécie se reproduz efetivamente, formando uma população autossustentável, mas permanece em uma área limitada, próxima ao local de introdução local;',
    marcador7: 'quando a espécie avança, de forma autônoma ou assistida por vetores antrópicos, em direção a outras áreas além do ponto local de introdução;',
    marcador7_1: 'quando a espécie não existe mais naquele local devido a intervenções de manejo. É importante notar que o status de invasão é um atributo da localidade de ocorrência, não da espécie. Dessa forma, a mesma espécie pode se comportar como invasora em uma localidade, ser contida em outro local e ter sido detectada na natureza em um terceiro local.',
    paragrafo7: 'Assim sendo, é importante que fique claro que uma espécie pode ser invasora num local, ainda estar em fase de latência (adaptação) em outro, e não ser invasora em ainda outro ambiente.',
    paragrafo8: 'Cada informação de ocorrência recebida fica sempre vinculada à fonte, ou seja, à pessoa que a forneceu e/ou a um trabalho científico, incluindo um ou  mais autores. Outros dados são referenciados nos campos descritivos à medida do possível. Se houver dúvidas, questionamentos ou necessidades de mais informação sobre algum dado, a fonte pode ser indicada ou consultada em busca de esclarecimentos. Esse vínculo tem principalmente o intuito de preservar a fonte da informação, reconhecer a autoria e valorizar o trabalho e o apoio dxs colaboradorxs.',
    paragrafo8_en: '',
    paragrafo9_1: 'Se você tiver dados sobre espécies exóticas invasoras para contribuir com a base de dados, por favor utilize a planilha disponível no site e envie para ',
    paragrafo9_2: ' ou entre em contato conosco.',
    paragrafo10: 'Se tiver dúvidas ou questionamentos, por favor escreva para ',
    paragrafo11: 'A Base de Dados de Espécies Exóticas Invasoras da República Argentina inclui espécies exóticas e criptogênicas detectadas em ambientes naturais ou seminaturais do país, bem como aquelas que, embora permanecendo contidas em sistemas de cultivo ou de produção, têm histórico de invasão em outros países. Também estão incluídas espécies que, sendo nativas de alguma região do país, foram translocadas para outras regiões, bacias ou ecossistemas dentro do próprio país. Todas as espécies estão associadas a pelo menos uma localidade de ocorrência no país. Em alguns casos, esse local é o aeroporto ou porto de entrada, quando não há dados pós-importação. O foco desse sistema é a conservação da diversidade biológica e, portanto, somente são incluídas espécies exóticas caracterizadas como ervas daninhas, pragas ou patógenos de pessoas ou animais domésticos quando também tenham sido detectadas em ambientes naturais ou impactando espécies nativas, ou com histórico de invasão nessas áreas. Ainda assim, é muito comum que espécies que afetam a estrutura, composição e funcionamento de ecossistemas naturais e seminaturais também tenham impactos na economia, na saúde e nos valores culturais.',
    paragrafo12: '',
    paragrafo13: 'As bases de dados deste sistema são atualizadas graças à contribuição de informações por colaboradorxs voluntárixs distribuídxs por todo o país. Xs colaboradorxs podem contribuir com informações sobre novas espécies, novos locais de ocorrência de espécies já detectadas no território nacional, projetos, especialistas ou publicações sobre o assunto. As informações de ocorrência ficam vinculadas aos dados de quem as forneceu, como um reconhecimento de suas contribuições para o conhecimento do estado do problema das invasões biológicas no país e como uma forma de promover a comunicação direta entre pessoas com conhecimento e interesse na o assunto. As informações também são obtidas de publicações e registros de espécimes em museus e herbários. Cada novo local de ocorrência é avaliado de acordo com os padrões de validação compartilhados com a Rede de Bases de Dados da América Latina e do Caribe . Os dados estão associados a coordenadas geográficas que permitem mapear a distribuição registrada para cada espécie.',
    paragrafo14: 'Os administradores da base de dados são responsáveis por preencher as informações sobre as características biológicas de cada espécie, os ambientes preferenciais de invasão, vetores e rotas de introdução e dispersão, impactos e técnicas de controle, entre outros.',
    paragrafo14_1: 'Os dados são revisados continuamente, a cada nova unidade de informação recebida. Novas espécies ou locais de ocorrência que impliquem em mudanças significativas na distribuição de uma espécie ou na colonização de novos ambientes são confirmados por consulta com especialistas do Comitê Científico ou outros especialmente convocados com base em sua especialidade.',
    paragrafo15: 'A confiabilidade dos dados é uma das principais preocupações dos administradores deste sistema. A validação da informação é avaliada em dois níveis principais: no que diz respeito à correta identificação de cada táxon e no que diz respeito à exatidão do sítio a que cada dado se refere. Para tal, utilizam-se as normas e critérios acordados com os restantes sistemas que integram a rede de informação do IAS.',
    paragrafo16: 'Critérios para validar a identidade taxonômica de cada táxon',
    marcador8: 'O fornecedor dos dados (autor da publicação ou colecionador do espécime) pode ser considerado um especialista do grupo em questão ou possui experiência equivalente?',
    marcador9: 'A ocorrência é documentada por meio de fotografia que permita a identificação confiável do organismo em questão?',
    marcador10: 'A ocorrência é documentada em publicação indexada e revisada por pares ou em documento técnico elaborado ou revisado por especialistas?',
    marcador11: 'A informação corresponde a um espécime de herbário ou a um espécime depositado em coleção de museu?',
    marcador12: 'A ocorrência corresponde a uma espécie já registrada para o país?',
    marcador13: 'O grupo a que pertence a espécie tem taxonomia estável e bem conhecida?',
    marcador14: 'A taxonomia do grupo ao qual a espécie pertence é estável e conhecida?',
    marcador14_1: 'As características das espécies permitem uma identificação relativamente simples?',
    paragrafo17: 'Uma resposta positiva a pelo menos uma das questões 1, 2, 3 ou 4 leva diretamente à recomendação de considerar os dados como validados. Em caso de resposta negativa a estas quatro questões, a validação será obtida apenas com respostas positivas às três últimas (5, 6 e 7).',
    paragrafo18: 'Critérios para validar o local associado onde uma espécie foi detectada.',
    marcador15: 'Os dados incluem coordenadas geográficas? São consideradas as coordenadas tomadas diretamente no local de observação e outras que correspondem a uma referência geográfica próxima (conforme indicado).',
    marcador16: 'Os dados incluem uma descrição do local que permita sua identificação confiável?',
    marcador17: 'A ocorrência é documentada por fotografia que permite a identificação confiável do local?',
    marcador18: 'As coordenadas geográficas fornecidas coincidem com a localização do local descrito e / ou fotografado?',
    marcador19: 'A espécie já é citada para o país e, nesse caso, a localidade está incluída em sua área de distribuição conhecida ou em local compatível com áreas de presença conhecida e com a capacidade de dispersão da espécie?',
    marcador20: 'As condições ecológicas do local, inferidas com base na localização indicada, são compatíveis com os requisitos gerais de nicho da espécie?',
    paragrafo19: 'Os dados são considerados validados, do ponto de vista da sua localização geográfica, se:',
    marcador21: 'As respostas às perguntas 1, 2 e / ou 3 e 4 são positivas (os dados incluem coordenadas geográficas, uma referência inequívoca ao local é fornecida e / ou uma fotografia é fornecida que permite a identificação confiável do local e as coordenadas fornecidas coincidem com a localização do local descrito ou fotografado, ou,',
    marcador22: 'Uma resposta positiva ou negativa é atribuída à questão 1 (coordenadas geográficas são fornecidas ou não), uma resposta negativa às questões 2 e 3 (o local não é descrito em detalhes ou uma fotografia é anexada para permitir sua localização), mas as perguntas 5 e 6 foram respondidas positivamente (a espécie já foi citada para o país e a localidade está incluída dentro de sua faixa de distribuição conhecida ou em um local que seja compatível com os locais de presença conhecida e com sua capacidade de dispersão, e as condições ecológicas do local são compatíveis com os requisitos gerais de nicho da espécie).',
    paragrafo20: 'O dado de localização não será validado se:',
    marcador23: 'I. A resposta à questão 1 e às questões 2 e / ou 3 forem positivas, mas a resposta à questão 4 for negativa (as coordenadas não coincidem com o local descrito ou fotografado), ou,',
    marcador24: 'II. As respostas às questões 2 e 3 forem negativas, assim como negativas para pelo menos uma das questões 5 ou 6 (os dados não atendem a uma ou nenhuma dessas duas condições: 1- a espécie já é citada para o país e, nesse caso, a localidade esteja incluída em sua faixa de distribuição conhecida ou em um local compatível com os locais de presença conhecida e com sua capacidade de dispersão, 2- o local atende a condições ecológicas compatíveis com os requisitos gerais de nicho da espécie).',
    paragrafo21: 'Por fim, os dados serão validados se a análise de validação dos dois critérios (taxonomia e localização) for aprovada.',
    paragrafo22: 'A validação não se propõe como uma regra de inclusão ou não de um dado, mas como um descritor que acompanha cada local de ocorrência. Cada local de ocorrência de cada espécie está associada ao descritor “dados validados” ou “dados não validados”. É importante entender que essa classificação é temporária, pois os dados podem ser confirmados e validados caso atendam aos critérios definidos em algum momento após serem registrados no sistema. Para facilitar a visualização do estado dos dados, o sistema de mapeamento de distribuição de ocorrências mostra as localidades validadas em verde e as não validadas em cinza.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Grupo de Trabalho',
    titulo: 'O Grupo de Trabalho sobre Espécies Exóticas Invasoras da Jamaica',
    paragrafo1: 'O Grupo de Trabalho sobre Espécies Exóticas Invasoras foi estabelecido para assistir a Jamaica no cumprimento de obrigações referentes à Convenção sobre Diversidade Biológica das Nações Unidas (CDB), especificamente ao Artigo 8(h) que requer dos países “Prevenir a introdução, controlar ou erradicar espécies que ameaçam ecossistemas, hábitats ou espécies”.',
    paragrafo2: 'O Grupo de Trabalho compreende a Seção IV, (a)10 da Decisão VI/23 da CDB e o anexo à Decision VI/23 sobre Orientação para a prevenção, introdução e mitigação de impactos de espécies exóticas invasoras que ameaçam ecossistemas, hábitats ou espécies (veja ',
    paragrafo3: 'Os principais objetivos do Grupo de Trabalho sobre Espécies Exóticas Invasoras incluem:',
    marcador1: 'Prover um fórum para discussão sobre Espécies Exóticas Invasoras e seus impactos reais ou potenciais, especialmente na Jamaica, mas também em nível global;',
    marcador2: 'Preparar e atualizar listas de espécies exóticas que são i) invasoras e ii) potencialmente invasoras na Jamaica;',
    marcador3: 'Identificar e fazer recomendações sobre melhores práticas referentes a medidas de prevenção, detecção precoce, controle e/ou erradicação de espécies exóticas invasoras;',
    marcador4: 'Identificar lacunas e fazer recomendações para pesquisa sobre espécies exóticas invasoras;',
    marcador4b: 'Revisar e fazer recomendações para a elaboração de políticas nacionais, estratégias, legislação, diretrizes, planos de manejo e projetos relacionados a espécies exóticas invasoras, conforme necessário;',
    marcador4c: 'Apoiar o Desenvolvimento de projetos relacionados a espécies exóticas invasoras;',
    marcador4d: 'Assistir com a promoção de educação e informação pública relacionada aos impactos reais e potenciais de espécies exóticas invasoras, incluindo impactos relacionados à saúde humana, e a medidas de controle e erradicação.',
    paragrafo4: 'A Base de Dados Nacional de Espécies Exóticas Invasoras da Jamaica (JISD) é continuamente atualizada e melhorada através de contribuições do Grupo de Trabalho. A lista nacional de espécies exóticas invasoras elaborada pelo Grupo de Trabalho serve como guia para o registro de informações na Base de Dados (JISD). O Grupo de Trabalho também contribui para a validação de informações registradas na Base de Dados.',
    marcador5: '',
    marcador6: '',
    marcador7: '',
    marcador8: '',
    marcador9: '',
    marcador10: '',
    marcador11: ''
  },
  especiesInstitucional: {
    titulo: 'Busca por especies',
    paragrafo1: 'Esta é a parte da base de dados que contém informações sobre espécies exóticas invasoras. Esses dados vêm sendo coletados desde o ano de 2004, quando foi realizado o primeiro levantamento nacional pelo Ministério do Meio Ambiente. As informações são enviadas por colaboradores diversos, incluindo especialistas em distintos grupos biológicos que também ajudam a validar dados, e compiladas a partir de artigos científicos e publicações técnicas.',
    paragrafo2_1: 'Se você tiver dados de ocorrência dessas espécies ou sobre espécies exóticas invasoras ainda não contempladas, ',
    paragrafo2_2: 'contribua para atualizar esta base de dados',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras da Jamaica. Institute of Jamaica. Consultado em DD/MM/AAA.',
    paragrafo4_2: ' ',
    tutorialTitulo1: 'Como citar a base de dados',
    tutorialDescricao1: 'Veja aqui o formato da referência para citação da base de dados em publicações.',
    tutorialTitulo2: 'Use os filtros',
    tutorialDescricao2: 'Aqui temos algumas opções para filtrar conteúdo.',
    tutorialTitulo3: 'Use os filtros: selecione dados',
    tutorialDescricao3: 'Escolha filtros e faça combinações para selecionar dados por local, forma biológica, uso, grupo taxonômico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este é o número de espécies exóticas invasoras na base de dados ou resultante das filtragens realizadas.',
    tutorialDescricao5: 'Aqui você pode selecionar quantos registros visualizar por página.',
    tutorialTitulo6: 'Lista de espécies exóticas invasoras',
    tutorialDescricao6: 'Listagem de todas as espécies ou resultados do uso de filtros. Clique no nome da espécie para ver os dados completos.',
    tutorialTitulo6_1: 'Exportar dados',
    tutorialDescricao6_1: 'Aqui você pode filtrar dados e escolher o formato para exportar.',
    tutorialTitulo7: 'Taxonomia',
    tutorialDescricao7: 'Classificação taxonômica da espécie selecionada, nomes populares, sinônimos e uma foto quando possível!',
    tutorialTitulo8: 'Ecologia e uso',
    tutorialDescricao8: 'Descrição, biologia e ecologia da espécie selecionada, de onde vem, que ambientes invade e usos econômicos.',
    tutorialTitulo9: 'Introdução e dispersão',
    tutorialDescricao9: 'Como a espécie chegou onde é invasora, por quê foi introduzida e como se dispersa para outros lugares.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientais, econômicos, à saúde e culturais. Classificação de impactos ambientais pelo protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detecção precoce, controle mecânico, químico e biológico para a espécie selecionada.',
    tutorialTitulo12: 'Análise de Risco',
    tutorialDescricao12: 'Resultados de análises de risco para invasão biológica realizadas para a espécie selecionada.',
    tutorialTitulo13: 'Ocorrências',
    tutorialDescricao13: 'Locais, ambientes, unidades de conservação, estados onde a espécie ocorre no Brasil e estágio de invasão – veja o mapa!',
    tutorialDescricao14: 'Lista de referências usadas para compilar dados sobre a espécie selecionada e outras publicações que citam a espécie.',
    tutorialTitulo15: 'Projetos',
    tutorialDescricao15: 'Lista de projetos de pesquisa e manejo que contemplam a espécie selecionada.'
  },
  colaboradoresInstitucional: {
    titulo: 'Busca por colaboradorxs',
    paragrafo1: 'Denominamos de colaboradorxs as pessoas que enviam dados a este sistema de informação, assim como as que coletam espécimes para museus ou herbários, são autorxs de publicações utilizadas como fontes de dados ou ajudam na identificação de espécies ou na validalção de informações sobre espécies exóticas invasoras.',
    paragrafo2: '',
    paragrafo3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras da Jamaica. Institute of Jamaica. Consultado em DD/MM/AAA.',
    paragrafo4_2: ''
  },
  especiesMarinhasInstitucional: {
    titulo: 'Espécies marinhas',
    paragrafo1: 'Ecossistemas marinhos são ecossistemas aquáticos relacionados ao mar. A água nesses ecossistemas tem algo conteúdo de sal, como no caso dos oceanos e outros ecossistemas costeiros, como manguezais e estuários.',
    paragrafo2: 'As vias de introdução de espécies exóticas invasoras são a água de lastro de navios, a incrustação em cascos de embarcações e outras estruturas aquáticas, a soltura intencional e não intencional de animais de aquário e o comércio de animais ornamentais.',
    paragrafo3: 'O mexilhão-verde da Ásia (Perna viridis) e o peixe-leão (Pterois volitans) são duas espécies invasoras marinhas. O mexilhão-verde entope e corrói tubulações de água em complexos industriais, incrusta equipamentos marinhos e ameaça a sustentabilidade da pesca de mexilhões e ostras ao crescer sobre suas carapaças.',
    paragrafo3_1: 'O peixe-leão não tem predadores naturais agressivos e pode sobrepujar espécies nativas com quem compete por alimento e espaço, ameaçando a população de peixes de arrecifes de coral na Jamaica.',
    paragrafo4: 'Lionfish - Pterois volitans',
    paragrafo4b: 'Foto: Anthony Chang'
  },
  especiesAguaDoceInstitucional: {
    titulo: 'Espécies de água doce',
    paragrafo1: 'Os ecossistemas de água doce incluem lagos, açudes, rios, córregos, nascentes, áreas úmidas e pântanos. Esses ecossistemas diferem dos marinhos pelo baixo conteúdo de sal na água.',
    paragrafo2: 'Algumas das principais vias de introdução de espécies de água doce são a soltura intencional e o escape de espécies de aquicultura.',
    paragrafo3: 'A lagosta-de-garras-vermelhas da Austrália (Cherax quadricarinatus) e a tilápia-do-nilo (Oreochromis niloticus niloticus) foram inicialmente introduzidas para fins de produção aquícola e agora podem ser encontradas na natureza.',
    paragrafo3_1: 'Outras vias de introdução incluem a soltura de animais de estimação ou de plantas aquáticas ornamentais, como o aguapé (Eichhornia crassipes).',
    paragrafo4: 'Lionfish - Pterois volitans',
    paragrafo4b: 'Foto: Anthony Chang'
  },
  especiesTerrestresInstitucional: {
    titulo: 'Espécies terrestres',
    paragrafo1: 'Ecossistemas terrestres diferem de ecossistemas aquáticos pela predominância de solo, não de água, na superfície. Exemplos incluem florestas, campos e desertos.',
    paragrafo2: 'Existem diversas vias de introdução de espécies em ecossistemas terrestres, incluindo escape de:',
    paragrafo3: 'Plantas ornamentais de áreas de cultivo, como o gengibre-selvagem (Alpinia nigra), introduzido para uso ornamental em jardins e quintais;',
    paragrafo3_1: 'Agentes de controle biológico, como o furão-indiano (Herpestes auropunctatus), que foi importado intencionalmente como agente de controle biológico para ratos em plantações de cana-de-açúcar. O furão-indiano levou à extinção de cinco espécies de vertebrados endêmicos, um lagarto, uma cobra, dois aves e um roedor.',
    paragrafo4: 'Wild coffee - Pittosporum undulatum in Blue Mountains',
    paragrafo4b: 'Foto: S. Otuokon, JCDT',
    paragrafo5: 'Red bush - Persicaria chinensis in Blue Mountains',
    paragrafo5b: 'Foto: S. Otuokon, JCDT'
  },
  especiesAgricolasInstitucional: {
    titulo: 'Espécies agrícolas',
    paragrafo1: 'Este grupo de espécies invasoras tem aumentado o nível de ameaça ou levado à redução significativa da produção de muitas culturas agrícolas, animais e árvores. Algumas dessas espécies são pragas de interesse econômico e requerem intervenção humana para que sejam controladas.',
    paragrafo2: 'A borboleta-rabo-de-andorinha Papilio demoleus é um exemplo de praga de plantas cítricas na Jamaica que afeta principalmente mudas em viveiros e plantas jovens. A remoção manual das larvas, aplicação de inseticidas e a ocorrência de vespas predadoras são alguns dos métodos usados no controle desta praga.',
    paragrafo3: 'Lime Swallowtail Butterfly - Papilio demoleus',
    paragrafo3b: 'Foto: NHMJ collection'
  },
  peixesAquarioInstitucional: {
    titulo: 'Peixes de aquário',
    paragrafo1: 'Os peixes de aquário são os “animais de estimação”, ou “pets”, mais numerosos do mundo atualmente. O Brasil é um grande fornecedor de peixes ornamentais devido à riqueza  da bacia amazônica e do Pantanal. Ainda assim, a maior parte dos peixes comercializados é exótico aos locais de venda. Esses animais jamais devem ser liberados em lagos, rios, ou no mar porque podem predar espécies nativas, alterar o ambiente aquático ou transmitir doenças e parasitos.',
    paragrafo2: 'Além disso, podem se tornar invasores, como é o caso do peixe-leão, que escapou de um aquário na Flórida, EUA, e agora está em todo o mar do Caribe e há risco de chegar à costa brasileira.',
    paragrafo3: 'É um risco grande para recifes de coral, como em Abrolhos e Fernando de Noronha. Jamais libere animais de estimação na natureza!',
    paragrafo4: 'Saiba mais',
    marcador1: 'Peixes ornamentais exóticos ameaçam a biodiversidade',
    marcador2: 'Exóticos invasores',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - carazinho',
    paragrafo5b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenil)',
    paragrafo6b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae carazinho',
    paragrafo7b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - mato-grosso',
    paragrafo8b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - oscar, apaiari',
    paragrafo9b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - paulistinha',
    paragrafo10b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - peixe-leão',
    paragrafo11b: 'Foto: Instituto Hórus'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Peixes de aquicultura',
    paragrafo1: 'A aquicultura é a principal causa de introdução de peixes de água doce no Brasil. Muitas espécies são bastante agressivas e têm histórico de extinguir espécies nativas de peixes em diversos países do mundo, como a tilápia-do-nilo e o bagre-africano. O panga é um peixe da Ásia que está começando a ser criado no Brasil sem autorização do IBAMA, com alto potencial de invasão e impacto na fauna nativa. Procure se informar antes de decidir que peixes vai consumir, de modo a não contribuir para os impactos que podem causar. ',
    paragrafo2: 'O problema é que as estruturas de criação – açudes e tanques – não são seguras e os peixes escapam para o ambiente natural, ou são até mesmo soltos intencionalmente durante o manejo dos tanques. Podem predar espécies nativas, alterar o ambiente aquático ou transmitir doenças e parasitos.',
    paragrafo3: 'Conheça o código de conduta da FAO para a aquicultura responsáve.',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo4: 'Cichla kelberi Tucunaré',
    paragrafo5: 'Coptodon rendalli Tilápia',
    paragrafo6: 'Cyprinus carpio Carpa-comum',
    paragrafo7: 'Micropterus salmoides - Black bass',
    paragrafo7b: 'Foto: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus Tilápia-do-nilo'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Pesca desportiva',
    paragrafo1: 'A pesca desportiva é uma atividade de lazer que, com frequência, envolve a introdução de espécies exóticas geralmente predadoras vorazes. Embora a introdução de espécies e a transposição de peixes para bacias hidrográficas onde não ocorrem seja proibida sem autorização do IBAMA (Portaria IBAMA 145-N/1998), muitos exemplares de peixes têm sido retirados de suas bacias hidrográficas de origem e levados a outras onde não são nativos. Esse processo gera impactos a peixes nativos por predação, competição e transmissão de parasitas e doenças, além de desequilíbrios ambientais severos.',
    paragrafo2: 'Associada à pesca desportiva está a introdução de espécies exóticas usadas como iscas vivas, que podem escapar do anzol ou mesmo ser jogadas nos rios ao final da atividade de lazer. Peixes e outras espécies usadas como isca viva de- vem ser usadas apenas se forem nativas do rio onde se realiza a atividade de pesca para evitar a introdução de espécies exóticas e impactos a espécies nativas.',
    paragrafo3: 'Jamais libere animais exóticos na natureza!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Foto: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - tucunaré',
    paragrafo5b: 'Foto: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - tucunaré',
    paragrafo6b: 'Foto: LEPIB - UEL PR',
    paragrafo7: 'Saiba mais'
  },
  petsInstitucional: {
    titulo: 'Pets',
    paragrafo1_1: 'O termo ',
    pet: 'pet',
    paragrafo1_2: ' se refere a animais de companhia, desde peixes de aquário até cães e gatos. Alguns animais incluídos na lista, especialmente cães e gatos, somente são considerados problemáticos quando encontrados no interior de áreas naturais onde predam a fauna nativa e causam impactos severos à conservação da biodiversidade.',
    paragrafo2: 'Não deixe seu animal de estimação ficar solto em áreas naturais!',
    paragrafo3: 'Trachemys scripta - tigre-dágua americano',
    paragrafo3b: 'Foto: Marcos Tortato',
    paragrafo4: 'Felis catus - gato doméstico',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Canis familiaris - cachorro doméstico',
    paragrafo5b: 'Foto: Marcos Tortato',
    paragrafo6: 'Trachemys dorbignyi - tigre-d´água',
    paragrafo6b: 'Foto: Marcos Tortato'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Plantas forrageiras',
    paragrafo1: 'Muitas plantas foram introduzidas para uso como forrageiras, ou seja, para alimentar animais de criação, como bois, vacas, cabras, cavalos e burros. São principalmente gramíneas, dentre as quais as braquiárias são invasoras muito agressivas em áreas naturais e áreas degradadas, até mesmo em áreas úmidas e pequenos córregos. Mas também há arvoretas como a leucena e árvores como a algaroba, cujas vagens são utilizadas para alimentação de cabras. ',
    paragrafo2: 'Essas espécies foram disseminadas amplamente no mundo tropical e causam impactos importantes ao ambiente por modificar ciclos naturais de fogo, reduzir o volume de água e deslocar espécies nativas de plantas, formando agrupamentos densos e dominantes.',
    paragrafo3: 'Saiba mais nessa publicação',
    paragrafo4: 'Urochloa decumbens - braquiária',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Prosopis juliflora - algaroba',
    paragrafo5b: 'Foto: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - capim-gordura',
    paragrafo6b: 'Foto: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - capim-jaraguá',
    paragrafo7b: 'Foto: Instituto Hórus'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentais',
    paragrafo1: 'Plantas ornamentais perfazem cerca de 70% de todas as plantas exóticas invasoras presentes no Brasil. A escolha de plantas para jardins, sombra e arborização urbana pode contribuir para a conservação da natureza, que inclui a provisão de água, a estabilidade climática e muitos outros “serviços” prestados pela natureza ao ser humano.',
    paragrafo2_1: 'Cultive plantas nativas da sua região',
    paragrafo2_2: 'Conheça, evite e substitua plantas exóticas invasoras no seu jardim.',
    paragrafo3: 'Consulte plantas alternativas para a sua região no ',
    arquiflora: 'Arquiflora',
    paragrafo4: 'Tecoma stans - ipê-de-jardim',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Furcraea foetida - piteira',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - comigo-ninguém-pode',
    paragrafo6b: 'Foto: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - aspargo',
    paragrafo7b: 'Foto: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - castanheira, amendoeira',
    paragrafo8b: 'Foto: Instituto Hórus'
  },
  projetosInstitucional: {
    titulo: 'Projetos',
    paragrafo1: 'Esta seção contém informação sobre projetos atuais ou concluídos com foco em pesquisa ou manejo de espécies exóticas invasoras presentes na Jamaica. Se você está participando ou já participou de algum projeto nessa área, por favor nos ajude a incluir mais informação. Mande um email para',
    paragrafo1_1: '',
    paragrafo2_1: 'Se você participa ou já participou de algum projeto sobre espécies exóticas invasoras, contribua com a base de dados ',
    paragrafo2_2: ', enviando informação sobre o projeto ',
    paragrafo2_3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo3: 'Para citar dados do sistema, utilize o seguinte formato:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras da Jamaica. Institute of Jamaica. Consultado em DD/MM/AAA.',
    paragrafo4_2: ''
  },
  referenciasInstitucional: {
    titulo: 'Busca por referências',
    paragrafo1: 'As publicações e informes técnicos citados aqui foram utilizados como fontes de dados sobre espécies exóticas invasoras no território nacional, seu comportamento e impactos. Alguns incluem dados sobre técnicas e métodos de manejo das distintas espécies, assim como pontos de ocorrência no país. Se você é autor/a ou conhece publicações sobre o tema que não estão incluídas nesta seção, por favor ajude a incorporar novas referências, enviando as citações para',
    paragrafo2: 'Se você tem publicações sobre espécies exóticas invasoras que não estão contempladas aqui, contribua para a atualização da Base de Dados Nacional através do envio dessas referências.',
    paragrafo3: 'Contribua com dados no site',
    paragrafo4: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo5_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras da Jamaica. Institute of Jamaica. Consultado em DD/MM/AAA.',
    paragrafo5_2: ''
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso florestal',
    paragrafo1: 'Diversas espécies de árvores exóticas foram introduzidas no Brasil a partir da década de 1950 por iniciativas de governo para desenvolver a indústria florestal de produção de papel, celulose, madeira e outros subprodutos. Algumas dessas espécies se tornaram invasoras, sendo os pínus especialmente distribuídos em inúmeros ambientes. Essas espécies requerem um manejo que inclua o controle contínuo e sua contenção em talhões florestais para evitar impactos ambientais e à paisagem. A certificação florestal exige o controle de espécies exóticas invasoras e é preciso eliminar essas árvores de áreas naturais, margens de rodovias e ferrovias, florestas ciliares e outros ambientes onde não são cultivadas.',
    paragrafo2: 'O gênero pínus é tido como o gênero de plantas com maior amplitude de invasão no hemisfério sul! Os pínus deslocam espécies nativas em ecossistemas abertos e são grande consumidores de água, mais do que espécies nativas.',
    paragrafo3: 'Saiba mais neste artigo',
    paragrafo4: 'Pínus sp. - pinheiro-americano',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Acacia mearnsii - acácia-negra',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Acacia mangium - acácia',
    paragrafo6b: 'Foto: Instituto Hórus'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contato',
    contato: 'Contato',
    p1: 'Preencha todos os campos do formulário abaixo:',
    nome: 'Nome',
    email: 'E-mail',
    instituicao: 'Instituição',
    mensagem: 'Mensagem',
    enviar: 'Enviar'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Colabore',
    paragrafo1: 'Nossa base de dados é atualizada graças a contribuições de especialistas e observadores da natureza distribuídos em todo o território nacional. Antes de serem publicadas, as informações fornecidas são validadas segundo critérios acordados em nível regional.',
    paragrafo2: 'Você pode enviar dados sobre espécies ainda não incluídas neste sistema ou sobre novas localidades de ocorrência baixando nosso aplicativo (Invasoras JM) ou enviando um e-mail para',
    paragrafo2b: 'Por e-mail você também pode enviar publicações ou informação sobre projetos que abordam invasões biológicas na Jamaica. Os provedores de dados que contribuem regularmente podem receber um nome de usuário e senha para inserir informações diretamente na base de dados.',
    paragrafo3: 'Ajude-nos a enfrentar o desafio de espécies exóticas invasoras com base em informações completas, atualizadas e confiáveis!',
    paragrafo4: 'Obrigado!'
  }
}
