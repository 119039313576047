<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center mt-4 mb-4">
      <li class="page-item" :class="{ 'disabled': $store.state.currentPage === 1 }">
        <a class="page-link" @click="nextPage(-1)">{{ $t('label.anterior') }}</a>
      </li>
      <li class="page-item" v-if="$store.state.currentPage > countNumbers">
        <a class="page-link" @click="nextPage(-countNumbers)">...</a>
      </li>
      <li class="page-item" v-for="index in listPagination" :key="index" :class="{ 'active': index === $store.state.currentPage}">
        <a class="page-link" @click="setPage(index, true)">{{index}}</a>
      </li>
      <li class="page-item" v-if="countPages > countNumbers && $store.state.currentPage < countPages">
        <a class="page-link" @click="nextPage(countNumbers)">...</a>
      </li>
      <li class="page-item" :class="{ 'disabled': $store.state.currentPage === countPages }">
        <a class="page-link" @click="nextPage(1)">{{ $t('label.próximo') }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TablePagination',
  data: function () {
    return {
      countNumbers: 10,
      listPagination: [],
      countPages: 0
    }
  },
  watch: {
    '$store.state.countList' () {
      this.preparePagination()
    },
    '$store.state.perPage' () {
      this.preparePagination()
    }
  },
  methods: {
    preparePagination: function () {
      if (this.$mq === 'xs') {
        this.countNumbers = 2
      } else if (this.$mq === 'sm') {
        this.countNumbers = 5
      } else {
        this.countNumbers = 10
      }
      this.countPages = this.$store.state.countList / this.$store.state.perPage
      if (!Number.isInteger(this.countPages)) {
        this.countPages = Math.trunc(this.countPages + 1)
      }

      if (this.$store.state.currentPage > this.countNumbers) {
        this.listPagination = [...Array(this.countPages + 1)].map((_, i) => i).filter(i => i > this.$store.state.currentPage - this.countNumbers && i <= this.$store.state.currentPage)
        const last = this.listPagination[this.listPagination.length - 1]
        if (this.$store.state.currentPage > last) {
          this.setPage(last, true)
        }
      } else {
        this.listPagination = [...Array(this.countPages + 1)].map((_, i) => i).filter(i => i > 0 && i <= this.countNumbers)
        if (this.$store.state.currentPage > this.countPages) {
          this.setPage(this.countPages, false)
        }
      }
    },
    setPage: function (number, search) {
      if (number > 0) {
        this.$store.state.currentPage = number
        if (search) {
          this.$emit('searchTable')
        }
        this.preparePagination()
      }
    },
    nextPage: function (number) {
      this.setPage(this.$store.state.currentPage + number, true)
    }
  },
  created: function () {
    this.preparePagination()
  }
}
</script>
