<template>
  <div>
    <Loading :active.sync="this.$store.state.preload" :is-full-page="true" :width="45" :color="'#007bff'"/>
    <AdminContent/>
  </div>
</template>

<script>
import AdminContent from '@/components/admin/AdminContent'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import '../../util/bootstrap'
export default {
  name: 'Admin',
  components: { AdminContent, Loading }
}
</script>
<style scoped>
@import url("../../assets/admin/css/app.css");
@import url("../../assets/institucional/css/bd-estilo-adm.css");
</style>
