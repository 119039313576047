<template>
  <div class="bd-front-user">
    <InstitucionalHeader/>
    <div class="main-content">
      <router-view></router-view>
    </div><!-- fim main content-->
    <InstitucionalFooter/>
  </div>
</template>

<script>
import InstitucionalHeader from '@/components/institucional/InstitucionalHeader'
import InstitucionalFooter from '@/components/institucional/InstitucionalFooter'
export default {
  name: 'InstitucionalContent',
  components: { InstitucionalHeader, InstitucionalFooter }
}
</script>
