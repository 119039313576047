<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('baseDadosNacional.cabecalhoTitulo') }`" :classProp="'header-single header-rede-nacional'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">
          <h2 class="laranja mt-2 h3">{{ $t('baseDadosNacional.titulo') }}</h2>
          <p>{{ $t('baseDadosNacional.paragrafo1') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo2') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo3') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo4') }}</p>
          <ol>
            <li>{{ $t('baseDadosNacional.paragrafo5') }}</li>
            <li>{{ $t('baseDadosNacional.paragrafo6') }}</li>
            <li>{{ $t('baseDadosNacional.paragrafo6_1') }}</li>
          </ol>
          <p>{{ $t('baseDadosNacional.paragrafo7') }}</p>

          <h3 class="h5 mt-5 mb-4 laranja">{{ $t('baseDadosNacional.paragrafo8') }}</h3>
          <div class="row">
            <div class="col-md-12">
              <ul class="rede_apoio">
                <li><img src="../../../assets/institucional/img/logo/Natural-History-Logo.png"></li>
                <li><img src="../../../assets/institucional/img/logo/logo-IASWG.png"></li>
                <li><img src="../../../assets/institucional/img/logo/NSDMD-Logo-PNG.png" style="width: 100px;"></li>
                <li><img src="../../../assets/institucional/img/logo/MOEGJC_LOGO_TITLED.png" style="width: 185px;"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'BaseDadosNacional',
  components: { InstitucionalHeaderSingle }
}
</script>
