<template>
  <div class="tab-pane fade" :class="{ 'show active': aba===4 }" id="impacto" role="tabpanel" aria-labelledby="impacto-tab">
    <div class="container-fluid  mt-3">
      <div class="row">
          <div class="col-md-6">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisRelImpacts }">
                <label for="impactos" class="form-label">{{ $t('label.impactos') }}</label>
                <button v-if="isPendingAnalysisRelImpacts" class="btn" type="button" @click="$emit('analysis', $t('label.impactos'), 'relImpactsSelected', vocImpactsList, relImpactsSelected, oldRelImpactsSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <v-select multiple :appendToBody="true" :options="vocImpactsList" v-model="relImpactsSelected" label="value" index="impact_id"
                  :reduce="lang => lang.impact_id" :clearable="false" class="wf-select vue-select" style="min-width: 150px;" :disabled="byInstitucional">
                  <template v-slot:selected-option="option">
                    {{ `${option.value}` }}
                  </template>
                  <template v-slot:option="option">
                    {{ `${option.value}` }}
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                    </template>
                  </template>
                </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisBiodiversityImpact }">
                <label for="impactos-ecologicos" class="form-label">{{ $t('label.impactosEcologicos') }}</label>
                <button v-if="isPendingAnalysisBiodiversityImpact" class="btn" type="button" @click="$emit('analysis', $t('label.impactosEcologicos'), 'biodiversity_impact')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <textarea v-model="form.biodiversity_impact" id="impactos-ecologicos" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisEconomicImpact }">
                <label for="impactos-economicos" class="form-label">{{ $t('label.impactosEconomicos') }}</label>
                <button v-if="isPendingAnalysisEconomicImpact" class="btn" type="button" @click="$emit('analysis', $t('label.impactosEconomicos'), 'economic_impact')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <textarea v-model="form.economic_impact" id="impactos-economicos" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisSocialImpact }">
                <label for="impactos-sociais" class="form-label">{{ $t('label.impactosSociais') }}</label>
                <button v-if="isPendingAnalysisSocialImpact" class="btn" type="button" @click="$emit('analysis', $t('label.impactosSociais'), 'social_impact')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <textarea v-model="form.social_impact" id="impactos-sociais" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisHealthImpact }">
                <label for="impactos-a-saude" class="form-label">{{ $t('label.impactosSaude') }}</label>
                <button v-if="isPendingAnalysisHealthImpact" class="btn" type="button" @click="$emit('analysis', $t('label.impactosSaude'), 'health_impact')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <textarea v-model="form.health_impact" id="impactos-a-saude" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
            </div>
          </div>
          <div class="col-md-6 border-start">
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisEicat }">
              <label for="impactos-resultados-eicat" class="form-label">{{ $t('label.categoriaEICAT') }}</label>
              <button v-if="isPendingAnalysisEicat" class="btn" type="button" @click="$emit('analysis', $t('label.categoriaEICAT'), 'relEicatSelected', vocEicatList, relEicatSelected, oldRelEicatSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocEicatList" v-model="relEicatSelected" label="value" index="eicat_id"
                :reduce="lang => lang.eicat_id" :clearable="false" class="wf-select vue-select" style="min-width: 150px;" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>

            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisImpactMechanismEicat }">
              <label for="impact_mechanism_eicat" class="form-label">{{ $t('label.mecanismosImpactoEICAT') }}</label>
              <button v-if="isPendingAnalysisImpactMechanismEicat" class="btn" type="button" @click="$emit('analysis', $t('label.mecanismosImpactoEICAT'), 'relImpactMechanismEicatSelected', vocImpactMechanismEicatList, relImpactMechanismEicatSelected, oldRelImpactMechanismEicatSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocImpactMechanismEicatList" v-model="relImpactMechanismEicatSelected" label="value" index="impact_mechanism_eicat_id"
                :reduce="lang => lang.impact_mechanism_eicat_id" :clearable="false" class="wf-select vue-select" style="min-width: 150px;" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisCertaintyEicat }">
              <label for="certainty_eicat" class="form-label">{{ $t('label.nivelConfiancaAvaliacaoEICAT') }}</label>
              <button v-if="isPendingAnalysisCertaintyEicat" class="btn" type="button" @click="$emit('analysis', $t('label.nivelConfiancaAvaliacaoEICAT'), 'certainty_eicat', confidenceList)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select :appendToBody="true" :options="confidenceList" v-model="form.eicat_confidence_id" label="valor" index="confidence_id"
                :reduce="lang => lang.confidence_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.valor}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.valor}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>

            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisEicatBibliographyId }">
              <label for="impactos-referencias-eicat" class="form-label">{{ $t('label.referenciaEICAT') }}</label>
              <button v-if="isPendingAnalysisEicatBibliographyId" class="btn" type="button" @click="$emit('analysis', $t('label.referenciaEICAT'), 'eicat_bibliography_id', dataBibliographyList)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select :appendToBody="true" :options="dataBibliographyList" v-model="form.eicat_bibliography_id" label="title" index="bibliography_id"
                :reduce="lang => lang.bibliography_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.title}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.title}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisEicatDate }">
                <label for="impactos-data-eicat" class="form-label">{{ $t('label.dataEICAT') }}</label>
                <button v-if="isPendingAnalysisEicatDate" class="btn" type="button" @click="$emit('analysis', $t('label.dataEICAT'), 'eicat_date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <input v-model="form.eicat_date" v-mask="'####'" class="form-control" type="text" maxLength="4" :placeholder="$t('label.digitarSomenteAno')"  :disabled="byInstitucional">
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisSeicat }">
                <label for="impactos-resultados-seicat" class="form-label">{{ $t('label.categoriaSEICAT') }}</label>
              <button v-if="isPendingAnalysisSeicat" class="btn" type="button" @click="$emit('analysis', $t('label.categoriaSEICAT'), 'relSeicatSelected', vocSeicatList, relSeicatSelected, oldRelSeicatSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocSeicatList" v-model="relSeicatSelected" label="value" index="seicat_id"
                :reduce="lang => lang.seicat_id" :clearable="false" class="wf-select vue-select" style="min-width: 150px;" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>

            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisImpactMechanismSeicat }">
              <label for="impact_mechanism_seicat" class="form-label">{{ $t('label.mecanismosImpactoSEICAT') }}</label>
              <button v-if="isPendingAnalysisImpactMechanismSeicat" class="btn" type="button" @click="$emit('analysis', $t('label.mecanismosImpactoSEICAT'), 'relImpactMechanismSeicatSelected', vocImpactMechanismSeicatList, relImpactMechanismSeicatSelected, oldRelImpactMechanismSeicatSelected)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select multiple :appendToBody="true" :options="vocImpactMechanismSeicatList" v-model="relImpactMechanismSeicatSelected" label="value" index="impact_mechanism_seicat_id"
                :reduce="lang => lang.impact_mechanism_seicat_id" :clearable="false" class="wf-select vue-select" style="min-width: 150px;" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.value}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisCertaintySeicat }">
              <label for="certainty_seicat" class="form-label">{{ $t('label.nivelConfiancaAvaliacaoSEICAT') }}</label>
              <button v-if="isPendingAnalysisCertaintySeicat" class="btn" type="button" @click="$emit('analysis', $t('label.nivelConfiancaAvaliacaoSEICAT'), 'certainty_seicat', confidenceList)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <v-select :appendToBody="true" :options="confidenceList" v-model="form.seicat_confidence_id" label="valor" index="confidence_id"
                :reduce="lang => lang.confidence_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                <template v-slot:selected-option="option">
                  {{ `${option.valor}` }}
                </template>
                <template v-slot:option="option">
                  {{ `${option.valor}` }}
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </div>

            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisSeicatBibliographyId }">
                <label for="impactos-referencia-seicat" class="form-label">{{ $t('label.referenciaSEICAT') }}</label>
                <button v-if="isPendingAnalysisSeicatBibliographyId" class="btn" type="button" @click="$emit('analysis', $t('label.referenciaSEICAT'), 'seicat_bibliography_id', dataBibliographyList)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <v-select :appendToBody="true" :options="dataBibliographyList" v-model="form.seicat_bibliography_id" label="title" index="bibliography_id"
                  :reduce="lang => lang.bibliography_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                  <template v-slot:selected-option="option">
                    {{ `${option.title}` }}
                  </template>
                  <template v-slot:option="option">
                    {{ `${option.title}` }}
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                    </template>
                  </template>
                </v-select>
            </div>
            <div class="mb-3" :class="{ 'pending_analysis': isPendingAnalysisSeicatDate }">
                <label for="impactos-data-seicat" class="form-label">{{ $t('label.dataSEICAT') }}</label>
                <button v-if="isPendingAnalysisSeicatDate" class="btn" type="button" @click="$emit('analysis', $t('label.dataSEICAT'), 'seicat_date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <input v-model="form.seicat_date" v-mask="'####'" class="form-control" type="text" maxLength="4" :placeholder="$t('label.digitarSomenteAno')"  :disabled="byInstitucional">
            </div>
          </div>
      </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EspeciesTabImpacto',
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorImpacto: Boolean,
    onlyLoadValidator: Boolean,
    dataBibliographyList: Array,
    pendingAnalysis: Boolean
  },
  data () {
    return {
      vocImpactsList: [],
      vocEicatList: [],
      vocSeicatList: [],
      vocImpactMechanismEicatList: [],
      vocImpactMechanismSeicatList: [],
      relImpactsSelected: [],
      oldRelImpactsSelected: [],
      relEicatSelected: [],
      oldRelEicatSelected: [],
      relSeicatSelected: [],
      oldRelSeicatSelected: [],
      relImpactMechanismEicatSelected: [],
      oldRelImpactMechanismEicatSelected: [],
      relImpactMechanismSeicatSelected: [],
      oldRelImpactMechanismSeicatSelected: [],
      confidenceList: []
    }
  },
  validations: {
    form: {}
  },
  watch: {
    'loadValidatorImpacto' (newValue) {
      if (newValue) {
        this.$v.$touch()
        const obj = {
          relImpactsSelected: this.relImpactsSelected,
          relEicatSelected: this.relEicatSelected,
          relSeicatSelected: this.relSeicatSelected,
          relImpactMechanismEicatSelected: this.relImpactMechanismEicatSelected,
          relImpactMechanismSeicatSelected: this.relImpactMechanismSeicatSelected
        }
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v, obj)
      }
    },
    'form.relImpacts' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelImpactsSelected()
        this.getOldRelImpactsSelected()
      }
    },
    'form.relEicat' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelEicatSelected()
        this.getOldRelEicatSelected()
      }
    },
    'form.relSeicat' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelSeicatSelected()
        this.getOldRelSeicatSelected()
      }
    },
    'form.relImpactMechanismEicat' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelImpactMechanismEicatSelected()
        this.getOldRelImpactMechanismEicatSelected()
      }
    },
    'form.relImpactMechanismSeicat' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getRelImpactMechanismSeicatSelected()
        this.getOldRelImpactMechanismSeicatSelected()
      }
    },
    'aba' () {
      if (this.aba === 4) {
        if (this.vocImpactsList.length === 0 ||
          this.confidenceList.length === 0 ||
          this.vocEicatList.length === 0 ||
          this.vocSeicatList.length === 0 ||
          this.vocImpactMechanismEicatList.length === 0 ||
          this.vocImpactMechanismSeicatList.length === 0) {
          this.$store.dispatch('showPreload')
          this.getVocImpacts()
          this.getVocConfidence()
          this.getVocEicat()
          this.getVocSeicat()
          this.getVocImpactMechanismEicat()
          this.getVocImpactMechanismSeicat()
        }
      }
    }
  },
  computed: {
    isPendingAnalysis: function () {
      return !this.byInstitucional && this.pendingAnalysis && this.form.log_update
    },
    isPendingAnalysisRelImpacts: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelImpacts
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisBiodiversityImpact: function () {
      const result = this.isPendingAnalysis && this.form.biodiversity_impact !== this.form.log_update.Biodiversity_impact
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisEconomicImpact: function () {
      const result = this.isPendingAnalysis && this.form.economic_impact !== this.form.log_update.Economic_impact
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisSocialImpact: function () {
      const result = this.isPendingAnalysis && this.form.social_impact !== this.form.log_update.Social_impact
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisHealthImpact: function () {
      const result = this.isPendingAnalysis && this.form.health_impact !== this.form.log_update.Health_impact
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisEicat: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelEicat
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisImpactMechanismEicat: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelImpactMechanismEicat
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisCertaintyEicat: function () {
      const result = this.isPendingAnalysis && this.form.eicat_confidence_id !== this.form.log_update.Eicat_confidence_id
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisEicatBibliographyId: function () {
      const result = this.isPendingAnalysis && this.form.eicat_bibliography_id !== this.form.log_update.Eicat_bibliography_id
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisEicatDate: function () {
      const result = this.isPendingAnalysis && this.form.eicat_date !== this.form.log_update.Eicat_date
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisSeicat: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelSeicat
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisImpactMechanismSeicat: function () {
      const result = !this.byInstitucional && this.pendingAnalysis && this.pendingRelImpactMechanismSeicat
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisCertaintySeicat: function () {
      const result = this.isPendingAnalysis && this.form.seicat_confidence_id !== this.form.log_update.Seicat_confidence_id
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisSeicatBibliographyId: function () {
      const result = this.isPendingAnalysis && this.form.seicat_bibliography_id !== this.form.log_update.Seicat_bibliography_id
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    isPendingAnalysisSeicatDate: function () {
      const result = this.isPendingAnalysis && this.form.seicat_date !== this.form.log_update.Seicat_date
      this.$emit('especiesTabImpactoPending', result)
      return result
    },
    pendingRelImpacts: function () {
      let result = false
      if (this.relImpactsSelected.length !== this.oldRelImpactsSelected.length) {
        return true
      }
      this.oldRelImpactsSelected.forEach(e => {
        if (!this.relImpactsSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelEicat: function () {
      let result = false
      if (this.relEicatSelected.length !== this.oldRelEicatSelected.length) {
        return true
      }
      this.oldRelEicatSelected.forEach(e => {
        if (!this.relEicatSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelSeicat: function () {
      let result = false
      if (this.relSeicatSelected.length !== this.oldRelSeicatSelected.length) {
        return true
      }
      this.oldRelSeicatSelected.forEach(e => {
        if (!this.relSeicatSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelImpactMechanismEicat: function () {
      let result = false
      if (this.relImpactMechanismEicatSelected.length !== this.oldRelImpactMechanismEicatSelected.length) {
        return true
      }
      this.oldRelImpactMechanismEicatSelected.forEach(e => {
        if (!this.relImpactMechanismEicatSelected.includes(e)) {
          result = true
        }
      })
      return result
    },
    pendingRelImpactMechanismSeicat: function () {
      let result = false
      if (this.relImpactMechanismSeicatSelected.length !== this.oldRelImpactMechanismSeicatSelected.length) {
        return true
      }
      this.oldRelImpactMechanismSeicatSelected.forEach(e => {
        if (!this.relImpactMechanismSeicatSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  methods: {
    getVocImpacts: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocimpacts/getall', { params })
        .then(response => {
          this.vocImpactsList = response.data.items.map(i => {
            const item = {
              impact_id: i.impact_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getRelImpactsSelected: function () {
      this.form.relImpacts.forEach(e => {
        if (!this.relImpactsSelected.includes(Number(e.impact_id))) {
          this.relImpactsSelected.push(Number(e.impact_id))
        }
      })
    },
    getOldRelImpactsSelected: function () {
      if (this.form.log_update && this.form.log_update.RelImpacts) {
        this.form.log_update.RelImpacts.forEach(e => {
          if (!this.oldRelImpactsSelected.includes(Number(e.Impact_id))) {
            this.oldRelImpactsSelected.push(Number(e.Impact_id))
          }
        })
      }
    },
    getRelEicatSelected: function () {
      this.form.relEicat.forEach(e => {
        if (!this.relEicatSelected.includes(Number(e.eicat_id))) {
          this.relEicatSelected.push(Number(e.eicat_id))
        }
      })
    },
    getOldRelEicatSelected: function () {
      if (this.form.log_update && this.form.log_update.RelEicat) {
        this.form.log_update.RelEicat.forEach(e => {
          if (!this.oldRelEicatSelected.includes(Number(e.Eicat_id))) {
            this.oldRelEicatSelected.push(Number(e.Eicat_id))
          }
        })
      }
    },
    getRelSeicatSelected: function () {
      this.form.relSeicat.forEach(e => {
        if (!this.relSeicatSelected.includes(Number(e.seicat_id))) {
          this.relSeicatSelected.push(Number(e.seicat_id))
        }
      })
    },
    getOldRelSeicatSelected: function () {
      if (this.form.log_update && this.form.log_update.RelSeicat) {
        this.form.log_update.RelSeicat.forEach(e => {
          if (!this.oldRelSeicatSelected.includes(Number(e.Seicat_id))) {
            this.oldRelSeicatSelected.push(Number(e.Seicat_id))
          }
        })
      }
    },
    getRelImpactMechanismEicatSelected: function () {
      this.form.relImpactMechanismEicat.forEach(e => {
        if (!this.relImpactMechanismEicatSelected.includes(Number(e.impact_mechanism_eicat_id))) {
          this.relImpactMechanismEicatSelected.push(Number(e.impact_mechanism_eicat_id))
        }
      })
    },
    getOldRelImpactMechanismEicatSelected: function () {
      if (this.form.log_update && this.form.log_update.RelImpactMechanismEicat) {
        this.form.log_update.RelImpactMechanismEicat.forEach(e => {
          if (!this.oldRelImpactMechanismEicatSelected.includes(Number(e.Impact_mechanism_eicat_id))) {
            this.oldRelImpactMechanismEicatSelected.push(Number(e.Impact_mechanism_eicat_id))
          }
        })
      }
    },
    getRelImpactMechanismSeicatSelected: function () {
      this.form.relImpactMechanismSeicat.forEach(e => {
        if (!this.relImpactMechanismSeicatSelected.includes(Number(e.impact_mechanism_seicat_id))) {
          this.relImpactMechanismSeicatSelected.push(Number(e.impact_mechanism_seicat_id))
        }
      })
    },
    getOldRelImpactMechanismSeicatSelected: function () {
      if (this.form.log_update && this.form.log_update.RelImpactMechanismSeicat) {
        this.form.log_update.RelImpactMechanismSeicat.forEach(e => {
          if (!this.oldRelImpactMechanismSeicatSelected.includes(Number(e.Impact_mechanism_seicat_id))) {
            this.oldRelImpactMechanismSeicatSelected.push(Number(e.Impact_mechanism_seicat_id))
          }
        })
      }
    },
    getVocEicat: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/voceicat/getall', { params })
        .then(response => {
          this.vocEicatList = response.data.items.map(i => {
            const item = {
              eicat_id: i.eicat_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocSeicat: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocseicat/getall', { params })
        .then(response => {
          this.vocSeicatList = response.data.items.map(i => {
            const item = {
              seicat_id: i.seicat_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocImpactMechanismEicat: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocimpactmechanismeicat/getall', { params })
        .then(response => {
          this.vocImpactMechanismEicatList = response.data.items.map(i => {
            const item = {
              impact_mechanism_eicat_id: i.impact_mechanism_eicat_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocImpactMechanismSeicat: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocimpactmechanismseicat/getall', { params })
        .then(response => {
          this.vocImpactMechanismSeicatList = response.data.items.map(i => {
            const item = {
              impact_mechanism_seicat_id: i.impact_mechanism_seicat_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocConfidence: function () {
      axios.get('/vocconfidence/getall')
        .then(response => {
          this.confidenceList = response.data.items.map(i => {
            const item = {
              confidence_id: i.confidence_id,
              valor: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.valor = i.en
                break
              case 'es':
                item.valor = i.es
                break
              case 'pt':
                item.valor = i.pt
                break
            }
            return item
          })
        })
    }
  }
}
</script>
