<template>
  <span class="edit-label edit-field">
      <input type="text" class="edit-label-input" v-model="edGenus.genus" ref="genus">
      <v-select :appendToBody="true" :options="list" v-model="edGenus.family_id" label="family" index="family_id"
        :reduce="r => r.family_id" :clearable="false" style="min-width: 150px;">
        <template v-slot:selected-option="option">
          {{ `${option.family}` }}
        </template>
        <template v-slot:option="option">
          {{ `${option.family}` }}
        </template>
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
          </template>
        </template>
      </v-select>
      <ul class="acoes d-flex justify-content-end">
        <li><a @click="salve"><span class="salvar mt-1"></span></a></li>
        <li><a @click="cancel"><span class="cancelar mt-1"></span></a></li>
      </ul>
  </span>
</template>

<script>
export default {
  name: 'EditTaxonomyGenus',
  props: {
    genus: {},
    list: Array
  },
  data: function () {
    return {
      edGenus: {
        genus_id: 0,
        genus: ''
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancelEditGenus')
    },
    salve: function () {
      this.$emit('salveEditGenus', this.edGenus)
    }
  },
  mounted () {
    this.edGenus = this.genus
    this.$refs.genus.focus()
  }
}
</script>
