<template>
  <div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
      <div class="col-auto d-none d-sm-block">
        <h3 class="laranja h4">{{pageTitle}} <em>{{nameEdit}}</em></h3>
      </div>
      <div class="col-auto ml-auto text-right mt-n1">
        <a @click="save(true)" class="bt-add-registro" v-if="user.perfilUser === 'administrator'">{{ $t('label.aprovarOcorrencia')}}</a>
        <a @click="save()" class="bt-add-registro ml-1">{{ $t('label.salvar')}}</a>
        <a @click="excluir" class="bt-add-registro bg-danger ml-1">{{ $t('label.excluir') }}</a>
        <a @click="cancel" class="btn btn-secondary ml-1">{{ $t('label.cancelarVoltar') }}</a>
      </div>
    </div>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill ">
          <div class="card-body box-dashboard p-3">
              <div class="align-self-center">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mt-3">
                      <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.especie') }}</label>
                      <v-select :appendToBody="true" :filterable="false" @search="getSpecies" :options="speciesList" v-model="item.species_id" label="scientific_name" index="species_id"
                        :reduce="r => r.species_id" :clearable="false" class="wf-select vue-select" :class="{ 'is-invalid': $v.item.species_id.$dirty && $v.item.species_id.$error }" :disabled="!item.pending_analysis">
                        <template v-slot:selected-option="option">
                          {{ `${option.scientific_name}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.scientific_name}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                      <div class="invalid-feedback">
                        {{ $t('message.requiredField') }}
                      </div>
                    </div>
                    <div class="mt-3">
                      <label class="form-label">{{ $t('label.nomeCientifico') }}</label>
                      <input type="text" class="form-control" id="scientific-name" v-model="item.scientific_name" :disabled="!item.pending_analysis">
                    </div>
                    <div class="mt-3">
                      <label for="add-ocorrencias-estado" class="form-label">{{ $t('label.estado') }}</label>
                      <v-select :appendToBody="true" :options="statesList" v-model="item.state_id" label="state" index="state_id" :disabled="!item.pending_analysis"
                        :reduce="r => r.state_id" :clearable="false" class="wf-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.state}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.state}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mt-3">
                      <label for="add-ocorrencias-municipio" class="form-label">{{ $t('label.municipio') }}</label>
                      <v-select :appendToBody="true" :options="vocMunicipiosList" v-model="item.municipio_id" label="municipio" index="municipio_id"
                        :reduce="r => r.municipio_id" :clearable="false" class="wf-select vue-select" :disabled="!item.pending_analysis">
                        <template v-slot:selected-option="option">
                          {{ `${option.municipio}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.municipio}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mt-3">
                      <label for="add-ocorrencias-ref-local" class="form-label">{{ $t('label.referenciaLocal') }}</label>
                      <textarea id="add-ocorrencias-ref-local" rows="4" class="box-dashboard p-2 textarea_adm form-control" v-model="item.location" :disabled="!item.pending_analysis"></textarea>
                    </div>
                    <div class="mt-3">
                      <label for="add-ocorrencias-grau-latitude" class="form-label">{{ $t('label.latitude') }} (ex. 27 35 06.43 S)</label>
                      <input type="text" class="form-control" id="add-ocorrencias-grau-latitude" v-model="item.latitude" :disabled="!item.pending_analysis">
                    </div>
                    <div class="mt-3">
                      <label for="add-ocorrencias-grau-longitude" class="form-label">{{ $t('label.longitude') }} (ex. 48 32 58 W)</label>
                      <input type="text" class="form-control" id="add-ocorrencias-grau-longitude" v-model="item.longitude" :disabled="!item.pending_analysis">
                    </div>
                    <div class="mt-3">
                      <label for="ecologia-ambientes-preferenciais-de-invasao" class="form-label">{{ $t('label.ambientesPreferenciaisInvasao') }}</label>
                      <v-select :appendToBody="true" :options="vocprefenvironmentsList" v-model="item.pref_inv_env_id" label="value" index="pref_inv_env_id"
                        :reduce="r => r.pref_inv_env_id" :clearable="false" class="wf-select vue-select" :disabled="!item.pending_analysis">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mt-3">
                      <label for="add-ocorrencias-descricao-invasao" class="form-label">{{ $t('label.descricaoInvasao') }}</label>
                      <textarea id="add-ocorrencias-descricao-invasao" rows="4" class="box-dashboard p-2 textarea_adm form-control" v-model="item.invasion_description" :disabled="!item.pending_analysis"></textarea>
                    </div>
                    <div class="mt-3">
                      <label for="add-ocorrencias-abundancia" class="form-label">{{ $t('label.abundancia') }}</label>
                      <v-select :appendToBody="true" :options="vocLocalAbundanceList" v-model="item.local_abundance_id" label="value" index="local_abundance_id" :disabled="!item.pending_analysis"
                        :reduce="r => r.local_abundance_id" :clearable="false" class="wf-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                </div>
                <div class="col-md-6 border-start">
                  <div class="mt-3">
                    <label for="add-ocorrencias-situacao-populacional" class="form-label">{{ $t('label.situacaoPopulacional') }}</label>
                    <v-select :appendToBody="true" :options="vocPopulationSituationList" v-model="item.population_situation_id" label="value" index="population_situation_id"
                      :reduce="r => r.population_situation_id" :clearable="false" class="wf-select vue-select" :disabled="!item.pending_analysis">
                      <template v-slot:selected-option="option">
                        {{ `${option.value}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.value}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <div class="mt-3">
                    <label for="add-ocorrencias-manejo" class="form-label">{{ $t('label.manejo') }}</label>
                    <v-select :appendToBody="true" :options="vocManagementList" v-model="item.management_id" label="value" index="management_id"
                      :reduce="r => r.management_id" :clearable="false" class="wf-select vue-select" :disabled="!item.pending_analysis">
                      <template v-slot:selected-option="option">
                        {{ `${option.value}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.value}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                    </v-select>
                  </div>
                  <div class="mt-3">
                    <div class="mb-3">
                        <label for="data" class="form-label">{{ $t('label.dataObservacao') }}</label>
                        <input type="text" class="form-control" v-model="item.obs_date" v-mask="['#####', '##/####', '##/##/####']" :disabled="!item.pending_analysis"
                        >
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="mb-3">
                      <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.colaborador') }}</label>
                        <v-select :appendToBody="true" :filterable="false" @search="getContacts" :options="contactsList" v-model="item.contact_id" label="surname" index="contact_id"
                          :reduce="r => r.contact_id" :clearable="false" class="wf-select vue-select" :class="{ 'is-invalid': colobadorJaCadastrado && !item.contact_id }">
                          <template v-slot:selected-option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                        <div class="invalid-feedback">
                          {{ $t('message.requiredField') }}
                        </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input class="form-check-input" type="checkbox" v-model="colobadorJaCadastrado">
                      <label class="form-check-label ml-1" for="inlineCheckbox1"> {{ $t('label.colobadorJaCadastrado') }}</label>
                    </div>
                    <div class="myClass pr-3 pl-3 pb-1 rounded-3 border-1" id="box-museu-id">
                      <div class="mt-3">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('label.nome') }}</label>
                          <input class="form-control" id="colaboradores-nome" type="text" v-model="item.sysUsers.description" :disabled="colobadorJaCadastrado">
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('label.email') }}</label>
                          <input class="form-control" type="text" id="colaboradores-email" v-model="item.sysUsers.email" :disabled="colobadorJaCadastrado">
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('label.titulo') }}</label>
                          <input class="form-control" type="text" id="colaboradores-titulo" v-model="item.sysUsers.profession" :disabled="colobadorJaCadastrado">
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('label.especialidadeApp') }}</label>
                          <input class="form-control" type="text" id="colaboradores-especialidade" v-model="item.sysUsers.expertise" :disabled="colobadorJaCadastrado">
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('label.especialidade') }}</label>
                          <v-select :appendToBody="true" :options="vocSpecialtiesList" v-model="item.sysUsers.specialty_id" label="value" index="specialty_id"
                            :reduce="r => r.specialty_id" :clearable="false" class="wf-select vue-select" :class="{ 'is-invalid': !colobadorJaCadastrado && !item.sysUsers.specialty_id }" :disabled="colobadorJaCadastrado">
                            <template v-slot:selected-option="option">
                              {{ `${option.value}` }}
                            </template>
                            <template v-slot:option="option">
                              {{ `${option.value}` }}
                            </template>
                            <template v-slot:no-options="{ search, searching }">
                              <template v-if="searching">
                                {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                              </template>
                            </template>
                          </v-select>
                          <div class="invalid-feedback">
                            {{ $t('message.requiredField') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="mb-3">
                      <label for="data" class="form-label">{{ $t('label.dataCadastro') }}</label>
                      <input type="text" class="form-control" :value="item.date_update | formatDate" disabled>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="mb-3">
                      <label for="data" class="form-label">{{ $t('label.nivelCerteza') }}</label>
                      <input v-if="item.vocCertaintyLevel" type="text" class="form-control" :value="$i18n.locale === 'pt' ? item.vocCertaintyLevel.pt : $i18n.locale === 'en' ? item.vocCertaintyLevel.en : item.vocCertaintyLevel.es" disabled>
                      <input v-else type="text" class="form-control" disabled>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <VViewer v-if="images.length > 0" :options="options" :images="images"
              @inited="inited"
              class="viewer" ref="viewer"
            >
              <template #default="scope">
                <img v-for="src in scope.images" :src="src" :key="src" width="150" height="200" style="padding: 2px;cursor: pointer;">
              </template>
            </VViewer>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import 'viewerjs/dist/viewer.css'
import { component as VViewer } from 'v-viewer'
import { mapState } from 'vuex'
export default {
  name: 'OcorrenciasAppForm',
  components: {
    VViewer
  },
  computed: {
    ...mapState(['user'])
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.ocorrenciasApp')} > ${this.$t('label.editando')}`,
      nameEdit: '',
      action: this.$actionNew,
      item: { sysUsers: {} },
      items: [],
      vocDistributionList: [],
      statesList: [],
      vocMunicipiosList: [],
      vocLocalAbundanceList: [],
      vocPopulationSituationList: [],
      vocManagementList: [],
      speciesList: [],
      images: [],
      vocprefenvironmentsList: [],
      options: { title: false },
      contactsList: [],
      colobadorJaCadastrado: false,
      vocSpecialtiesList: []
    }
  },
  validations: {
    item: {
      species_id: { required }
    }
  },
  watch: {
    'item.state_id' (newValue, oldValue) {
      if (oldValue !== undefined) {
        this.item.municipio_id = null
      }
      this.getVocMunicipios(newValue)
    },
    'item.contact_id' (newValue) {
      if (newValue) {
        this.colobadorJaCadastrado = true
      }
    },
    'colobadorJaCadastrado' (newValue) {
      if (!newValue) {
        this.item.contact_id = null
      }
    }
  },
  methods: {
    getSpeciesById: async function (id) {
      let specie = {}
      await axios.get(`/Specie/${id}`)
        .then(response => {
          specie = response.data
        })
      return specie
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/DataPlacesForApproval/${id}`)
        .then(response => {
          this.item = response.data
          if (!this.item.sysUsers) {
            this.item.sysUsers = {}
          }
          if (this.item.invasion_description && this.item.invasion_description.length > 100) {
            this.nameEdit = `${this.item.invasion_description.substring(0, 100)}...`
          }
          if (this.item.pending_analysis && this.item.dataPlacesForApprovalImgs) {
            this.item.dataPlacesForApprovalImgs.forEach(img => {
              this.getImage(img.image_name)
            })
          }
        })
    },
    save: function (approval) {
      this.$v.$touch()
      if ((this.$v.item.$invalid) || (this.colobadorJaCadastrado && !this.item.contact_id) || (!this.colobadorJaCadastrado && !this.item.sysUsers.specialty_id)) {
        return false
      }
      if (approval && confirm(this.$t('message.confirmApproval'))) {
        axios.put(`/DataPlacesForApproval/approval/${!this.colobadorJaCadastrado}`, this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      } else {
        axios.put('/DataPlacesForApproval', this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/ocorrencias-app')
      }
    },
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/DataPlacesForApproval/${this.item.place_for_approval_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    getVocPrefEnvironments: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocprefenvironments/getall', { params })
        .then(response => {
          this.vocprefenvironmentsList = response.data.items.map(i => {
            const item = {
              pref_inv_env_id: i.pref_inv_env_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocDistribution: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocdistribution/getall', { params })
        .then(response => {
          this.vocDistributionList = response.data.items.map(i => {
            const item = {
              distribution_id: i.distribution_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getStates: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
        })
    },
    getVocMunicipios: function (stateId) {
      const params = {
        Page: 1,
        PageSize: 9999,
        State_id: stateId
      }
      this.$store.dispatch('showPreload')
      axios.get('/VocMunicipios/getall', { params })
        .then(response => {
          this.vocMunicipiosList = response.data.items
        })
    },
    getVocLocalAbundance: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocLocalAbundance/getall', { params })
        .then(response => {
          this.vocLocalAbundanceList = response.data.items.map(i => {
            const item = {
              local_abundance_id: i.local_abundance_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocPopulationSituation: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocPopulationSituation/getall', { params })
        .then(response => {
          this.vocPopulationSituationList = response.data.items.map(i => {
            const item = {
              population_situation_id: i.situation_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocManagement: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocManagement/getall', { params })
        .then(response => {
          this.vocManagementList = response.data.items.map(i => {
            const item = {
              management_id: i.management_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getSpecies: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.scientific_name = search
        params.species_id_array = this.item.species_id
        loading(true)
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.species_id_array = this.item.species_id
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
          })
      }
    },
    getImage: function (imageName) {
      if (imageName) {
        axios.get(`/Upload/placesforapprovalimg/${imageName}`)
          .then(resp => {
            if (resp.data) {
              this.images.push(`data:image/*;base64, ${resp.data}`)
            }
          })
      }
    },
    inited (viewer) {
      this.$viewer = viewer
    },
    show () {
      this.$viewer.show()
    },
    getContacts: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.surname = search
        params.contact_id_array = this.item.contact_id
        loading(true)
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.contact_id_array = this.item.contact_id
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
          })
      }
    },
    getVocSpecialties: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocSpecialties/getall', { params })
        .then(response => {
          this.vocSpecialtiesList = response.data.items.map(i => {
            const item = {
              specialty_id: i.specialty_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    }
  },
  created: async function () {
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      await this.getById(this.$route.params.id)
    } else {
      this.cancel()
    }
    this.getSpecies()
    this.getStates()
    this.getVocLocalAbundance()
    this.getVocPopulationSituation()
    this.getVocManagement()
    this.getVocPrefEnvironments()
    this.getContacts()
    this.getVocSpecialties()
  }
}
</script>
