export const en = {
  message: {
    requiredField: 'Required field',
    noRecords: 'No record found!',
    confirmDel: 'Are you sure you wish to delete?',
    confirmApproval: 'Are you sure you wish to approve this occurrence?',
    noResultsFor: 'No results for'
  },
  login: {
    username: 'User',
    password: 'Password',
    login: 'Login',
    entrar: 'Log in',
    descricao: 'Enter your access data in the form below:',
    logout: 'Logout',
    myaccount: 'Account'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Back',
    outro: 'Other',
    filtros: 'Filters',
    acesseBaseDados: 'Access the database',
    conhecaNossaRede: 'Latin American network',
    baseDadosNacional: 'Jamaica Database',
    politicaDeDados: 'Data policy',
    comiteCientifico: 'Working group',
    colabore: 'Collaborate',
    especiesMarinhas: 'Marine species',
    peixesAquario: 'Aquarium fishes',
    peixesAquicultura: 'Aquaculture fishes',
    pescaDesportiva: 'Sport fishing',
    pets: 'Pets',
    plantasForrageiras: 'Forage plants',
    plantasOrnamentais: 'Ornamental plants',
    usoFlorestal: 'Forestry',
    tutorial: 'Tutorial',
    especies: 'Species',
    colaboradores: 'Collaborators',
    referencias: 'References',
    projetos: 'Projects',
    saibaMais: 'More info',
    contida: 'Contained',
    casual: 'Casual',
    estabelecida: 'Established',
    invasora: 'Invasive',
    exibirListagem: 'View List',
    concluir: 'Complete',
    fechar: 'Close',
    próximo: 'Next',
    anterior: 'Former',
    clickAqui: 'Click here',
    numRegistrosEncontrados: 'Number of records found:',
    exibir10Reg: 'Display 10 records',
    exibir25Reg: 'Display 25 records',
    exibir50Reg: 'Display 50 records',
    exibir100Reg: 'Display 100 records',
    limpar: 'Clear',
    buscar: 'Search',
    addColaborador: 'Add collaborator',
    nome: 'Name',
    sobrenome: 'Last name',
    aprovarColaborador: 'Approve collaborator',
    salvarColaborador: 'Save collaborator',
    titulo: 'Title',
    cargo: 'Position',
    especialidade: 'Expertise',
    instituicao: 'Institution',
    endereco: 'Address',
    estado: 'State',
    cidade: 'City',
    pais: 'Country',
    email: 'E-mail address',
    telefone: 'Telephone 1',
    telefone2: 'Telephone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Social media 1',
    midiaSocial2: 'Social media 2',
    midiaSocial3: 'Social media 3',
    observacoesAdicionais: 'Additional observations',
    adicionar: 'Add',
    editando: 'Editing',
    acoes: 'Actions',
    usuarixs: 'Users',
    adicionarUsuario: 'Add user',
    descricao: 'Description',
    ativo: 'Active',
    sim: 'Yes',
    nao: 'No',
    salvarUsuarix: 'Save user',
    senha: 'Password',
    observacoes: 'Observations',
    grupos: 'Groups',
    basico: 'Basic',
    administrator: 'Administrator',
    taxonomia: 'Taxonomy',
    nomeCientifico: 'Latin name',
    adicionarEspecie: 'Add species',
    genero: 'Genus',
    familia: 'Family',
    ordem: 'Order',
    classe: 'Class',
    phylumDivisao: 'Phylum / Division',
    reino: 'Kingdom',
    phylum: 'Phylum',
    origem: 'Origin',
    descricaoInvalida: 'Invalid description',
    selecioneFamilia: 'Please select a Family.',
    selecioneOrdem: 'Please select an Order.',
    selecioneClasse: 'Please select a Class.',
    selecioneDivisao: 'Please select a Phylum.',
    selectioneReino: 'Please select a Kingdom.',
    selectioneGenero: 'Please select a Genus.',
    addReferencia: 'Add reference',
    autoresEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Approve reference',
    salvarReferencia: 'Save reference',
    autorxsEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Authors',
    local: 'Location (Ex. Salvador, BA  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Location',
    edicao: 'Edition (ex. 2 ed.)',
    editora: 'Editor',
    revista: 'Journal',
    anoPublicacao: 'Publication year',
    meioDivulgacao: 'Publication means',
    volume: 'Volume (ex. v. 3)',
    numero: 'Number (ex. n. 28)',
    pagina: 'Pages (ex. p. 25-34 / 245p.)',
    url: 'DOI / URL',
    palavraChave: 'Keywords',
    anoPublic: 'Publication year',
    addProjeto: 'Add project',
    aprovarProjeto: 'Approve project',
    salvarProjeto: 'Save project',
    contato: 'Contact',
    areaGeografica: 'Geographic range',
    objetivos: 'Objectives',
    breveDescricao: 'Brief description',
    dataInicio: 'Start date',
    dataTermino: 'End date',
    comentarios: 'Comments',
    anoInicio: 'Start year',
    anoTermino: 'End year',
    ocorrencias: 'Occurrences',
    exportarDados: 'Export data',
    addOcorrencia: 'Add occurrence',
    visualizarOcorrencia: 'View occurrence',
    alterarOcorrencia: 'Change occurrence',
    ocorrenciasVinculadas: 'Linked occurrences',
    areaProtegida: 'Protected area',
    especie: 'Species',
    aprovarOcorrencia: 'Approve occurrence',
    salvarOcorrencia: 'Save occurrence',
    colaborador: 'Collaborator',
    municipio: 'Municipality',
    municipioSede: 'Municipality (city)',
    municipioCentroide: 'Municipality (centroid)',
    referenciaLocal: 'Local reference',
    referencia: 'Reference',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'The coordinates correspond to',
    distanciaAproximada: 'Approximate distance',
    ambiente: 'Environment',
    baciaHidro: 'Basin',
    descricaoInvasao: 'Description of the invasion',
    abundancia: 'Abundance',
    situacaoPopulacional: 'Population status',
    manejo: 'Management',
    dataEntrada: 'Entry date',
    dataObservacao: 'Observation date (ex. 2020 ou 13/05/2020)',
    validado: 'Validated',
    especieMuseuHerbario: 'Museum or herbarium specimen',
    selecioneTipo: 'Select the type',
    herbario: 'Herbarium',
    museu: 'Museum',
    numeroExemplaInstituicao: 'Specimen number (Institution)',
    numeroExemplarColetor: 'Specimen number (Collector)',
    selecioneOpcao: 'Select an option',
    pointList1: 'A point of occurrence of one or more individuals of the species',
    pointList2: 'The center of a group of individuals of the species',
    pointList3: 'A point of occurrence at an approximate distance of:',
    coordinatesSourceList1: 'A - A political or administrative unit',
    coordinatesSourceList2: 'B - The actual site of occurrence',
    ultimoRegistroBaseDados: 'Latest records in database',
    ultimosAcessos: 'Latest access',
    fichaTecnicaEspecie: 'Species factsheet',
    fichaTecnicaEspecieMMA: 'Species factsheet MMA version 1',
    fichaTecnicaEspecieMMA2: 'Species factsheet MMA version 2',
    invasoras: 'Invasive',
    contidasBrasil: 'Contained',
    ausentesBrasil: 'Absent',
    deficiencia: 'Data deficient (DD)',
    formatosDisponiveisExportacao: 'Available formats for export:',
    biologiaEcologia: 'Biology and ecology',
    usoEconomico: 'Economic use',
    usoEconomicoDescricao: 'Economic use - description',
    invasaoBiologica: 'Biological invasion',
    impactos: 'Impacts',
    habitat: 'Habitat',
    nomeCientificoSinonimos: 'Scientific name + synonyms',
    nomesPopulares: 'Common names',
    reproducao: 'Reproduction',
    dispersao: 'Dispersal',
    dieta: 'Diet',
    formaBiologica: 'Habit',
    ambientesPreferenciaisInvasao: 'Habitats more susceptible to invasion',
    tipoIntroducao: 'Introduction type',
    causaIntroducao: 'Cause of introduction',
    localIntroducao: 'Place of introduction',
    anoIntroducao: 'Year of introduction',
    marinho: 'Marine',
    dulcicola: 'Freshwater',
    terrestre: 'Terrestrial',
    validarEspecie: 'Validate species',
    salvarEspecie: 'Save species',
    data: 'Date',
    confiabilidade: 'Confidence level',
    resultadosAnalisesRisco: 'Risk assessment results',
    risco: 'Risk',
    areaOrigem: 'Area of origin',
    areaDistribuicaoNatural: 'Native range',
    ambientesNaturais: 'Natural habitats',
    descricaoEspecie: 'Species description',
    ambientesPreferenciaisInvasaoDescricao: 'Habitats more susceptible to invasion - description',
    outrosLocaisOndeEspecieInvasora: 'Other places where the species is invasive',
    impactosEcologicos: 'Ecological impacts',
    impactosEconomicos: 'Economic impacts',
    impactosSociais: 'Social impacts',
    impactosSaude: 'Health impacts',
    categoriaEICAT: 'EICAT category',
    mecanismosImpactoEICAT: 'EICAT impact mechanisms',
    nivelConfiancaAvaliacaoEICAT: 'EICAT assessment confidence level',
    referenciaEICAT: 'EICAT reference',
    dataEICAT: 'EICAT date (e.g. 2021)',
    categoriaSEICAT: 'SEICAT category',
    mecanismosImpactoSEICAT: 'SEICAT impact mechanisms',
    nivelConfiancaAvaliacaoSEICAT: 'SEICAT assessment confidence level',
    referenciaSEICAT: 'SEICAT reference',
    dataSEICAT: 'SEICAT date (e.g. 2021)',
    digitarSomenteAno: 'Enter year only - e.g. 2021',
    viasCDBCategorias: 'CDB pathways - categories',
    viasIntroducao: 'Pathways of introducion and spread',
    viasVetoresCDBSubcategorias: 'CDB pathways - subcategories',
    vetoresIntroducao: 'Vectors of introducion and spread',
    introducao: 'Introduction',
    adicionarIntroducao: 'Add introduction',
    ano: 'Year',
    descricaoIntroducao: 'Introduction description',
    salvar: 'Save',
    cancelar: 'Cancel',
    excluir: 'Delete',
    medidasPreventivas: 'Prevention measures',
    medidasPreventivasMMA: 'Prevention measures MMA',
    medidasDeteccaoPrecoce: 'Early detection measures',
    controleMecanico: 'Mechanical control',
    controleQuimico: 'Chemical control',
    controleBiologico: 'Biological control',
    distribuicaoPais: 'Distribution in the country',
    vejaMapaOcorrencias: 'See occurrence map',
    ecologiaUso: 'Ecology and use',
    introducaoDispersao: 'Introduction and spread',
    analiseRisco: 'Risk analysis',
    nomeComum: 'Common name',
    foto: 'Photograph',
    credito: 'Credit',
    sinonimos: 'Synonyms',
    autor: 'Author',
    subespecie: 'Subspecies',
    autorSubespecie: 'Author of subspecies',
    pendenteAnalise: 'Analysis pending',
    cancelarVoltar: 'Cancel / Back',
    versaoAnterior: 'Former version',
    versaoAtual: 'Current version',
    colaboradorxs: 'Collaborators',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomy - Complete list of species',
    consultaEspeciesOrigemAmbientes: 'Species, origin, habitats susceptible to invasion, impacts',
    consultaEspeciesManejo: 'Species and management',
    consultaParaGerarFichaTecnica: 'Species factsheet without places of occurrence',
    consultaEspeciesOcorrencias: 'Species and places of occurrence',
    exportarDadosConsultas: 'Export data',
    ocorrenciasApp: 'Occurrences app',
    dataCadastro: 'Registration date',
    criadoPor: 'Created by',
    nivelCerteza: 'Confidence level',
    byApp: 'From mobile app',
    verDados: 'View data',
    appuser: 'App user',
    profissao: 'Profession',
    colobadorJaCadastrado: 'Collaborator already registered',
    especialidadeApp: 'Expertise app',
    cep: 'Zip code',
    categoriaCDB: 'CBD Category',
    subCategoriaCDB: 'CDB Subcategory',
    mensagemEnviada: 'Message sent successfully.',
    detectadaNatureza: 'Detected in nature (casual)',
    erradicada: 'Eradicated',
    exportar: 'Export'
  },
  home: {
    destaque1: 'Jamaica National Invasive Alien Species Database',
    destaqueDescricao1: 'This database system receives contributions from people committed to the conservation of biodiversity. We invite you to tour our page!',
    destaque2: 'Since 2006 documenting invasive alien species and sharing the information online!',
    destaqueDescricao2: 'This database system was developed and is kept updated with national and international resources and has a Working Group for support.',
    destaque3: 'What does this information system include?',
    destaqueDescricao3: 'We manage four interconnected databases on species and occurrences, collaborators, references, and projects.',
    destaque4: 'How does it work?',
    destaqueDescricao4: 'You can consult about invasive alien species that impact the environment, their occurrence points in Jamaica, specialists, projects and references.',
    titulo: 'Find species, filter and export data',
    conteudo: 'Here you will find data on the native range of invasive alien species, ecological and biological characteristics, habitats invaded, places where species are invasive in the world and in Jamaica, including a map of places of occurrence, history of introduction and use, vectors and pathways of introduction and spread, information on management and references. Filter and export data to different formats.',
    conteudoEspecies: 'Species lists, ecological traits, native range, where species are invasive in the world, vectors and pathways, risk assessment, uses, management options, places of occurrence in Jamaica, references.',
    conteudoColaboradores: 'Registry of people who have contributed data to the database. If you need to find an expert or more information a place of occurrence, look for contacts here.',
    conteudoReferencias: 'Complete list of references used for the data fields on invasive alien species.',
    conteudoProjetos: 'Invasive alien species research and management projects in Jamaica. If you are working on a project, please let us know, so we can register it.',
    titulo1: 'Learn more about some of Jamaica\'s invasive alien species',
    conteudo1: 'Invasive alien species belong to all groups of organisms and are introduced accidentally or voluntarily for a variety of reasons, their effects are also very diverse.'
  },
  areaParceiros: {
    titulo: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Latin American and Caribbean Network',
    paragrafo1: 'This database network was established in 2005 as I3N (IABIN Invasives Information Network), a thematic network on invasive alien species then developed as part of the InterAmerican Biodiversity Information Network established by governments of countries in the Americas in 2001.',
    paragrafo2: 'Each country had a Lead for the network in charge of developing a national database and sharing information on invasive species. The people in charge of the national databases are:',
    paragrafo2_1: '',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (since 2003);',
    marcador2: 'Brazil: Dr. Silvia R. Ziller, Founder and Executive Director of the Horus Institute for Environmental Conservation and Development (since 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Professor, Universidad de Costa Rica. Researcher at the Center for Research in Biodiversity and Tropical Biology. Co-coordinator of Flora Introduced and Naturalized in Central America;',
    marcador5: 'Ecuador: Dr. Diego Inclán, Executive Director of the National Biodiversity Institute - INABIO (since 2021) and Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dr. Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Jamaica: Dr. Suzanne Davis, Jamaica Clearing-House Mechanism, Institute of Jamaica;',
    marcador5_3: 'Paraguay: Hugo Fernando del Castillo, Guyra Paraguay (desde 2006);',
    marcador5_4: 'Uruguay: Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (since 2005), and M.Sc. Marcelo Iturburu, Coordinator of the Invasive Alien Species Committee of the Ministry of Environment (since 2018);',
    paragrafo3: 'IABIN received funding from a World Bank (GEF) project between 2004 and 2011, when it was terminated. Despite the closing of IABIN, some of the network Leads continued to develop and update the national databases (Brazil, Argentina, Uruguay, Paraguay and Jamaica).',
    paragrafo4: 'In 2021, the four South American countries received funding from the Bio-Bridge Initiative of the Convention on Biological Diversity to revitalize the Argentina, Brazil, Paraguay, and Uruguay databases. The interface was redesigned, new functions were added, and the programming was totally redone.',
    paragrafo5: 'The database developers are:',
    marcador6: 'The Programmer João Scucato, from Curitiba, Brazil, who developed the first version in Microsoft Access, 2004-2005;',
    marcador7: 'The Programmer Alejandro Moreno, from Argentina, who has provided support to the databases since 2006, and rescued data from Paraguay and Uruguay for this new version. He also developed the',
    marcador7_1: 'Common Platform',
    marcador7_2: 'for the South American countries in 2021.',
    marcador8: 'The Web Designer Rafael Moura and Programmer Thiago Lôbo, through',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'in Florianópolis, Brazil; ',
    marcador9: 'The programmer Leonardo Rotondano, in charge of developing the cell phone application for invasive species occurrences.',
    paragrafo6: 'We hope to expand the network once again to include more countries in Latin America and the Caribbean, making more data on invasive alien species available for management and policy, public knowledge and on-site field work.',
    paragrafo7: 'Network of Databases on Invasive Alien Species:'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Jamaica Database',
    titulo: 'Jamaica Invasive Species Database',
    paragrafo1: 'The Jamaica Invasive Species Database originated from the Inter-American Biodiversity Information Network (IABIN), an outcome of the organization of American States\' 1996 Summit of the Americas. The Government of Jamaica endorsed IABIN in the late 1990s. Subsequently, the Jamaica Clearing-House Mechanism (CHM) located at the Natural History Museum of Jamaica, Institute of Jamaica actively participated in IABIN\'s Thematic Working Group on Invasive Species. Through an IABIN Project, the Jamaica CHM received grant funding to develop the first national invasive species database in 2001 - 2002. The first version of the Database existed as an Excel file.',
    paragrafo2: 'In 2006 - 2007, IABIN-sourced funding and matching funds from the Institute of Jamaica facilitated the content building of the Database and migration of the records to a Microsoft Access version. In addition, standards for database entry developed under IABIN were applied to the Database.',
    paragrafo3: 'IABIN subsequently developed an open source database platform that member States could customize according to national needs. Under the National Biodiversity Strategy and Action Plan (NBSAP) Project, the Jamaica CHM acquired the platform and partnered with the National Spatial Data Management Division, to organize content management and database hosting. Before the formal launch of the JISD in 2015, nine (9) entities from the Invasive Alien Species Working Group agreed to periodically contribute to national content building for the Database. Furthermore, a data entry training workshop and another in 2018 were co-facilitated by the Jamaica CHM. The United Nations Development Programme provided grant funding through the NBSAP Project. At that time, the Database was the first comprehensive portal of its kind in the island.',
    paragrafo4: 'Since 2015, the JISD has provided information in support of Jamaica meeting its obligations under the United Nations Convention on Biological Diversity. Each entity\'s participation in the JISD network directly contributed information relevant to:',
    paragrafo5: 'The 2016 - 2021 National Biodiversity Target 9: "By 2021, invasive alien species and pathways are identified ...", and',
    paragrafo6: 'The Sustainable Development Goal, Target 15.8: "Prevent invasive alien species on land and in water ecosystems".',
    paragrafo6_1: 'Kunming-Montreal Global Biodiversity Framework, Target 6: ... identifying and managing pathways of introduction of invasive species ...',
    paragrafo7: 'This most recent version of the Database orients the JISD to enhance content building, especially with images and spatial data.',
    paragrafo8: 'Partners'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Data policy',
    titulo: 'Concepts and sources of reference',
    criterio: 'Criteria for species inclusion',
    avaliacao: 'Collaborative construction',
    validacao: 'Data validation',
    paragrafo1: 'For the purposes of this data system, the following definitions are adopted, in accordance with the Convention on Biological Diversity:',
    marcador1: 'Native species: found within its native range, where it evolved and is part of a biological community that is considered natural or semi-natural;',
    marcador2: 'Alien species: found outside its past or present natural range;',
    marcador3: 'Invasive alien species: any alien species that affects or threatens habitats, ecosystems or species, often leading to changes in the natural environment;',
    marcador3_1: 'Cryptogenic species: species whose origin cannot be established with certainty and therefore cannot be classified as native or alien.',
    paragrafo2: '',
    paragrafo3: '',
    paragrafo4: '',
    paragrafo5_1: 'The controlled vocabulary on habitats that are more susceptible to invasion, pathways and vectors, biological habit, impacts, and control methods were defined in accordance with the terms used in the Global Invasive Species Database managed by the Invasive Species Specialist Group linked to the IUCN',
    paragrafo5_2: 'The main taxonomic references are taken from the systems proposed by the Missouri Botanical Garden',
    paragrafo5_3: ', and the Integrated Taxonomic Information System',
    paragrafo5_4: 'Supplementary references include the World Register of Marine Species',
    paragrafo5_5: 'the Global Invasive Species Database',
    paragrafo5_6: 'and the CABI Invasive Species Compendium',
    paragrafo6: 'At each point of occurrence, the species are classified as:',
    marcador4: 'presence is limited or restricted to cultivation or breeding systems or is directly dependent on people, for example in a laboratory, as a pet or aquarium animal, or a garden plant, without evidence of escape at the location;',
    marcador5: 'Detected in nature (casual): observed in natural or semi-natural environments, outside of cultivation or breeding systems, still without evidence of having established a population;',
    marcador6: 'the species reproduces effectively, forming a self-sustaining population, but remains in a limited area, close to the site of introduction;',
    marcador7: 'the species advances, either on its own or assisted by anthropic vectors or pathways, to other sites beyond the point of introduction;',
    marcador7_1: 'when the species no longer exists at the site due to management interventions.',
    paragrafo7: 'It is important to note that invasion status is an attribute of the place of occurrence, not of the species. Therefore, the same species can behave as invasive in one place, be contained in another, and have been detected in the wild in a third place',
    paragrafo8: 'Data have been collected for the country since 2003 with suppport from hundreds of people, listed in the database as Collaborators. These data providers send articles, reports, or field data with occurrences of species that are then added to the database.',
    paragrafo8_en: 'All the information on species occurrences are always connected to the source, as the name of the data provider is included with each record as well as references of published materials. Other data are referenced in the descriptive fields. In case of doubt or need for more information on a point of occurrence, users can contact the person who sent or published the data. The link between data and source is meant to ensure that every contribution is properly acknowledged and cited when used by others.',
    paragrafo9_1: 'If you have data on invasive alien species to contribute to this database, please use the spreadsheet available from our website and send it to ',
    paragrafo9_2: ' or contact us.',
    paragrafo10: 'For other issues or questions, please contact us as well! ',
    paragrafo11: 'The majority of species listed in this database were introduced into Jamaica through human intervention and have become disruptive or detrimental to species in ',
    paragrafo11_1: 'natural, agricultural and urban ecosystems.',
    paragrafo11_2: 'The definitions of \'invasive alien species\' and \'alien species\' provided above align with those terms in the Glossary of Terms for the Convention of Biological Diversity website ',
    paragrafo11_3: 'The national Invasive Alien Species Working Group (IASWG) has developed criteria for listing species that are known to be invasive to Jamaica.',
    paragrafo12: 'The criteria for listing species that are known to be invasive to Jamaica are:',
    paragrafo12_1: 'Existence of scientific basis or reported evidence showing the negative impact of an alien species on Jamaican biodiversity',
    paragrafo12_2: 'Species documented as being invasive on other islands with similar climatic conditions',
    paragrafo12_3: 'Negative socio-economic impact including impacts on agriculture, tourism and public health',
    paragrafo13: 'The database is updated thanks to the contributions of volunteer collaborators distributed throughout the country. Our collaborators can contribute information on new species, new locations of occurrence of species already detected in the national territory, on projects, experts, or publications. The information is linked to the data providers as a recognition of their contributions to the state of the problem of biological invasions in the country and as a way to promote direct communication between people with knowledge and interest in invasive species. Information is also uploaded from publications and museum and herbarium records. Each new location of occurrence is evaluated based on validation standards shared with the Latin American and Caribbean Database Network . Occurrence data are associated with geographic coordinates, so species distribution maps are also available.',
    paragrafo14: 'The database administrators are in charge of providing information on the biological characteristics of each species, the habitats that are more susceptible to invasion, vectors and pathways of introduction and spread, impacts and control techniques, among other data fields.',
    paragrafo14_1: 'The data is continually reviewed as new information is available. New species or places of occurrence that imply significant changes in the distribution of a species or the colonization of new environments are confirmed by consulting experts in the Working Group or other experts, as necessary.',
    paragrafo15: 'The reliability of the data is one of the main concerns of the system administrators. Validation is conducted at two main levels: for the correct identification of each taxon and to verify the accuracy of the location data. Standards and criteria have been defined in agreement with the other existing databases in the network.',
    paragrafo16: 'Criteria to validate taxonomic identity',
    marcador8: 'Can the data provider (author of the publication or collector of the specimen) be considered an expert in the group in question or does the person have equivalent experience?',
    marcador9: 'Is the occurrence documented by means of a photograph that allows for the reliable identification of the organism in question?',
    marcador10: 'Is the occurrence documented in an indexed and peer-reviewed publication or in a technical document prepared or reviewed by experts?',
    marcador11: 'Does the information correspond to a herbarium specimen or to a specimen deposited in a museum collection?',
    marcador12: 'Does the occurrence correspond to a species already registered in the country?',
    marcador13: 'Is the taxonomy of the group to which the species belongs stable and well-known?',
    marcador14: 'Do the characteristics of the species allow for a relatively simple identification?',
    marcador14_1: '',
    paragrafo17: 'A positive answer to at least one of questions 1, 2, 3 or 4 indicates that the data should be considered validated. If these four questions have negative answers, the data will only be considered validated if the last three (5, 6 and 7) questions have positive answers.',
    paragrafo18: 'Criteria to validate location associated with the site where a species was detected',
    marcador15: 'Does the data include geographic coordinates? Both coordinates taken directly at the observation site and others that correspond to a nearby geographic reference are considered (as indicated).',
    marcador16: 'Is a description of the site included to allow for a reliable identification?',
    marcador17: 'Is the occurrence documented by a photograph that allows for a reliable identification of the site?',
    marcador18: 'Do the geographic coordinates provided coincide with the location of the described and / or photographed site?',
    marcador19: 'If the species is already cited for the country, is the locality included within its known distribution range or in a site compatible with the characteristics of other areas where the species is present, and with its spread capacity?',
    marcador20: 'Are the ecological conditions of the site, inferred from the indicated location, compatible with the general niche requirements of the species?',
    paragrafo19: 'The geographical location data are considered validated if',
    marcador21: 'A- questions 1, 2 and / or 3, and 4 have positive answers (the data includes geographical coordinates, an unequivocal reference to the site is provided and / or a photograph is provided that allows reliable identification of the place and the coordinates provided match the location of the site described or photographed), or',
    marcador22: 'B- a positive or negative answer is assigned to question 1 (geographical coordinates are provided or not), a negative answer to questions 2 and 3 (the site is not described in detail or a photograph is attached to allow it to be located), but questions 5 and 6 are answered positively (the species has already been cited for the country and the locality is included within its known distribution range or in a site compatible with the characteristics of other areas where the species is present and with its spread capacity, and the ecological conditions of the site are compatible with the general niche requirements of the species).',
    paragrafo20: 'The location will be considered not validated if:',
    marcador23: 'A- question 1 and questions 2 and / or 3 have positive answers, but question 4 is negative (the coordinates do not coincide with the site described or photographed), or,',
    marcador24: 'B- questions 2 and 3 have negative answers, and at least one of questions 5 and 6 are negative (the data does not meet one or neither of these two conditions: 1- The species is already cited for the country and, in that case, the locality is included within its known range of distribution or in a site that is compatible with the characteristics of other areas where the species is present and with its spread capacity, 2- the site meets ecological conditions compatible with the general requirements niche of the species).',
    paragrafo21: 'Finally, the data are considered validated if the validation analysis of both criteria (taxonomy and location) is approved.',
    paragrafo22: 'Validation is not proposed as a rule for inclusion of data, but as a descriptor that accompanies each location of occurrence. Each locality of occurrence of each species is associated with the descriptor “validated data” or “non-validated data”. It is important to note that this classification is temporary, since data can be confirmed and become validated once the criteria are met at some point after they have been appended to the system. The status of validation of species occurrences is shown on the respective distribution maps, with validated localities in green, and non-validated ones in grey.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Working group',
    titulo: 'Jamaica’s Invasive Alien Species Working Group',
    paragrafo1: 'The Invasive Alien Species Working Group was established to assist Jamaica in fulfilling its obligations under the U.N. Convention on Biological Diversity (CBD), specifically Article 8(h) which states, “Prevent the introduction of, control or eradicate those alien species which threaten ecosystems, habitats or species”.',
    paragrafo2: 'The Working Group embraces Section IV, (a)10 of Decision VI/23 of the CBD and the annex to Decision VI/23 on Guiding Principles for the Prevention, Introduction and Mitigation of Impacts of Alien Species that Threaten Ecosystems, Habitats or Species (see',
    paragrafo3: 'The major objectives of the Invasive Alien Species Working Group (IASWG) include:',
    marcador1: 'Providing a forum for discussion on invasive alien species (IAS) and their potential or actual impacts primarily in Jamaica, as well as worldwide;',
    marcador2: 'Preparing and maintaining lists of alien species that are i) invasive and ii) potentially invasive to Jamaica;',
    marcador3: 'Identifying and making recommendations on best practices regarding measures for prevention, early detection, control and/or eradication of IAS;',
    marcador4: 'Identifying gaps and making recommendations regarding research needs for IAS;',
    marcador4b: 'Reviewing and making recommendations regarding the development or revision of national policies, strategies, legislation, guidelines, management plans and projects relating to IAS, as required;',
    marcador4c: 'Assisting with the development of IAS-related projects;',
    marcador4d: 'Assisting with the promotion of public education and awareness related to the actual and potential impacts of IAS, including those related to human health, and measures for control and eradication.',
    paragrafo4: 'The Jamaica Invasive Species Database (JISD) is continually advanced through input from Jamaica’s Invasive Alien Species Working Group. The national list of invasive alien species developed by the IASWG is what guides the data entry into the Jamaica Invasive Species database (JISD). The IASWG also aids in the validation of information inputted into the JISD.',
    marcador5: '',
    marcador6: '',
    marcador7: '',
    marcador8: '',
    marcador9: '',
    marcador10: '',
    marcador11: ''
  },
  especiesInstitucional: {
    titulo: 'Species search',
    paragrafo1: 'Search here for detailed information about the invasive alien species present in Jamaica, the specialists who study them, the projects dedicated to understanding their behavior, their effects and control options, and the publications that refer to them.',
    paragrafo2_1: 'If you have occurrence data for invasive alien species in Jamaica, ',
    paragrafo2_2: 'please contribute to update this database',
    verTutorial: 'Open tutorial',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Jamaica National Invasive Alien Species Database. Institute of Jamaica. Checked on DD/MM/AAA.',
    paragrafo4_2: '',
    tutorialTitulo1: 'Please cite our database',
    tutorialDescricao1: 'Please use this citation format in publications.',
    tutorialTitulo2: 'Use the filters',
    tutorialDescricao2: 'You’ll find some options to filter contents here.',
    tutorialTitulo3: 'Use the filters: select data',
    tutorialDescricao3: 'Choose filters and combine data by place, biological group, use, taxonomy, etc.',
    tutorialTitulo4: 'Number of records',
    tutorialDescricao4: 'This is the number of invasive alien species in the database or resulting from your search using combined filters.',
    tutorialDescricao5: 'Here you can select the number of records you wish to view per page.',
    tutorialTitulo6: 'List of invasive alien species',
    tutorialDescricao6: 'List of all species or search results using filters. Click on the name of the species to see the specific data set.',
    tutorialTitulo6_1: 'Export data',
    tutorialDescricao6_1: 'Here you can filter data and choose the format to export.',
    tutorialDescricao7: 'Taxonomic classification of the species selected, common names, synonyms, and a picture when possible!',
    tutorialTitulo8: 'Ecology and use',
    tutorialDescricao8: 'Description, biology and ecology of the species selected, native range, type of habitats it invades, and economic uses.',
    tutorialTitulo9: 'Introduction and spread',
    tutorialDescricao9: 'How the species arrived, why it was introduced, and how it spreads to other areas.',
    tutorialTitulo10: 'Impacts',
    tutorialDescricao10: 'Environmental, economic, health, and cultural impacts. Classification of environmental impacts according to the IUCN EICAT protocol.',
    tutorialTitulo11: 'Management',
    tutorialDescricao11: 'Prevention and early detection measures, mechanical, chemical, biological control.',
    tutorialTitulo12: 'Risk analysis',
    tutorialDescricao12: 'Risk analysis results for the species selected.',
    tutorialTitulo13: 'Places of occurrence',
    tutorialDescricao13: 'Location, ecosystems, protected areas, states in the country where the species occurs, and stage of invasion – see the map!',
    tutorialDescricao14: 'List of references used to compile data on the species and other publications on the species.',
    tutorialTitulo15: 'Projects',
    tutorialDescricao15: 'List of research and management projects on the species selected.'
  },
  colaboradoresInstitucional: {
    titulo: 'Collaborators',
    paragrafo1: 'Collaborators are those who contribute data to this information system, as well as those who collect museum or herbarium specimens, author publications used as data sources or provide support for species identification or to validate information on invasive alien species.',
    paragrafo2: 'Contributions of places of occurrence for species in the database are highly relevant for us to update distribution data and detect new areas of invasion. This in turn helps managers respond with management actions and allows us to share knowledge on species locations.',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Jamaica National Invasive Alien Species Database. Institute of Jamaica. Checked on DD/MM/AAA.',
    paragrafo4_2: ''
  },
  especiesMarinhasInstitucional: {
    titulo: 'Wild marine species',
    paragrafo1: 'Marine ecosystems are aquatic ecosystems that relate to the sea. The water in these ecosystems have high salt content, for example, oceans and other coastal ecosystems such as mangroves and estuaries.',
    paragrafo2: 'The most common pathways for marine invasive species are ship ballast water, hitchhiking on ship hulls and other aquatic structures (biofouling), intentional and unintentional release of pets acquired through aquaria, and the exotic pet trade.',
    paragrafo3: 'The Asian green mussel (Perna viridis) and the Lionfish (Pterois volitans) are two marine invasive species. The Asian Green Mussel is notorious for clogging and corroding water pipes used by industrial complexes, fouling marine equipment, and threatening the sustainability of shellfish fisheries. The Lionfish has no aggressive natural predators and can overwhelm native species with whom they compete for food and space, causing a threat to Jamaica\'s reef fish population.',
    paragrafo3_1: '',
    paragrafo4: 'Lionfish - Pterois volitans',
    paragrafo4b: 'Photo: Anthony Chang'
  },
  especiesAguaDoceInstitucional: {
    titulo: 'Wild freshwater species',
    paragrafo1: 'Freshwater ecosystems include lakes, ponds, rivers, streams, springs, bogs, and marshes. In contrast to marine ecosystems, they have a low salt content.',
    paragrafo2: 'Some of the major pathways of introduction of freshwater invasive species is through intentional release or accidental escape of aquaculture species.',
    paragrafo3: 'The Australian Red-claw Lobster (Cherax quadricarinatus) and the Nile Tilapia (Oreochromis niloticus niloticus) are two species that were initially aquaculture species that can be now found in the wild.',
    paragrafo3_1: 'Other pathways include the release of pets and ornamental cultivation escape , for instance the Water Hyacinth (Eichhornia crassipes).',
    paragrafo4: 'Black River - Upper Morass',
    paragrafo4b: 'Photo: Keron Campbell'
  },
  especiesTerrestresInstitucional: {
    titulo: 'Terrestrial species',
    paragrafo1: 'Terrestrial ecosystems are found on land and contrast aquatic ecosystems by the predominant presence of soil rather than water at the surface. Examples include forests, grasslands and deserts.',
    paragrafo2: 'There are several pathways of introduction of invasive terrestrial species. The pathways are inclusive but not exhaustive of:',
    paragrafo3: 'Cultivation escape of ornamental herbs such as the Wild Ginger (Alpinia nigra), which was actively introduced as an ornamental in gardens and yards.',
    paragrafo3_1: 'Biological control agents such as the small Indian Mongoose (Herpestes auropunctatus) which was deliberately imported as a biological control agent against rats in sugar cane fields. It has subsequently been linked to the extinction of five endemic vertebrate species, one lizard, one snake, two birds, and a rodent.',
    paragrafo4: 'Wild coffee - Pittosporum undulatum in Blue Mountains',
    paragrafo4b: 'Photo: S. Otuokon, JCDT',
    paragrafo5: 'Red bush - Persicaria chinensis in Blue Mountains',
    paragrafo5b: 'Photo: S. Otuokon, JCDT'
  },
  especiesAgricolasInstitucional: {
    titulo: 'Agricultural invasive species',
    paragrafo1: 'This group of invasive species has either posed a threat to or significantly decreased the production of many agricultural crops, animals, and trees. A number of them are pests of economic interest, requiring human intervention to control their populations. One such pest in Jamaica is the Lime Swallowtail Butterfly (Papilio demoleus) which is a pest to citrus plants particularly seedlings in nurseries and to young plants.',
    paragrafo2: 'Removal of the butterfly larvae by hand, the application of insecticides and naturally-occurring predatory wasps are some of the methods used in the management of this pest.',
    paragrafo3: 'Lime Swallowtail Butterfly - Papilio demoleus',
    paragrafo3b: 'Photo: NHMJ collection'
  },
  peixesAquarioInstitucional: {
    titulo: 'Aquarium fishes',
    paragrafo1: 'Aquarium fishes are currently the most numerous pets in the world. Brazil is a large provider of aquarium fishes due to the high species richness in the Amazon and Pantanal. Still, most fishes are non-native wherever they are sold. Such fishes must never be released in lakes, riverrs or the ocean, as they may prey on native species, change natural habitats or transmit diseases or parasites.',
    paragrafo2: 'Besides, they may become invasive, as in the case of lion fish, which escaped na aquarium in Florida, USA, and is now invasive throughout the Caribbean. ',
    paragrafo3: 'It might reach the coast of Brazil, where it ir a high risk for coral reefs in Abrolhos and Fernando de Noronha. ',
    paragrafo4: 'Learn more:',
    marcador1: 'Non-native ornamental fishes threaten biodiversity',
    marcador2: 'Non-native invaders',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - cichlid fish',
    paragrafo5b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenile)',
    paragrafo6b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae cichlid fish',
    paragrafo7b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - serpae tetra fish',
    paragrafo8b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - fat oscar',
    paragrafo9b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - zebra danio',
    paragrafo10b: 'Photo by: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - lion fish',
    paragrafo11b: 'Photo by: The Horus Institute'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Aquaculture fishes',
    paragrafo1: 'Aquaculture is the main pathway of introduction of freshwater fishes in Brazil. Many species are quite aggressive and have caused the extinction of native species in seveal countries, such as the Nile tilápia and the African catfish. Pangasius Is a fish native to Asia now being cultivated in Brazil in spite of not having been authorized by the federal environmental agency. It is highly invasive, and survives in low quality waters. Learn about fish products before you buy them to avoid contributing to environmental impacts. ',
    paragrafo2: 'The problem is that breeding facilities such as artificial ponds and net cages are not safe enough to avoid escapes, or fishes are releaseed on purpose by breeders. They may prey on native species, change the natural aquatic environment, or transmit diseases or parasites. ',
    paragrafo3: 'Learn more.',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo4: 'Cichla kelberi - peacock bass',
    paragrafo5: 'Coptodon rendalli - tilapia',
    paragrafo6: 'Cyprinus carpio - common carp',
    paragrafo7: 'Micropterus salmoides - black bass',
    paragrafo7b: 'Photo by: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus - Nile tilapia'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Sport fishing',
    paragrafo1: 'Sport fishing is a leisure activity that has led to the introduction of many non-native species which are often varacious predators. Although the introduction of non-native species and the translocation of species between watersheds is prohibited in Brazil, (Portaria IBAMA 145-N/1998), many fishes have been taken from their watersheds of origin to others where they are not native, creating impacts on native species by predation, competition, and transmission of diseases and parasites, apart from ecological imbalance.',
    paragrafo2: 'The introduction of invasive species used as live bait is associated with sport fishing, as they may escape from fish hooks or even be discarded in natural water bodies. Fishes and other species used as live bait should only be used when native to the watershed where you go fishing to avoid introducing alien species and their impacts on native species.',
    paragrafo3: 'Never release non-native species in nature!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Photo by: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - peacock bass',
    paragrafo5b: 'Photo by: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - peacock bass',
    paragrafo6b: 'Photo by: LEPIB - UEL PR',
    paragrafo7: 'Learn more'
  },
  petsInstitucional: {
    titulo: 'Pets',
    paragrafo1_1: 'The word ',
    pet: 'pet',
    paragrafo1_2: ' refers to animals maintained for company, from aquarium fishes to dogs and cats. Some animals listed here, especially cats and dogs, are only considered a problem when present in natural areas where they can harm native animals.',
    paragrafo2: 'Do not allow your pet to wander in natural areas! ',
    paragrafo3: 'Trachemys scripta - red-eared slider',
    paragrafo3b: 'Photo by: Marcos Tortato',
    paragrafo4: 'Felis catus - domestic cat',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Canis familiaris - domestic dog',
    paragrafo5b: 'Photo by: Marcos Tortato',
    paragrafo6: 'Trachemys dorbigni - slider',
    paragrafo6b: 'Photo by: Marcos Tortato'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Forage plants',
    paragrafo1: 'Many plants have been introduced for use as forage, i.e., to feed domestic animals such as cattle, goats, horses or mules. These plants are mainly grasses, among which braquiarias are aggressive invasives in natural areas, degraded areas, and even wetlands and small streams. Small trees such as leucena, and trees such as mesquite are also used as forage, the pods fed to goats.',
    paragrafo2: 'These species were widely disseminated in the tropical world and cause environmental impacts by changing natural cycles such as fire, reducing water availability, and displacing native species by forming dense stands where nothing else can grow.',
    paragrafo3: 'Learn more',
    paragrafo4: 'Urochloa decumbens - brachiaria',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Prosopis juliflora - mesquite',
    paragrafo5b: 'Photo by: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - molassess grass',
    paragrafo6b: 'Photo by: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - jaragua grass',
    paragrafo7b: 'Photo by: The Horus Institute'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Ornamental plants',
    paragrafo1: 'Ornamental plants make up for more than 50% of all invasive alien plants in Brazil. The choice of plants for gardens and urban landscaping can contribute for the conservation of nature, which includes water provisioning, climate stability and many other benefits of nature to people.',
    paragrafo2_1: 'Grow plants that are native in your area',
    paragrafo2_2: 'Get to know, avoid and replace invasive alien plants in your garden.',
    paragrafo3: 'Find native alternatives for your area in the ',
    arquiflora: 'Arquiflora website',
    paragrafo4: 'Tecoma stans - yellow bells',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Furcraea foetida - Mauritius hemp',
    paragrafo5b: 'Photo by: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - dumb cane',
    paragrafo6b: 'Photo by: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - asparagus fern',
    paragrafo7b: 'Photo by: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - tropical almond tree',
    paragrafo8b: 'Photo by: The Horus Institute'
  },
  projetosInstitucional: {
    titulo: 'Projects',
    paragrafo1: 'This section contains information about current or finished projects focused on invasive species research or management in Jamaica. If you work or have worked in such a project, please help us by sending data to',
    paragrafo1_1: 'Your contribution is much appreciated.',
    paragrafo2_1: '',
    paragrafo2_2: '',
    paragrafo2_3: '',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Jamaica National Invasive Alien Species Database. Institute of Jamaica. Checked on DD/MM/AAA.',
    paragrafo4_2: ''
  },
  referenciasInstitucional: {
    titulo: 'References',
    paragrafo1: 'The publications and reports listed here have been used as data sources on invasive alien species, their behavior and impacts. Some include information on managemet and on places of occurrence in the country. If you have published or know of publications that are not listed here, please contribute by sending publications or citations to',
    paragrafo2: 'If you have publications on invasive alien species that are not listed here, please let us know and contribute to maintain the database up to date.',
    paragrafo3: 'Contribute with data',
    paragrafo4: 'Please use the following format to cite data from this system:',
    paragrafo5_1: 'Jamaica National Invasive Alien Species Database. Institute of Jamaica. Checked on DD/MM/AAA.',
    paragrafo5_2: ''
  },
  usoFlorestalInstitucional: {
    titulo: 'Forestry',
    paragrafo1: 'Several non-native species were introduced in Brazil after 1950 by government initiatives to develop a forestry industy for pulp, paper, wood and other subproducts. Some of these species became invasive, especially pines which have been distributed to many ecosystems. These species require continuous control to prevent them from escaping plantations and causing environmental damage. Forest certification requires companies to control invasions, and such trees have to be eliminated from natural areas, roadsides and railways, riparian forests, and other habitat types where they are not cultivated.',
    paragrafo2: 'The genus Pinus is considered the most invasive genus of tree in the southern hemisphere! Pines displace native vegetation in open ecosystems and use up more water than native plants.',
    paragrafo3: 'Learn more',
    paragrafo4: 'Pinus sp. - pine',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Acacia mearnsii - black wattle',
    paragrafo5b: 'Photo by: The Horus Institute',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Photo by: The Horus Institute'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contact',
    contato: 'Contact',
    p1: 'Fill all the fields in the form below:',
    nome: 'Name',
    email: 'E-mail',
    instituicao: 'Institution',
    mensagem: 'Message',
    enviar: 'Send'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Collaborate',
    paragrafo1: 'Our database is updated with support from experts and nature lovers throughout the national territory. Before being published, all information provided is validated according to criteria adopted at the regional level.',
    paragrafo2: 'You can provide data on species not yet listed in our database or on new places of occurrence by downloading our app (Invasives JM) or sending an e-mail to',
    paragrafo2b: 'You can also send information on new publications or projects on biological invasions in Jamaica. For information on how to join our team of data providers, please contact chm@nhmj-ioj.org.jm or telephone 876(922-0620).',
    paragrafo3: 'Help us face the challenge of invasive alien species based with complete, up-to-date and reliable information!',
    paragrafo4: 'Thank you!'
  }
}
