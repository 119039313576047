import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
  iconPack: 'fontawesome',
  position: 'top-center',
  duration: 10000
})

Vue.toasted.register(
  'defaultSuccess',
  payload => !payload.msg ? 'Operation was successful!' : payload.msg,
  { type: 'success', icon: 'check' }
)

Vue.toasted.register(
  'defaultError',
  payload => !payload.msg ? 'Unexpected error.' : payload.msg,
  { type: 'error', icon: 'times' }
)

Vue.toasted.register(
  'saved',
  payload => !payload.msg ? 'Saved!' : payload.msg,
  { type: 'success', icon: 'check' }
)

Vue.toasted.register(
  'notSaved',
  payload => !payload.msg ? 'Error - Data not saved!' : payload.msg,
  { type: 'error', icon: 'times' }
)

Vue.toasted.register(
  'infoFilter',
  payload => !payload.msg ? 'Please enter at least one filter field!' : payload.msg,
  { type: 'info', icon: 'info' }
)
