<template>
  <span class="edit-label edit-field">
      <input type="text" class="edit-label-input" v-model="synonymous.synonymous" ref="synonymous">
      <ul class="acoes d-flex justify-content-end">
        <li><a @click="salve"><span class="salvar"></span></a></li>
        <li><a @click="cancel"><span class="cancelar"></span></a></li>
      </ul>
  </span>
</template>

<script>
export default {
  name: 'EditDataSynonyms',
  props: {
    synonymous: {}
  },
  data: function () {
    return {
      edSynonymous: {
        synonymous_id: 0,
        synonymous: ''
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancelEditSynonymous', this.edSynonymous)
    },
    salve: function () {
      this.$emit('salveEditSynonymous')
    }
  },
  mounted () {
    this.edSynonymous.synonymous_id = this.synonymous.synonymous_id
    this.edSynonymous.synonymous = this.synonymous.synonymous
    this.$refs.synonymous.focus()
  }
}
</script>
