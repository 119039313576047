<template>
  <span class="edit-label edit-field">
      <input type="text" class="edit-label-input" v-model="item.class" ref="class">
      <v-select :appendToBody="true" :options="list" v-model="item.phyllum_id" label="phyllum" index="phyllum_id"
        :reduce="r => r.phyllum_id" :clearable="false" style="min-width: 150px;">
        <template v-slot:selected-option="option">
          {{ `${option.phyllum}` }}
        </template>
        <template v-slot:option="option">
          {{ `${option.phyllum}` }}
        </template>
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
          </template>
        </template>
      </v-select>
      <ul class="acoes d-flex justify-content-end">
        <li><a @click="salve"><span class="salvar mt-1"></span></a></li>
        <li><a @click="cancel"><span class="cancelar mt-1"></span></a></li>
      </ul>
  </span>
</template>

<script>
export default {
  name: 'EditTaxonomyClass',
  props: {
    objClass: {},
    list: Array
  },
  data: function () {
    return {
      item: {
        class_id: 0,
        class: ''
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancelEdit')
    },
    salve: function () {
      this.$emit('salveEdit', this.item)
    }
  },
  mounted () {
    this.item = this.objClass
    this.$refs.class.focus()
  }
}
</script>
