import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './util/i18n'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import VueGeolocation from 'vue-browser-geolocation'
import { Icon } from 'leaflet'
import 'vue-select/dist/vue-select.css'
import './util/mq'
import './util/constants'
import 'feather-icons'
import './util/toasted'
import './util/axios'
import './util/moment'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.component('v-select', vSelect)
Vue.use(VueGeolocation)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  mixins: [Vuelidate.validationMixin]
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
