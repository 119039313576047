<template>
  <div class="container-fluid p-0">
    <PageTitle v-if="!byInstitucional" :title="pageTitle" :titleSave="pendingAnalysis ? $t('label.aprovarReferencia') : $t('label.salvarReferencia')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit" :pendingAnalysis="pendingAnalysis"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
                <div class="align-self-center">
                  <div class="row">
                    <div class="col-md-6">
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.title !== this.form.log_update.Title }">
                      <label for="referencias-titulo" class="form-label">{{ $t('label.titulo') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.title !== this.form.log_update.Title" class="btn" type="button" @click="analysis($t('label.titulo'), 'title')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <textarea v-model="form.title" type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$dirty && $v.form.title.$error }" :disabled="byInstitucional" rows="2"></textarea>
                      <div class="invalid-feedback">
                        {{ $t('message.requiredField') }}
                      </div>
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.author !== this.form.log_update.Author }">
                      <label for="referencias-autor" class="form-label">{{ $t('label.autorxsEX') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.author !== this.form.log_update.Author" class="btn" type="button" @click="analysis($t('label.autorxsEX'), 'author')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.author" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.location !== this.form.log_update.Location }">
                      <label for="referencias-local" class="form-label">{{ $t('label.local') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.location !== this.form.log_update.Location" class="btn" type="button" @click="analysis($t('label.local'), 'location')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.location" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.edition !== this.form.log_update.Edition }">
                      <label for="referencias-edicao" class="form-label">{{ $t('label.edicao') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.edition !== this.form.log_update.Edition" class="btn" type="button" @click="analysis($t('label.edicao'), 'edition')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.edition" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.publisher !== this.form.log_update.Publisher }">
                      <label for="referencias-editora" class="form-label">{{ $t('label.editora') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.publisher !== this.form.log_update.Publisher" class="btn" type="button" @click="analysis($t('label.editora'), 'publisher')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.publisher" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.magazine !== this.form.log_update.Magazine }">
                      <label for="referencias-revista" class="form-label">{{ $t('label.revista') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.magazine !== this.form.log_update.Magazine" class="btn" type="button" @click="analysis($t('label.revista'), 'magazine')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <textarea v-model="form.magazine" type="text" class="form-control" :disabled="byInstitucional" rows="2"></textarea>
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.year !== this.form.log_update.Year }">
                      <label for="referencias-ano-publicacao" class="form-label">{{ $t('label.anoPublicacao') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.year !== this.form.log_update.Year" class="btn" type="button" @click="analysis($t('label.anoPublicacao'), 'year')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.year" v-mask="'####'" class="form-control" type="text" maxLength="4" :disabled="byInstitucional">
                    </div>
                  </div>
                  <div class="col-md-6 border-start">
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.medium_printed !== this.form.log_update.Medium_printed }">
                      <label for="referencias-meio-divulgacao" class="form-label">{{ $t('label.meioDivulgacao') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.medium_printed !== this.form.log_update.Medium_printed" class="btn" type="button" @click="analysis($t('label.meioDivulgacao'), 'medium_printed')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.medium_printed" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.vol !== this.form.log_update.Vol }">
                      <label for="referencias-volume" class="form-label">{{ $t('label.volume') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.vol !== this.form.log_update.Vol" class="btn" type="button" @click="analysis($t('label.volume'), 'vol')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.vol" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.number !== this.form.log_update.Number }">
                      <label for="referencias-numero" class="form-label">{{ $t('label.numero') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.number !== this.form.log_update.Number" class="btn" type="button" @click="analysis($t('label.numero'), 'number')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.number" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.pag !== this.form.log_update.Pag }">
                      <label for="referencias-pagina" class="form-label">{{ $t('label.pagina') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.pag !== this.form.log_update.Pag" class="btn" type="button" @click="analysis($t('label.pagina'), 'pag')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.pag" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.url !== this.form.log_update.Url }">
                      <label for="referencias-url" class="form-label">{{ $t('label.url') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.url !== this.form.log_update.Url" class="btn" type="button" @click="analysis($t('label.url'), 'url')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input v-model="form.url" type="text" class="form-control" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && pendingSpecies }">
                      <label for="referencias-especies" class="form-label">{{ $t('label.especies') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && pendingSpecies" class="btn" type="button" @click="analysis(`${$t('label.especies') }`, 'specieSelected')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <v-select multiple :appendToBody="true" :filterable="false" @search="getSpecies" :options="speciesList" v-model="specieSelected" label="scientific_name" index="species_id"
                        :reduce="r => r.species_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                        <template v-slot:selected-option="option">
                          {{ `${option.scientific_name}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.scientific_name}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.keyword_id !== this.form.log_update.Keyword_id }">
                      <label for="referencia-palavra-chave" class="form-label">{{ $t('label.palavraChave') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.log_update &&  this.form.keyword_id !== this.form.log_update.Keyword_id" class="btn" type="button" @click="analysis($t('label.palavraChave'), 'keyword_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <v-select :appendToBody="true" :options="vocKeywordsList" v-model="form.keyword_id" label="value" index="keyword_id"
                        :reduce="r => r.keyword_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
    <AdminModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import AdminModalReview from '@/components/admin/AdminModalReview'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ReferenciasForm',
  props: {
    byInstitucional: Boolean,
    idItem: Number,
    viewItem: Boolean
  },
  components: {
    PageTitle, AdminModalReview
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.referencias')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      form: { title: '' },
      vocKeywordsList: [],
      speciesList: [],
      specieSelected: [],
      oldSpecieSelected: [],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  validations: {
    form: {
      title: { required }
    }
  },
  watch: {
    'form.relBibliography' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getSpecieSelected()
        this.getOldSpecieSelected()
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    },
    pendingSpecies: function () {
      let result = false
      if (this.specieSelected.length !== this.oldSpecieSelected.length) {
        return true
      }
      this.oldSpecieSelected.forEach(e => {
        if (!this.specieSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  methods: {
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/bibliography/${this.form.bibliography_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'title':
          this.modalValuesSimple(this.form.title, this.form.log_update.Title, label)
          break
        case 'author':
          this.modalValuesSimple(this.form.author, this.form.log_update.Author, label)
          break
        case 'location':
          this.modalValuesSimple(this.form.location, this.form.log_update.Location, label)
          break
        case 'edition':
          this.modalValuesSimple(this.form.edition, this.form.log_update.Edition, label)
          break
        case 'publisher':
          this.modalValuesSimple(this.form.publisher, this.form.log_update.Publisher, label)
          break
        case 'magazine':
          this.modalValuesSimple(this.form.magazine, this.form.log_update.Magazine, label)
          break
        case 'year':
          this.modalValuesSimple(this.form.year, this.form.log_update.Year, label)
          break
        case 'medium_printed':
          this.modalValuesSimple(this.form.medium_printed, this.form.log_update.Medium_printed, label)
          break
        case 'vol':
          this.modalValuesSimple(this.form.vol, this.form.log_update.Vol, label)
          break
        case 'number':
          this.modalValuesSimple(this.form.number, this.form.log_update.Number, label)
          break
        case 'pag':
          this.modalValuesSimple(this.form.pag, this.form.log_update.Pag, label)
          break
        case 'url':
          this.modalValuesSimple(this.form.url, this.form.log_update.Url, label)
          break
        case 'keyword_id':
          newValue = this.vocKeywordsList.filter(l => l.keyword_id === this.form.keyword_id)[0]
          oldValue = this.vocKeywordsList.filter(l => l.keyword_id === this.form.log_update.Keyword_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'specieSelected':
          this.modalValues.label = label
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.specieSelected.forEach(e => {
            newValue = this.speciesList.filter(l => l.species_id === e)[0]
            if (!newValue) {
              newValue = this.getSpeciesById(e)
            }
            this.modalValues.newValue.push({ id: newValue.species_id, value: newValue.scientific_name })
          })
          this.modalValues.oldValue = []
          this.oldSpecieSelected.forEach(e => {
            oldValue = this.speciesList.filter(l => l.species_id === e)[0]
            if (!oldValue) {
              oldValue = this.getSpeciesById(e)
            }
            this.modalValues.oldValue.push({ id: oldValue.species_id, value: oldValue.scientific_name })
          })
          break
      }
    },
    getVocKeywords: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocKeywords/getall', { params })
        .then(response => {
          this.vocKeywordsList = response.data.items.map(i => {
            const item = {
              keyword_id: i.keyword_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getById: function (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/Bibliography/${id}`)
        .then(response => {
          this.form = response.data
          this.nameEdit = ` ${this.form.title}`
          this.form.log_update = JSON.parse(this.form.log_update)
        })
    },
    save: function () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.form.relBibliography = []
      this.specieSelected.forEach(element => {
        this.form.relBibliography.push({ biblio_id: this.form.bibliography_id, species_id: element })
      })
      this.form.log_update = JSON.stringify(this.form.log_update)
      if (this.action === this.$actionEdit) {
        axios.put('/bibliography', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/bibliography', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/referencias')
      }
    },
    getSpeciesById: async function (id) {
      let specie = {}
      await axios.get(`/Specie/${id}`)
        .then(response => {
          specie = response.data
        })
      return specie
    },
    getSpecies: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.scientific_name = search
        params.species_id_array = this.specieSelected.join(',')
        loading(true)
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.species_id_array = this.specieSelected.join(',')
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
          })
      }
    },
    getSpecieSelected: function () {
      this.form.relBibliography.forEach(e => {
        if (e && !this.specieSelected.includes(Number(e.species_id))) {
          this.specieSelected.push(Number(e.species_id))
        }
      })
      this.getSpecies()
    },
    getOldSpecieSelected: function () {
      if (this.form.log_update) {
        this.form.log_update.RelBibliography.forEach(e => {
          if (e && !this.oldSpecieSelected.includes(Number(e.Species_id))) {
            this.oldSpecieSelected.push(Number(e.Species_id))
          }
        })
      }
    }
  },
  created: function () {
    this.getVocKeywords()
    this.getSpecies()
    if (this.viewItem) {
      this.action = this.$actionEdit
      this.getById(this.idItem)
    } else {
      this.action =
        this.$route.params.id !== this.$actionNew
          ? this.$actionEdit
          : this.$actionNew

      if (this.action === this.$actionEdit) {
        this.pageTitle = `${this.$t('label.referencias')} > ${this.$t('label.editando')}: `
        this.getById(this.$route.params.id)
      }
    }
  }
}
</script>
