<template>
  <div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
      <div class="col-auto d-none d-sm-block">
        <h3 class="laranja h4">{{ $t('label.exportarDadosConsultas') }}</h3>
      </div>
    </div>
    <ConsultasFiltro />
  </div>
</template>

<script>
import ConsultasFiltro from './ConsultasFiltro.vue'
export default {
  name: 'Consultas',
  components: { ConsultasFiltro }
}
</script>
