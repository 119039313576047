<template>
  <div class="container-fluid p-0">
    <PageTitle :title="pageTitle" :titleSave="pendingAnalysis ? $t('label.aprovarOcorrencia') : $t('label.salvarOcorrencia')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit" :pendingAnalysis="pendingAnalysis"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
                <div class="align-self-center">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.species_id !== this.item.log_update.Species_id }">
                        <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.especie') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.species_id !== this.item.log_update.Species_id" class="btn" type="button" @click="analysis($t('label.especie'), 'species_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :filterable="false" @search="getSpecies" :options="speciesList" v-model="item.species_id" label="scientific_name" index="species_id"
                          :reduce="r => r.species_id" :clearable="false" class="wf-select vue-select" :class="{ 'is-invalid': $v.item.species_id.$dirty && $v.item.species_id.$error }">
                          <template v-slot:selected-option="option">
                            {{ `${option.scientific_name}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.scientific_name}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                        <div class="invalid-feedback">
                          {{ $t('message.requiredField') }}
                        </div>
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && pendingRelContacts }">
                        <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.colaborador') }}</label>
                        <button v-if="pendingAnalysis && pendingRelContacts" class="btn" type="button" @click="analysis($t('label.colaborador'), 'relContactsSelected')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select multiple :appendToBody="true" :filterable="false" @search="getContacts" :options="contactsList" v-model="relContactsSelected" label="surname" index="contact_id"
                          :reduce="r => r.contact_id" :clearable="false" class="wf-select vue-select" :class="{ 'is-invalid': $v.relContactsSelected.$dirty && $v.relContactsSelected.$error }">
                          <template v-slot:selected-option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.surname}, ${option.name}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                        <div class="invalid-feedback">
                          {{ $t('message.requiredField') }}
                        </div>
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.country_id !== this.item.log_update.Country_id }">
                        <label for="add-ocorrencias-pais" class="form-label">{{ $t('label.pais') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.country_id !== this.item.log_update.Country_id" class="btn" type="button" @click="analysis($t('label.pais'), 'country_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="vocCountryList" v-model="item.country_id" label="value" index="country_id"
                          :reduce="r => r.country_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.state_id !== this.item.log_update.State_id }">
                        <label for="add-ocorrencias-estado" class="form-label">{{ $t('label.estado') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.state_id !== this.item.log_update.State_id" class="btn" type="button" @click="analysis($t('label.estado'), 'state_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="statesList" v-model="item.state_id" label="state" index="state_id"
                          :reduce="r => r.state_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.state}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.state}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.municipio_id !== this.item.log_update.Municipio_id }">
                        <label for="add-ocorrencias-municipio" class="form-label">{{ $t('label.municipio') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.municipio_id !== this.item.log_update.Municipio_id" class="btn" type="button" @click="analysis($t('label.municipio'), 'municipio_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="vocMunicipiosList" v-model="item.municipio_id" label="municipio" index="municipio_id"
                          :reduce="r => r.municipio_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.municipio}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.municipio}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.protected_area !== this.item.log_update.Protected_area }">
                        <label for="add-ocorrencias-area-protegida" class="form-label">{{ $t('label.areaProtegida') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.protected_area !== this.item.log_update.Protected_area" class="btn" type="button" @click="analysis($t('label.areaProtegida'), 'protected_area')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input type="text" class="form-control" id="ocorrencias-area-protegida" v-model="item.protected_area">
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.location !== this.item.log_update.Location }">
                        <label for="add-ocorrencias-ref-local" class="form-label">{{ $t('label.referenciaLocal') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.location !== this.item.log_update.Location" class="btn" type="button" @click="analysis($t('label.referenciaLocal'), 'location')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <textarea id="add-ocorrencias-ref-local" rows="4" class="box-dashboard p-2 textarea_adm form-control" v-model="item.location"></textarea>
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.bibliographic_reference_id !== this.item.log_update.Bibliographic_reference_id }">
                        <label for="add-ocorrencias-referencia" class="form-label">{{ $t('label.referencia') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.bibliographic_reference_id !== this.item.log_update.Bibliographic_reference_id" class="btn" type="button" @click="analysis($t('label.referencia'), 'bibliographic_reference_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="dataBibliographyList" v-model="item.bibliographic_reference_id" label="title" index="bibliography_id"
                          :reduce="lang => lang.bibliography_id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.title}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.title}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.latitude !== this.item.log_update.Latitude }">
                        <label for="add-ocorrencias-grau-latitude" class="form-label">{{ $t('label.latitude') }} (ex. 27 35 06.43 S)</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.latitude !== this.item.log_update.Latitude" class="btn" type="button" @click="analysis($t('label.latitude'), 'latitude')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input type="text" class="form-control" id="add-ocorrencias-grau-latitude" v-model="item.latitude" >
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.longitude !== this.item.log_update.Longitude }">
                        <label for="add-ocorrencias-grau-longitude" class="form-label">{{ $t('label.longitude') }} (ex. 48 32 58 W)</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.longitude !== this.item.log_update.Longitude" class="btn" type="button" @click="analysis($t('label.longitude'), 'longitude')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input type="text" class="form-control" id="add-ocorrencias-grau-longitude" v-model="item.longitude" >
                      </div>
                      <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.coordinates_source !== this.item.log_update.Coordinates_source }">
                        <label for="add-ocorrencias-coordenadas-correspondem" class="form-label">{{ $t('label.coordenadasCorrespondem') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.coordinates_source !== this.item.log_update.Coordinates_source" class="btn" type="button" @click="analysis($t('label.coordenadasCorrespondem'), 'coordinates_source')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="coordinatesSourceList" v-model="item.coordinates_source" label="value" index="id"
                          :reduce="r => r.id" :clearable="false" class="wf-select vue-select" >
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" v-show="item.coordinates_source === 1" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.point_scope !== this.item.log_update.Point_scope }">
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.point_scope !== this.item.log_update.Point_scope" class="btn" type="button" @click="analysis('', 'point_scope')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="pointScopeList" v-model="item.point_scope" label="value" index="id"
                          :reduce="r => r.id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" v-show="item.coordinates_source === 2" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.point !== this.item.log_update.Point }">
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.point !== this.item.log_update.Point" class="btn" type="button" @click="analysis('', 'point')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="pointList" v-model="item.point" label="value" index="id"
                          :reduce="r => r.id" :clearable="false" class="wf-select vue-select">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mt-3" id="dist-aproximada" v-show="item.coordinates_source === 2 && (item.point === 3 || item.point === '3')" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.point_distance !== this.item.log_update.Point_distance }">
                        <label for="add-ocorrencias-distancia-aproximada" class="form-label">{{ $t('label.distanciaAproximada') }}</label>
                        <button v-if="pendingAnalysis && this.item.log_update && this.item.point_distance !== this.item.log_update.Point_distance" class="btn" type="button" @click="analysis($t('label.distanciaAproximada'), 'point_distance')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input type="text" class="form-control" id="dd-ocorrencias-distancia-aproximada" placeholder="Por ex. 50m" v-model="item.point_distance">
                      </div>
                  </div>
                  <div class="col-md-6 border-start">
                    <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.environment_id !== this.item.log_update.Environment_id }">
                      <label for="ocorrencias-ambiente" class="form-label">{{ $t('label.ambiente') }}</label>
                      <button v-if="pendingAnalysis && this.item.log_update && this.item.environment_id !== this.item.log_update.Environment_id" class="btn" type="button" @click="analysis($t('label.ambiente'), 'environment_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <v-select :appendToBody="true" :options="vocEnvironmentsList" v-model="item.environment_id" label="value" index="environment_id"
                        :reduce="r => r.environment_id" :clearable="false" class="wf-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.watershed !== this.item.log_update.Watershed }">
                      <label for="add-ocorrencias-bacia-hidrografica" class="form-label">{{ $t('label.baciaHidro') }}</label>
                      <button v-if="pendingAnalysis && this.item.log_update && this.item.watershed !== this.item.log_update.Watershed" class="btn" type="button" @click="analysis($t('label.baciaHidro'), 'watershed')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input type="text" class="form-control" id="add-ocorrencias-bacia-hidrografica" v-model="item.watershed">
                    </div>
                    <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.invasion_description !== this.item.log_update.Invasion_description }">
                      <label for="add-ocorrencias-descricao-invasao" class="form-label">{{ $t('label.descricaoInvasao') }}</label>
                      <button v-if="pendingAnalysis && this.item.log_update && this.item.invasion_description !== this.item.log_update.Invasion_description" class="btn" type="button" @click="analysis($t('label.descricaoInvasao'), 'invasion_description')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <textarea id="add-ocorrencias-descricao-invasao" rows="4" class="box-dashboard p-2 textarea_adm form-control" v-model="item.invasion_description"></textarea>
                    </div>
                    <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.local_abundance_id !== this.item.log_update.Local_abundance_id }">
                      <label for="add-ocorrencias-abundancia" class="form-label">{{ $t('label.abundancia') }}</label>
                      <button v-if="pendingAnalysis && this.item.log_update && this.item.local_abundance_id !== this.item.log_update.Local_abundance_id" class="btn" type="button" @click="analysis($t('label.abundancia'), 'local_abundance_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <v-select :appendToBody="true" :options="vocLocalAbundanceList" v-model="item.local_abundance_id" label="value" index="local_abundance_id"
                        :reduce="r => r.local_abundance_id" :clearable="false" class="wf-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.population_situation_id !== this.item.log_update.Population_situation_id }">
                      <label for="add-ocorrencias-situacao-populacional" class="form-label">{{ $t('label.situacaoPopulacional') }}</label>
                      <button v-if="pendingAnalysis && this.item.log_update && this.item.population_situation_id !== this.item.log_update.Population_situation_id" class="btn" type="button" @click="analysis($t('label.situacaoPopulacional'), 'population_situation_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <v-select :appendToBody="true" :options="vocPopulationSituationList" v-model="item.population_situation_id" label="value" index="population_situation_id"
                        :reduce="r => r.population_situation_id" :clearable="false" class="wf-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.management_id !== this.item.log_update.Management_id }">
                      <label for="add-ocorrencias-manejo" class="form-label">{{ $t('label.manejo') }}</label>
                      <button v-if="pendingAnalysis && this.item.log_update && this.item.management_id !== this.item.log_update.Management_id" class="btn" type="button" @click="analysis($t('label.manejo'), 'management_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <v-select :appendToBody="true" :options="vocManagementList" v-model="item.management_id" label="value" index="management_id"
                        :reduce="r => r.management_id" :clearable="false" class="wf-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mt-3">
                      <div class="mb-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.entry_date !== this.item.log_update.Entry_date }">
                          <label for="data" class="form-label">{{ $t('label.dataEntrada') }}</label>
                          <button v-if="pendingAnalysis && this.item.log_update && this.item.entry_date !== this.item.log_update.Entry_date" class="btn" type="button" @click="analysis($t('label.dataEntrada'), 'entry_date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </button>
                          <input type="text" class="form-control" v-model="item.entry_date" disabled>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="mb-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.obs_date !== this.item.log_update.Obs_date }">
                          <label for="data" class="form-label">{{ $t('label.dataObservacao') }}</label>
                          <button v-if="pendingAnalysis && this.item.log_update && this.item.obs_date !== this.item.log_update.Obs_date" class="btn" type="button" @click="analysis($t('label.dataObservacao'), 'obs_date')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </button>
                          <input type="text" class="form-control" v-model="item.obs_date" v-mask="['#####', '##/####', '##/##/####']">
                      </div>
                    </div>
                    <div class="mt-5">
                      <label for="data" class="form-label">{{ $t('label.validado') }}</label>
                    </div>
                    <div class="mt-0" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.validated !== this.item.log_update.Validated }">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="0" v-model="item.validated">
                          <label class="form-check-label" for="inlineRadio1">{{ $t('label.sim') }}</label>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="1" v-model="item.validated">
                          <label class="form-check-label" for="inlineRadio2">{{ $t('label.nao') }}</label>
                      </div>
                    </div>
                    <div class="mt-5">
                      <div :class="{ 'pending_analysis': pendingAnalysis && this.is_herbarium_museum !== this.old_is_herbarium_museum }">
                        <input class="form-check-input" type="checkbox" id="especie-museu-herbario" v-model="is_herbarium_museum">
                        <label class="form-check-label" for="inlineCheckbox1">{{ $t('label.especieMuseuHerbario') }}</label>
                      </div>
                      <div class="myClass p-4 rounded-3 border-1 mt-3" id="box-museu-id" v-show="is_herbarium_museum">
                        <div>
                          <div class="" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.is_herbarium !== this.item.log_update.Is_herbarium }">
                            <label for="selecionar-tipo-ocorrencia" class="form-label">{{ $t('label.selecioneTipo') }}</label>
                            <button v-if="pendingAnalysis && this.item.log_update && this.item.is_herbarium !== this.item.log_update.Is_herbarium" class="btn" type="button" @click="analysis('', 'is_herbarium')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                              <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                            <v-select :appendToBody="true" :options="isHerbariumList" v-model="item.is_herbarium" label="value" index="id"
                              :reduce="r => r.id" :clearable="false" class="wf-select vue-select">
                              <template v-slot:selected-option="option">
                                {{ `${option.value}` }}
                              </template>
                              <template v-slot:option="option">
                                {{ `${option.value}` }}
                              </template>
                              <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                  {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                </template>
                              </template>
                            </v-select>
                          </div>
                            <div id="herbario-box">
                              <div  v-show="item.is_herbarium === 'herbarium'" class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.herbarium_id !== this.item.log_update.Herbarium_id }">
                                <label for="add-ocorrencias-herbario" class="form-label">{{ $t('label.herbario') }}</label>
                                <button v-if="pendingAnalysis && this.item.log_update && this.item.herbarium_id !== this.item.log_update.Herbarium_id" class="btn" type="button" @click="analysis($t('label.herbario'), 'herbarium_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                  <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                                <v-select :appendToBody="true" :filterable="false" @search="getVocMuseum" :options="vocMuseumList" v-model="item.herbarium_id" label="value" index="museum_id"
                                  :reduce="r => r.museum_id" :clearable="false" class="wf-select vue-select">
                                  <template v-slot:selected-option="option">
                                    {{ `${option.value}` }}
                                  </template>
                                  <template v-slot:option="option">
                                    {{ `${option.value}` }}
                                  </template>
                                  <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                      {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                    </template>
                                  </template>
                                </v-select>
                              </div>
                              <div v-show="item.is_herbarium === 'museum'" class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.museum !== this.item.log_update.Museum }">
                                <label class="form-label">{{ $t('label.museu') }}</label>
                                <button v-if="pendingAnalysis && this.item.log_update && this.item.museum !== this.item.log_update.Museum" class="btn" type="button" @click="analysis($t('label.museu'), 'museum')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                  <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                                <input type="text" class="form-control" v-model="item.museum">
                              </div>
                              <template v-if="item.is_herbarium">
                                <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.specimen_number !== this.item.log_update.Specimen_number }">
                                  <label for="add-ocorrencias-numero-exemplar" class="form-label">{{ $t('label.numeroExemplaInstituicao') }}</label>
                                  <button v-if="pendingAnalysis && this.item.log_update && this.item.specimen_number !== this.item.log_update.Specimen_number" class="btn" type="button" @click="analysis($t('label.numeroExemplaInstituicao'), 'specimen_number')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                  </button>
                                  <input type="text" class="form-control" id="add-ocorrencias-numero-exemplar" v-model="item.specimen_number">
                                </div>
                                <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.copy_specimen_number_collector !== this.item.log_update.Copy_specimen_number_collector }">
                                  <label for="add-ocorrencias-numero-exemplar-coletor" class="form-label">{{ $t('label.numeroExemplarColetor') }}</label>
                                  <button v-if="pendingAnalysis && this.item.log_update && this.item.copy_specimen_number_collector !== this.item.log_update.Copy_specimen_number_collector" class="btn" type="button" @click="analysis($t('label.numeroExemplarColetor'), 'copy_specimen_number_collector')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                  </button>
                                  <input type="text" class="form-control" id="add-ocorrencias-numero-exemplar-coletor" v-model="item.copy_specimen_number_collector">
                                </div>
                                <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.copy_institution !== this.item.log_update.Copy_institution }">
                                  <label for="add-ocorrencias-instituicao" class="form-label">{{ $t('label.instituicao') }}</label>
                                  <button v-if="pendingAnalysis && this.item.log_update && this.item.copy_institution !== this.item.log_update.Copy_institution" class="btn" type="button" @click="analysis($t('label.instituicao'), 'copy_institution')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                  </button>
                                  <input type="text" class="form-control" id="add-ocorrencias-instituicao" v-model="item.copy_institution">
                                </div>
                                <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.copy_country_id !== this.item.log_update.Copy_country_id }">
                                  <label for="add-ocorrencias-museu-pais" class="form-label">{{ $t('label.pais') }}</label>
                                  <button v-if="pendingAnalysis && this.item.log_update && this.item.copy_country_id !== this.item.log_update.Copy_country_id" class="btn" type="button" @click="analysis($t('label.pais'), 'copy_country_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                  </button>
                                  <v-select :appendToBody="true" :options="vocCountryList" v-model="item.copy_country_id" label="value" index="country_id"
                                    :reduce="r => r.country_id" :clearable="false" class="wf-select vue-select">
                                    <template v-slot:selected-option="option">
                                      {{ `${option.value}` }}
                                    </template>
                                    <template v-slot:option="option">
                                      {{ `${option.value}` }}
                                    </template>
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                      </template>
                                    </template>
                                  </v-select>
                                </div>
                                <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && this.item.log_update && this.item.copy_city !== this.item.log_update.Copy_city }">
                                  <label for="add-ocorrencias-cidade" class="form-label">{{ $t('label.cidade') }}</label>
                                  <button v-if="pendingAnalysis && this.item.log_update && this.item.copy_city !== this.item.log_update.Copy_city" class="btn" type="button" @click="analysis($t('label.cidade'), 'copy_city')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                  </button>
                                  <input type="text" class="form-control" id="add-ocorrencias-cidade" v-model="item.copy_city">
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
    <AdminModalReview :form="item" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import AdminModalReview from '@/components/admin/AdminModalReview'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'OcorrenciasForm',
  components: {
    PageTitle, AdminModalReview
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.ocorrencias')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      item: { is_herbarium: '', coordinates_source: null, point_scope: null, point: null, entry_date: this.$options.filters.formatDate(new Date()) },
      items: [],
      vocDistributionList: [],
      relDistributionSelected: [],
      oldRelDistributionSelected: [],
      contactsList: [],
      relContactsSelected: [],
      oldRelContactsSelected: [],
      vocCountryList: [],
      statesList: [],
      vocMunicipiosList: [],
      vocEnvironmentsList: [],
      vocLocalAbundanceList: [],
      vocPopulationSituationList: [],
      vocManagementList: [],
      speciesList: [],
      pointList: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.pointList1') },
        { id: 2, value: this.$t('label.pointList2') },
        { id: 3, value: this.$t('label.pointList3') }
      ],
      pointScopeList: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.estado') },
        { id: 2, value: this.$t('label.municipioSede') },
        { id: 4, value: this.$t('label.municipioCentroide') },
        { id: 3, value: this.$t('label.areaProtegida') },
        { id: 5, value: this.$t('label.outro') }
      ],
      coordinatesSourceList: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.coordinatesSourceList1') },
        { id: 2, value: this.$t('label.coordinatesSourceList2') }
      ],
      is_herbarium_museum: false,
      old_is_herbarium_museum: false,
      isHerbariumList: [
        { id: 'museum', value: this.$t('label.museu') },
        { id: 'herbarium', value: this.$t('label.herbario') }
      ],
      vocMuseumList: [],
      dataBibliographyList: [],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  validations: {
    item: {
      species_id: { required }
    },
    relContactsSelected: { required }
  },
  watch: {
    'item.is_herbarium' (newValue) {
      if (newValue) {
        this.is_herbarium_museum = (this.item.is_herbarium === 'herbarium' || this.item.is_herbarium === 'museum')
        if (this.item.log_update) {
          this.old_is_herbarium_museum = (this.item.log_update.Is_herbarium === 'herbarium' || this.item.log_update.Is_herbarium === 'museum')
        }
      } else {
        this.is_herbarium_museum = false
        this.old_is_herbarium_museum = false
      }
    },
    'item.contact_id' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getContacts()
      }
    },
    'item.state_id' (newValue, oldValue) {
      if (oldValue !== undefined) {
        this.item.municipio_id = null
      }
      this.getVocMunicipios(newValue)
    },
    'item.coordinates_source' (newValue, oldValue) {
      if (newValue === 3) {
        this.item.coordinates_source = 2
      }
    },
    'item.point' (newValue, oldValue) {
      if (newValue) {
        this.item.point = Number(this.item.point)
      }
    },
    'item.herbarium_id' (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getVocMuseum()
      }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.item.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    },
    pendingRelContacts: function () {
      let result = false
      if (this.relContactsSelected.length !== this.oldRelContactsSelected.length) {
        return true
      }
      this.oldRelContactsSelected.forEach(e => {
        if (!this.relContactsSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  methods: {
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'species_id':
          newValue = this.speciesList.filter(l => l.species_id === this.item.species_id)[0]
          oldValue = this.speciesList.filter(l => l.species_id === this.item.log_update.Species_id)[0]
          if (!newValue) {
            newValue = this.getSpeciesById(this.item.species_id)
          }
          if (!oldValue && this.item.log_update.Species_id) {
            oldValue = this.getSpeciesById(this.item.log_update.Species_id)
          }
          this.modalValuesSimple(newValue ? newValue.scientific_name : '', oldValue ? oldValue.scientific_name : '', label)
          break
        case 'relContactsSelected':
          this.modalValues.label = label
          this.modalValues.isList = true
          this.modalValues.newValue = []
          this.relContactsSelected.forEach(e => {
            newValue = this.contactsList.filter(l => l.contact_id === e)[0]
            if (!newValue) {
              newValue = this.getContactById(e)
            }
            this.modalValues.newValue.push({ id: newValue.contact_id, value: newValue.name })
          })
          this.modalValues.oldValue = []
          this.oldRelContactsSelected.forEach(e => {
            oldValue = this.contactsList.filter(l => l.contact_id === e)[0]
            if (!oldValue) {
              oldValue = this.getContactById(e)
            }
            this.modalValues.oldValue.push({ id: oldValue.contact_id, value: oldValue.name })
          })
          break
        case 'country_id':
          newValue = this.vocCountryList.filter(l => l.country_id === this.item.country_id)[0]
          oldValue = this.vocCountryList.filter(l => l.country_id === this.item.log_update.Country_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'state_id':
          newValue = this.statesList.filter(l => l.state_id === this.item.state_id)[0]
          oldValue = this.statesList.filter(l => l.state_id === this.item.log_update.State_id)[0]
          this.modalValuesSimple(newValue ? newValue.state : '', oldValue ? oldValue.state : '', label)
          break
        case 'municipio_id':
          newValue = this.vocMunicipiosList.filter(l => l.municipio_id === this.item.municipio_id)[0]
          oldValue = this.vocMunicipiosList.filter(l => l.municipio_id === this.item.log_update.Municipio_id)[0]
          this.modalValuesSimple(newValue ? newValue.municipio : '', oldValue ? oldValue.municipio : '', label)
          break
        case 'bibliographic_reference_id':
          newValue = this.dataBibliographyList.filter(l => l.bibliography_id === this.item.bibliographic_reference_id)[0]
          oldValue = this.dataBibliographyList.filter(l => l.bibliography_id === this.item.log_update.Bibliographic_reference_id)[0]
          this.modalValuesSimple(newValue ? newValue.title : '', oldValue ? oldValue.title : '', label)
          break
        case 'coordinates_source':
          newValue = this.coordinatesSourceList.filter(l => l.id === this.item.coordinates_source)[0]
          oldValue = this.coordinatesSourceList.filter(l => l.id === this.item.log_update.Coordinates_source)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'point_scope':
          newValue = this.pointScopeList.filter(l => l.id === this.item.point_scope)[0]
          oldValue = this.pointScopeList.filter(l => l.id === this.item.log_update.Point_scope)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'point':
          newValue = this.pointList.filter(l => l.id === this.item.point)[0]
          oldValue = this.pointList.filter(l => l.id === this.item.log_update.Point)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'environment_id':
          newValue = this.vocEnvironmentsList.filter(l => l.environment_id === this.item.environment_id)[0]
          oldValue = this.vocEnvironmentsList.filter(l => l.environment_id === this.item.log_update.Environment_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'local_abundance_id':
          newValue = this.vocLocalAbundanceList.filter(l => l.local_abundance_id === this.item.local_abundance_id)[0]
          oldValue = this.vocLocalAbundanceList.filter(l => l.local_abundance_id === this.item.log_update.Local_abundance_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'population_situation_id':
          newValue = this.vocPopulationSituationList.filter(l => l.population_situation_id === this.item.population_situation_id)[0]
          oldValue = this.vocPopulationSituationList.filter(l => l.population_situation_id === this.item.log_update.Population_situation_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'management_id':
          newValue = this.vocManagementList.filter(l => l.management_id === this.item.management_id)[0]
          oldValue = this.vocManagementList.filter(l => l.management_id === this.item.log_update.Management_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'is_herbarium':
          newValue = this.isHerbariumList.filter(l => l.id === this.item.is_herbarium)[0]
          oldValue = this.isHerbariumList.filter(l => l.id === this.item.log_update.Is_herbarium)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'herbarium_id':
          newValue = this.vocMuseumList.filter(l => l.museum_id === this.item.herbarium_id)[0]
          oldValue = this.vocMuseumList.filter(l => l.museum_id === this.item.log_update.Herbarium_id)[0]
          if (!newValue) {
            newValue = this.getVocMuseumById(this.item.herbarium_id)
          }
          if (!oldValue && this.item.log_update.Herbarium_id) {
            oldValue = this.getVocMuseumById(this.item.log_update.Herbarium_id)
          }
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'copy_country_id':
          newValue = this.vocCountryList.filter(l => l.country_id === this.item.copy_country_id)[0]
          oldValue = this.vocCountryList.filter(l => l.country_id === this.item.log_update.Copy_country_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'protected_area':
          this.modalValuesSimple(this.item.protected_area, this.item.log_update.Protected_area, label)
          break
        case 'location':
          this.modalValuesSimple(this.item.location, this.item.log_update.Location, label)
          break
        case 'latitude':
          this.modalValuesSimple(this.item.latitude, this.item.log_update.Latitude, label)
          break
        case 'longitude':
          this.modalValuesSimple(this.item.longitude, this.item.log_update.Longitude, label)
          break
        case 'point_distance':
          this.modalValuesSimple(this.item.point_distance, this.item.log_update.Point_distance, label)
          break
        case 'watershed':
          this.modalValuesSimple(this.item.watershed, this.item.log_update.Watershed, label)
          break
        case 'invasion_description':
          this.modalValuesSimple(this.item.invasion_description, this.item.log_update.Invasion_description, label)
          break
        case 'entry_date':
          this.modalValuesSimple(this.item.entry_date, this.item.log_update.Entry_date, label)
          break
        case 'obs_date':
          this.modalValuesSimple(this.item.obs_date, this.item.log_update.Obs_date, label)
          break
        case 'specimen_number':
          this.modalValuesSimple(this.item.specimen_number, this.item.log_update.Specimen_number, label)
          break
        case 'copy_specimen_number_collector':
          this.modalValuesSimple(this.item.copy_specimen_number_collector, this.item.log_update.Copy_specimen_number_collector, label)
          break
        case 'copy_institution':
          this.modalValuesSimple(this.item.copy_institution, this.item.log_update.Copy_institution, label)
          break
        case 'copy_city':
          this.modalValuesSimple(this.item.copy_city, this.item.log_update.Copy_city, label)
          break
        case 'museum':
          this.modalValuesSimple(this.item.museum, this.item.log_update.Museum, label)
          break
      }
    },
    getContactById: async function (id) {
      let contact = {}
      await axios.get(`/Contact/${id}`)
        .then(response => {
          contact = response.data
        })
      return contact
    },
    getSpeciesById: async function (id) {
      let specie = {}
      await axios.get(`/Specie/${id}`)
        .then(response => {
          specie = response.data
        })
      return specie
    },
    getById: async function (id) {
      this.$store.dispatch('showPreload')
      await axios.get(`/DataPlaces/${id}`)
        .then(response => {
          this.item = response.data
          this.item.log_update = JSON.parse(this.item.log_update)
          if (this.item.invasion_description && this.item.invasion_description.length > 100) {
            this.nameEdit = `${this.item.invasion_description.substring(0, 100)}...`
          }
          this.getRelContactsSelected()
          this.getOldRelContactsSelected()
        })
    },
    save: function () {
      this.$v.$touch()
      if (this.$v.item.$invalid || this.$v.relContactsSelected.$invalid) {
        return false
      }
      this.item.relContacts = []
      this.relContactsSelected.forEach(el => {
        this.item.relContacts.push({ contact_id: el, place_id: this.item.place_id })
      })
      this.item.log_update = JSON.stringify(this.item.log_update)
      if (this.action === this.$actionEdit) {
        axios.put('/DataPlaces', this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.item.log_update = JSON.parse(this.item.log_update)
            this.cancel()
          })
      } else {
        axios.post('/DataPlaces', this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/ocorrencias')
      }
    },
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/DataPlaces/${this.item.place_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    getVocDistribution: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocdistribution/getall', { params })
        .then(response => {
          this.vocDistributionList = response.data.items.map(i => {
            const item = {
              distribution_id: i.distribution_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getRelContactsSelected: function () {
      this.relContactsSelected = []
      this.item.relContacts.forEach(e => {
        if (!this.relContactsSelected.includes(e.contact_id)) {
          this.relContactsSelected.push(Number(e.contact_id))
        }
      })
      this.getContacts()
    },
    getOldRelContactsSelected: function () {
      this.oldRelContactsSelected = []
      if (this.item.log_update && this.item.log_update.RelContacts) {
        this.item.log_update.RelContacts.forEach(e => {
          if (!this.oldRelContactsSelected.includes(e.Contact_id)) {
            this.oldRelContactsSelected.push(Number(e.Contact_id))
          }
        })
      }
      this.getContacts()
    },
    getContacts: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.surname = search
        params.contact_id_array = this.relContactsSelected.join(',')
        loading(true)
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.contact_id_array = this.relContactsSelected.join(',')
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items
          })
      }
    },
    getVocCountry: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocCountry/getall', { params })
        .then(response => {
          this.vocCountryList = response.data.items.map(i => {
            const item = {
              country_id: i.country_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getStates: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
        })
    },
    getVocMunicipios: function (stateId) {
      const params = {
        Page: 1,
        PageSize: 9999,
        State_id: stateId
      }
      this.$store.dispatch('showPreload')
      axios.get('/VocMunicipios/getall', { params })
        .then(response => {
          this.vocMunicipiosList = response.data.items
        })
    },
    getVocEnvironments: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocEnvironments/getall', { params })
        .then(response => {
          this.vocEnvironmentsList = response.data.items.map(i => {
            const item = {
              environment_id: i.environment_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocLocalAbundance: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocLocalAbundance/getall', { params })
        .then(response => {
          this.vocLocalAbundanceList = response.data.items.map(i => {
            const item = {
              local_abundance_id: i.local_abundance_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocPopulationSituation: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocPopulationSituation/getall', { params })
        .then(response => {
          this.vocPopulationSituationList = response.data.items.map(i => {
            const item = {
              population_situation_id: i.situation_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocManagement: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocManagement/getall', { params })
        .then(response => {
          this.vocManagementList = response.data.items.map(i => {
            const item = {
              management_id: i.management_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocMuseumById: async function (id) {
      const museum = {}
      await axios.get(`/VocMuseum/${id}`)
        .then(response => {
          museum.museum_id = response.museum_id
          switch (this.$i18n.locale) {
            case 'en':
              museum.value = response.en
              break
            case 'es':
              museum.value = response.es
              break
            case 'pt':
              museum.value = response.pt
              break
          }
        })
      return museum
    },
    getVocMuseum: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999,
        Museum_id: this.item.herbarium_id
      }
      if (search && search.length > 2) {
        switch (this.$i18n.locale) {
          case 'en':
            params.en = search
            break
          case 'es':
            params.es = search
            break
          case 'pt':
            params.pt = search
            break
        }
        loading(true)
        axios.get('/VocMuseum/getallcombo', { params })
          .then(response => {
            this.vocMuseumList = response.data.items.map(i => {
              const item = {
                museum_id: i.museum_id,
                value: ''
              }
              switch (this.$i18n.locale) {
                case 'en':
                  item.value = i.en
                  break
                case 'es':
                  item.value = i.es
                  break
                case 'pt':
                  item.value = i.pt
                  break
              }
              return item
            })
            loading(false)
          })
      } else if (!search) {
        axios.get('/VocMuseum/getallcombo', { params })
          .then(response => {
            this.vocMuseumList = response.data.items.map(i => {
              const item = {
                museum_id: i.museum_id,
                value: ''
              }
              switch (this.$i18n.locale) {
                case 'en':
                  item.value = i.en
                  break
                case 'es':
                  item.value = i.es
                  break
                case 'pt':
                  item.value = i.pt
                  break
              }
              return item
            })
          })
      }
    },
    getDataBibliography: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/bibliography/getallcombo', { params })
        .then(response => {
          this.dataBibliographyList = response.data.items
        })
    },
    getSpecies: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.scientific_name = search
        params.species_id_array = this.item.species_id
        loading(true)
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.species_id_array = this.item.species_id
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
          })
      }
    }
  },
  created: async function () {
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      this.pageTitle = `${this.$t('label.ocorrencias')} > ${this.$t('label.editando')}:`
      await this.getById(this.$route.params.id)
    }
    this.getSpecies()
    this.getVocDistribution()
    this.getContacts()
    this.getVocCountry()
    this.getStates()
    this.getVocEnvironments()
    this.getVocLocalAbundance()
    this.getVocPopulationSituation()
    this.getVocManagement()
    this.getVocMuseum()
    this.getDataBibliography()
  }
}
</script>
