<template>
  <div>
    <InstitucionalHeaderSingle :classProp="'header-single header-contato'" :title="`${$t('contatoInstitucional.cabecalhoTitulo') }`"/>
    <div class="contato">
      <div class="container ">
        <div class="row cadastro-altura align-items-center justify-content-md-center">
          <div class="col-md-7 align-middle py-5 ">
            <h1 class="laranja">{{ $t('contatoInstitucional.contato') }}</h1>
            <p>{{ $t('contatoInstitucional.p1') }}</p>
            <form class="mt-5 col-md-12">
              <div class="row">
                <div class="mb-3 col-md-12">
                  <label for="CadastroNome" class="form-label">{{ $t('contatoInstitucional.nome') }}</label>
                  <input type="text" class="form-control" id="CadastroNome" v-model="form.name" :class="{ 'is-invalid': $v.form.name.$dirty && $v.form.name.$error }">
                  <div class="invalid-feedback">
                    {{ $t('message.requiredField') }}
                  </div>
                </div>
                <div class="mb-3 col-md-12">
                  <label for="CadastroEmail" class="form-label">{{ $t('contatoInstitucional.email') }}</label>
                  <input type="email" class="form-control" id="CadastroEmail" v-model="form.email" :class="{ 'is-invalid': $v.form.email.$dirty && $v.form.email.$error }">
                  <div class="invalid-feedback">
                    {{ $t('message.requiredField') }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-md-12">
                  <label for="Instituicao" class="form-label">{{ $t('contatoInstitucional.instituicao') }}</label>
                  <input type="text" class="form-control" id="instituicao" v-model="form.institution" >
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-md-12">
                  <label for="Mensagem" class="form-label">{{ $t('contatoInstitucional.mensagem') }}</label>
                  <textarea class="form-control" id="mensagem" v-model="form.message" :class="{ 'is-invalid': $v.form.message.$dirty && $v.form.message.$error }"></textarea>
                  <div class="invalid-feedback">
                    {{ $t('message.requiredField') }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-12">
                  <button type="button" class="btn btn-primary btn-1-horus mt-4" @click="save">{{ $t('contatoInstitucional.enviar') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
  name: 'ContatoInstitucional',
  components: { InstitucionalHeaderSingle },
  data: function () {
    return {
      form: {}
    }
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      message: { required }
    }
  },
  methods: {
    save: function () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      axios.post('/InstitutionalContact', this.form)
        .then(() => {
          this.$toasted.global.saved({ msg: this.$t('label.mensagemEnviada') })
        })
    }
  }
}
</script>
