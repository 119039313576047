<template>
  <div class="container-fluid p-0">
    <PageTitle v-if="withPageTitle" :title="`${ $t('label.referencias') }`" :titleAdd="`${ $t('label.addReferencia') }`" :isAdd="true"/>
    <!-- filtro -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body p-0 box-dashboard">
            <div class="align-self-center">
              <form class="filtro_especie" @submit.prevent="search">
                <div class="row g-3 p-3 pb-0" v-if="!byInstitucional">
                  <div class="col-12 text-right">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearch.pending_analysis">
                      <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
                    </div>
                  </div>
                </div>
                <div class="row g-3 p-3 pb-0">
                  <div class="col-12 col-lg-4">
                    <input type="text" class="form-control bg-laranja" :placeholder="$t('label.nomeCientifico')" v-model="$store.state.formSearch.scientific_name"/>
                  </div>
                  <div class="col-12 col-lg-3">
                    <input type="text" class="form-control bg-laranja" :placeholder="$t('label.titulo')" v-model="$store.state.formSearch.title"/>
                  </div>
                  <div class="col-12 col-lg-3">
                    <input type="text" class="form-control bg-laranja" :placeholder="$t('label.autores')" v-model="$store.state.formSearch.author"/>
                  </div>

                  <div class="col-12 col-lg-2 ml-auto text-right pt-1">
                    <button type="button" @click="clear" class="btn btn-outline-secondary mt-1 mb-2 mr-1">
                      {{ $t('label.limpar') }}
                    </button>
                    <button type="submit" class="btn bt-filtrar mt-1 mb-2">
                      {{ $t('label.buscar') }}
                    </button>
                  </div>
                </div>
              </form>

              <div class="row mt-1 mt-xl-2 p-3 bg_table_header">
                <div class="col-auto d-none d-sm-block">
                  <p class="mb-0 mt-2">
                    {{ $t('label.numRegistrosEncontrados') }}
                    <span class="laranja">{{$store.state.countList}}</span>
                  </p>
                </div>

                <div class="col-auto ml-auto text-right select_registro">
                  <select class="selectpicker" ref="select" v-model="$store.state.perPage" @change="search">
                    <option selected value="10">{{ $t('label.exibir10Reg') }}</option>
                    <option value="25">{{ $t('label.exibir25Reg') }}</option>
                    <option value="50">{{ $t('label.exibir50Reg') }}</option>
                    <option value="100">{{ $t('label.exibir100Reg') }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /filtro -->

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <ReferenciasTable @orderBy="orderBy" @search="search" :items="items" :withOrderBy="withOrderBy" :byInstitucional="byInstitucional" @actionByInstitucional="actionByInstitucional"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import ReferenciasTable from './ReferenciasTable.vue'
export default {
  name: 'Referencias',
  components: { PageTitle, ReferenciasTable },
  props: {
    byInstitucional: {
      type: Boolean,
      default: false
    },
    withPageTitle: {
      type: Boolean,
      default: true
    },
    withOrderBy: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      items: [],
      dataOrderBy: {}
    }
  },
  methods: {
    clear: function () {
      this.$store.commit('clearFormSearch')
    },
    orderBy: function (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy: function () {
      this.$store.state.formSearch.OrderByTitleAcs = this.$store.state.dataOrderBy.OrderByTitleAcs
      this.$store.state.formSearch.OrderByTitleDesc = this.$store.state.dataOrderBy.OrderByTitleDesc
      this.$store.state.formSearch.OrderByAuthorAcs = this.$store.state.dataOrderBy.OrderByAuthorAcs
      this.$store.state.formSearch.OrderByAuthorDesc = this.$store.state.dataOrderBy.OrderByAuthorDesc
      this.$store.state.formSearch.OrderByIDAcs = this.$store.state.dataOrderBy.OrderByIDAcs
      this.$store.state.formSearch.OrderByIDDesc = this.$store.state.dataOrderBy.OrderByIDDesc
    },
    search: function () {
      this.$store.dispatch('showPreload')

      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      axios.get('/Bibliography/getall', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
        })
    },
    actionByInstitucional: function (item) {
      this.$emit('actionByInstitucional', item)
    }
  },
  created: function () {
    this.$store.state.countList = 0
    this.search()
  }
}
</script>
