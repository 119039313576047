<template>
  <div class="tab-pane fade" :class="{ 'show active': aba===6 }" id="analiserisco" role="tabpanel" aria-labelledby="analiserisco-tab">
    <div class="container-fluid  mt-3">
      <!-- tabela -->
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <div class="mb-3">
                  <div class="d-flex">
                    <div>
                      <label for="analise-riskAnalysis" class="form-label">{{ $t('label.resultadosAnalisesRisco') }}</label>
                    </div>
                    <div class="ml-auto text-right" v-if="!byInstitucional">
                      <a class="bt-add-registro" @click="add">{{ $t('label.adicionar') }}</a>
                    </div>
                  </div>
                </div>
                <div class="card-body box-dashboard p-0 mb-3">
                  <div class="align-self-center">
                    <div class="table-responsive full_table mt-0 ">
                      <table class="table caption-top">
                        <thead class="bg_table_header">
                          <tr>
                            <th class="col-md-1 py-3 pl-4">{{ $t('label.data') }}</th>
                            <th class="col-md-2 py-3 pl-4">{{ $t('label.confiabilidade') }}</th>
                            <th class="col-md-2 py-3 pl-4">{{ $t('label.risco') }}</th>
                            <th class="col-md-4 py-3 px-2">{{ $t('label.descricao') }}</th>
                            <th class="col-md-1 py-3  px-2 pr-4 text-right">{{ !byInstitucional ? $t('label.acoes') : '' }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="addRiskAnalysis || editRiskAnalysis" key="add=edit">
                            <td colspan="5">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="mb-3">
                                      <label for="analise-data" class="form-label">{{ $t('label.data') }} (ex. 2021)</label>
                                      <input v-mask="'####'" v-model="newRiskAnalysis.risk_analysis_date" class="form-control" id="analise-data" :disabled="byInstitucional">
                                  </div>
                                  <div class="mb-3 pt-2" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_confidence_id !== newRiskAnalysis.log_update.Risk_analysis_confidence_id }">
                                      <label for="analise-confiabilidade" class="form-label">{{ $t('label.confiabilidade') }}</label>
                                      <button v-if="!byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_confidence_id !== newRiskAnalysis.log_update.Risk_analysis_confidence_id" class="btn" type="button" @click="$emit('analysis', $t('label.confiabilidade'), 'risk_analysis_confidence_id', confidenceList)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                      </button>
                                      <v-select :appendToBody="true" :options="confidenceList" v-model="newRiskAnalysis.risk_analysis_confidence_id" label="valor" index="confidence_id"
                                        :reduce="lang => lang.confidence_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                                        <template v-slot:selected-option="option">
                                          {{ `${option.valor}` }}
                                        </template>
                                        <template v-slot:option="option">
                                          {{ `${option.valor}` }}
                                        </template>
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                          </template>
                                        </template>
                                      </v-select>
                                  </div>
                                  <div class="mb-3 pt-2" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_comments !== newRiskAnalysis.log_update.Risk_analysis_comments }">
                                      <label for="analise-comentarios" class="form-label">{{ $t('label.comentarios') }}</label>
                                      <button v-if="!byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_comments !== newRiskAnalysis.log_update.Risk_analysis_comments" class="btn" type="button" @click="$emit('analysis', $t('label.comentarios'), 'risk_analysis_comments')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                      </button>
                                      <textarea v-model="newRiskAnalysis.risk_analysis_comments" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                                  </div>
                                  <div class="mb-3 pt-2" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_bibliography_id !== newRiskAnalysis.log_update.Risk_analysis_bibliography_id }">
                                    <label for="analise-referencia" class="form-label">{{ $t('label.referencia') }}</label>
                                    <button v-if="!byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_bibliography_id !== newRiskAnalysis.log_update.Risk_analysis_bibliography_id" class="btn" type="button" @click="$emit('analysis', $t('label.referencia'), 'risk_analysis_bibliography_id', dataBibliographyList)" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                      <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                    <v-select :appendToBody="true" :options="dataBibliographyList" v-model="newRiskAnalysis.risk_analysis_bibliography_id" label="title" index="bibliography_id"
                                      :reduce="lang => lang.bibliography_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                                      <template v-slot:selected-option="option">
                                        {{ `${option.title}` }}
                                      </template>
                                      <template v-slot:option="option">
                                        {{ `${option.title}` }}
                                      </template>
                                      <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                                        </template>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>
                                <div class="col-md-6  border-left">
                                  <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_url !== newRiskAnalysis.log_update.Risk_analysis_url }">
                                    <label for="risk_analysis_url" class="form-label">{{ $t('label.url') }}</label>
                                    <button v-if="!byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_analysis_url !== newRiskAnalysis.log_update.Risk_analysis_url" class="btn" type="button" @click="$emit('analysis', $t('label.url'), 'risk_analysis_url')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                      <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                    <input v-model="newRiskAnalysis.risk_analysis_url" type="text" class="form-control" :disabled="byInstitucional">
                                  </div>
                                  <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.descricao !== newRiskAnalysis.log_update.Descricao }">
                                    <label for="descricao" class="form-label">{{ $t('label.descricao') }}</label>
                                    <button v-if="!byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.descricao !== newRiskAnalysis.log_update.Descricao" class="btn" type="button" @click="$emit('analysis', $t('label.descricao'), 'descricao')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                      <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                    <textarea v-model="newRiskAnalysis.description" rows="3" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                                  </div>
                                  <div class="mb-3" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_type !== newRiskAnalysis.log_update.Risk_type }">
                                    <div>
                                      <label for="risk_type" class="form-label">{{ $t('label.risco') }}</label>
                                      <button v-if="!byInstitucional && pendingAnalysis && newRiskAnalysis.log_update && newRiskAnalysis.risk_type !== newRiskAnalysis.log_update.Risk_type" class="btn" type="button" @click="$emit('analysis', $t('label.risco'), 'risk_type')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                    <div class="mt-2">
                                      <div class="form-check form-check-inline" v-for="item in riskanalysisList" :key="item.risk_type">
                                        <input v-model="newRiskAnalysis.risk_type" class="form-check-input" type="radio" :value="item.risk_type" :disabled="byInstitucional">
                                        <label class="form-check-label">{{item.description}}</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 text-right">
                                  <div class="mb-3">
                                    <a class="bt-add-registro" @click="salve" v-if="!byInstitucional">{{ $t('label.salvar') }}</a>
                                    <a class="bt-add-registro bg-danger ml-1" @click="cancel">{{ $t('label.cancelar') }}</a>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="form.riskAnalysis && form.riskAnalysis.length === 0"><td colspan="5" class="text-center">{{ $t('message.noRecords') }}</td></tr>
                          <tr v-else v-for="item in form.riskAnalysis" :key="item.risk_analysis_id" v-show="!addRiskAnalysis && !editRiskAnalysis" :class="{ 'pending_analysis': checkRiskAnalysis(item) }">
                            <td class="py-3 pl-4 pr-2">{{item.risk_analysis_date}}</td>
                            <td class="py-3 pl-4 pr-2">{{getConfidence(item.risk_analysis_confidence_id)}}</td>
                            <td class="py-3 pl-4 pr-2">{{getRiskType(item.risk_type)}}</td>
                            <td class="py-3  px-2">{{item.description}}</td>
                            <td class="py-3 px-2 ">
                              <ul class="acoes text-right pr-1" v-if="!byInstitucional">
                                <li>
                                  <a @click="edit(item)"><span class="editar"></span></a>
                                </li>
                                <li>
                                  <a @click="remove(item)"><span class="apagar"></span></a>
                                </li>
                              </ul>
                              <ul class="acoes text-right pr-1" v-else>
                                <li>
                                  <a @click="edit(item)"><span class="visualizar"></span></a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EspeciesTabAnaliseDeRisco',
  props: {
    byInstitucional: Boolean,
    form: {},
    aba: Number,
    loadValidatorAnaliseDeRisco: Boolean,
    onlyLoadValidator: Boolean,
    dataBibliographyList: Array,
    pendingAnalysis: Boolean
  },
  data: function () {
    return {
      confidenceList: [],
      riskanalysisList: [
        { risk_type: 1, description: this.$i18n.locale === 'pt' ? 'Alto' : this.$i18n.locale === 'en' ? 'High' : 'Alto' },
        { risk_type: 2, description: this.$i18n.locale === 'pt' ? 'Moderado' : this.$i18n.locale === 'en' ? 'Moderate' : 'Moderado' },
        { risk_type: 3, description: this.$i18n.locale === 'pt' ? 'Baixo' : this.$i18n.locale === 'en' ? 'Low' : 'Bajo' },
        { risk_type: 4, description: this.$i18n.locale === 'pt' ? 'Incerto' : this.$i18n.locale === 'en' ? 'Uncertain' : 'Incerto' }
      ],
      addRiskAnalysis: false,
      editRiskAnalysis: false,
      newRiskAnalysis: {
        description: '',
        log_update: {},
        risk_analysis_bibliography_id: null,
        risk_analysis_comments: '',
        risk_analysis_confidence_id: null,
        risk_analysis_date: null,
        risk_analysis_id: this.form.riskAnalysis ? new Date().getTime() : 999,
        risk_type: 1,
        species_id: this.form.species_id
      },
      editing: -1,
      riskAnalysisOld: {}
    }
  },
  validations: {
    form: {}
  },
  watch: {
    'loadValidatorAnaliseDeRisco' (newValue) {
      if (newValue) {
        this.$v.$touch()
        this.$emit(this.onlyLoadValidator ? 'continueSetAba' : 'continueSave', this.$v)
      }
    },
    'aba' () {
      if (this.aba === 6) {
        if (this.confidenceList.length === 0) {
          this.getVocConfidence()
        }
      }
    }
  },
  methods: {
    checkRiskAnalysis: function (obj) {
      if (this.form.log_update && this.form.log_update.RiskAnalysis) {
        const item = this.form.log_update.RiskAnalysis.filter(f =>
          f.Description === obj.description &&
          f.Risk_analysis_bibliography_id === obj.risk_analysis_bibliography_id &&
          f.Risk_analysis_comments === obj.risk_analysis_comments &&
          f.Risk_analysis_confidence_id === obj.risk_analysis_confidence_id &&
          f.Risk_analysis_date === obj.risk_analysis_date &&
          f.Risk_analysis_url === obj.risk_analysis_url &&
          f.Risk_type === obj.risk_type &&
          f.Species_id === obj.species_id
        )
        const result = !this.byInstitucional && this.pendingAnalysis && item.length === 0
        if (result) {
          this.$emit('especiesTabAnaliseDeRiscoPending', result)
        }
        return result
      }
      return false
    },
    getRiskType: function (riskType) {
      const risk = this.riskanalysisList.filter(l => {
        return l.risk_type === riskType
      })
      return risk && risk.length > 0 ? risk[0].description : ''
    },
    getConfidence: function (confidenceId) {
      const confidence = this.confidenceList.filter(l => {
        return l.confidence_id === confidenceId
      })
      return confidence && confidence.length > 0 ? confidence[0].valor : ''
    },
    remove: function (item) {
      if (confirm(this.$t('message.confirmDel'))) {
        this.form.riskAnalysis = this.form.riskAnalysis.filter(f => f.risk_analysis_id !== item.risk_analysis_id)
      }
    },
    add: function () {
      this.addRiskAnalysis = true
      this.editRiskAnalysis = false
      this.newRiskAnalysis = {
        description: '',
        log_update: {},
        risk_analysis_bibliography_id: null,
        risk_analysis_comments: '',
        risk_analysis_confidence_id: null,
        risk_analysis_date: null,
        risk_analysis_id: this.form.riskAnalysis ? new Date().getTime() : 999,
        risk_type: 1,
        species_id: this.form.species_id
      }
    },
    edit: function (item) {
      this.addRiskAnalysis = false
      this.editRiskAnalysis = true
      item.risk_analysis_date = item.risk_analysis_date ? item.risk_analysis_date.split('T')[0] : null

      this.riskAnalysisOld = {
        description: item.description,
        log_update: item.log_update,
        risk_analysis_bibliography_id: item.risk_analysis_bibliography_id,
        risk_analysis_comments: item.risk_analysis_comments,
        risk_analysis_confidence_id: item.risk_analysis_confidence_id,
        risk_analysis_date: item.risk_analysis_date,
        risk_analysis_id: item.risk_analysis_id,
        risk_type: item.risk_type,
        species_id: item.species_id
      }
      this.newRiskAnalysis = item
      this.editing = item.risk_analysis_id
    },
    cancel: function () {
      this.addRiskAnalysis = false
      this.editRiskAnalysis = false
      this.editing = ''
      this.form.riskAnalysis.forEach(i => {
        if (i.risk_analysis_id === this.newRiskAnalysis.risk_analysis_id) {
          i.description = this.riskAnalysisOld.description
          i.log_update = this.riskAnalysisOld.log_update
          i.risk_analysis_bibliography_id = this.riskAnalysisOld.risk_analysis_bibliography_id
          i.risk_analysis_comments = this.riskAnalysisOld.risk_analysis_comments
          i.risk_analysis_confidence_id = this.riskAnalysisOld.risk_analysis_confidence_id
          i.risk_analysis_date = this.riskAnalysisOld.risk_analysis_date
          i.risk_analysis_id = this.riskAnalysisOld.risk_analysis_id
          i.risk_type = this.riskAnalysisOld.risk_type
          i.species_id = this.riskAnalysisOld.species_id
        }
      })
    },
    salve: function () {
      if (this.addRiskAnalysis) {
        this.form.riskAnalysis.push(this.newRiskAnalysis)
        this.newRiskAnalysis = {
          description: '',
          log_update: {},
          risk_analysis_bibliography_id: null,
          risk_analysis_comments: '',
          risk_analysis_confidence_id: null,
          risk_analysis_date: null,
          risk_analysis_id: this.form.riskAnalysis ? new Date().getTime() : 999,
          risk_type: 1,
          species_id: this.form.species_id
        }
      }
      this.addRiskAnalysis = false
      this.editRiskAnalysis = false
      this.editing = ''
    },
    getVocConfidence: function () {
      this.$store.dispatch('showPreload')
      axios.get('/vocconfidence/getall')
        .then(response => {
          this.confidenceList = response.data.items.map(i => {
            const item = {
              confidence_id: i.confidence_id,
              valor: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.valor = i.en
                break
              case 'es':
                item.valor = i.es
                break
              case 'pt':
                item.valor = i.pt
                break
            }
            return item
          })
        })
    }
  }
}
</script>
