<template>
  <div>
    <div class="header-single header-colabore">
      <div class="container  h-100">
        <div class="row align-items-end  h-100">
          <div class="col-sm ">
            <h1 class="align-bottom">{{ $t('colaboradoresInstitucional.titulo') }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <div class="row mb-4">
        <div class="col-md-4 p-4">
          <p>{{ $t('colaboradoresInstitucional.paragrafo1') }}</p>
        </div>
        <div class="col-md-4 p-4">
          <p>{{ $t('colaboradoresInstitucional.paragrafo2') }}</p>
        </div>
        <div class="col-md-4 p-0">
          <div class="p-3 rounded-5 box-citacao">
            <p>{{ $t('colaboradoresInstitucional.paragrafo3') }}</p>
            <p class="mb-0">{{ $t('colaboradoresInstitucional.paragrafo4_1') }}</p>
            </div>
        </div>
      </div>
      <ColaboradoresForm v-if="viewItem" :idItem="colaborador.contact_id" :viewItem="true" :byInstitucional="true"/>
      <Colaboradores v-else :withPageTitle="false" :withOrderBy="true" :byInstitucional="true" @actionByInstitucional="actionByInstitucional"/>
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto ml-auto text-right mt-n1">
          <button v-if="viewItem" @click="viewItem=false" class="btn bt-filtrar mt-1 mb-2">
            {{ $t('label.exibirListagem') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'driver.js/dist/driver.min.css'
import Colaboradores from '../../admin/colaboradores/Colaboradores.vue'
import ColaboradoresForm from '../../admin/colaboradores/ColaboradoresForm.vue'
export default {
  name: 'ColaboradoresInstitucional',
  components: { Colaboradores, ColaboradoresForm },
  data () {
    return {
      driver: null,
      viewItem: false,
      colaborador: {}
    }
  },
  methods: {
    actionByInstitucional: function (item) {
      this.colaborador = item
      this.viewItem = true
    }
  },
  created () {
    this.viewItem = false
  }
}
</script>
