import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'
import CripVueLoading from 'crip-vue-loading'
import i18n from './i18n'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || 'https://localhost:44367/api'

Vue.use(CripVueLoading, {
  axios,
  color: '#007bff',
  failColor: '#dc3545',
  height: '3px'
})

const success = res => {
  store.dispatch('hidePreload')
  return res
}
const error = err => {
  store.dispatch('hidePreload')
  if (!err.response || err.response.status === 500) {
    console.log('Unavailable service')
  } else {
    if (err.response.status === 401 || err.response.status === 403) {
      Vue.toasted.global.defaultError({ msg: 'Unauthorized' })
      if (router.currentRoute.path !== '/login') {
        store.commit('setUser', null)
        window.location = '/login'
      }
    } else {
      Vue.toasted.global.defaultError({ msg: err.response.data })
      return Promise.reject(err)
    }
  }
}

axios.interceptors.response.use(success, error)

axios.interceptors.request.use(async (config) => {
  config.headers['Content-Type'] = 'application/json'
  if (store.state.user && store.state.user.token) {
    config.headers.Authorization = `Bearer ${store.state.user.token}`
    if (config.method === 'get') {
      config.headers['Content-Language'] = i18n.locale
    }
  }
  return config
})
