<template>
  <div class="container-fluid p-0">
    <PageTitle v-if="!byInstitucional" :title="pageTitle" :titleSave="pendingAnalysis ? $t('label.aprovarColaborador') : $t('label.salvarColaborador')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit" :pendingAnalysis="pendingAnalysis"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
                <div class="align-self-center">
                  <div class="row">
                    <div class="" :class="byInstitucional ? 'col-md-12' : 'col-md-6'">
                      <div class="row">
                        <div class="mb-3 col-md-4" :class="{ 'pending_analysis': !byInstitucional && pendingAnalysis && this.form.name !== this.form.log_update.Name }">
                          <label for="colaboradores-nome" class="form-label">{{ $t('label.nome') }}</label>
                          <button v-if="!byInstitucional && pendingAnalysis && this.form.name !== this.form.log_update.Name" class="btn" type="button" @click="analysis($t('label.nome'), 'name')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </button>
                          <input class="form-control" id="colaboradores-nome" type="text" v-model="form.name" :class="{ 'is-invalid': $v.form.name.$dirty && $v.form.name.$error }" :disabled="byInstitucional">
                          <div class="invalid-feedback">
                            {{ $t('message.requiredField') }}
                          </div>
                        </div>
                        <div class="mb-3  col-md-8" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.surname !== this.form.log_update.Surname }">
                          <label for="colaboradores-sobrenome" class="form-label">{{ $t('label.sobrenome') }}</label>
                          <button v-if="!byInstitucional && pendingAnalysis && this.form.surname !== this.form.log_update.Surname" class="btn" type="button" @click="analysis($t('label.sobrenome'), 'surname')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </button>
                          <input class="form-control" id="colaboradores-sobrenome" type="text" v-model="form.surname" :disabled="byInstitucional">
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3 col-md-6" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.title !== this.form.log_update.Title }">
                        <label for="colaboradores-titulo" class="form-label">{{ $t('label.titulo') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.title !== this.form.log_update.Title" class="btn" type="button" @click="analysis($t('label.titulo'), 'title')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-titulo" v-model="form.title" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3 col-md-6" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.office !== this.form.log_update.Office }">
                        <label for="colaboradores-cargo" class="form-label">{{ $t('label.cargo') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.office !== this.form.log_update.Office" class="btn" type="button" @click="analysis($t('label.cargo'), 'office')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-cargo" v-model="form.office" :disabled="byInstitucional">
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-3 col-md-6" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.specialty_id !== this.form.log_update.Specialty_id }">
                        <label for="colaboradores-especialidade" class="form-label">{{ $t('label.especialidade') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.specialty_id !== this.form.log_update.Specialty_id" class="btn" type="button" @click="analysis($t('label.especialidade'), 'specialty_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <v-select :appendToBody="true" :options="vocSpecialtiesList" v-model="form.specialty_id" label="value" index="specialty_id"
                          :reduce="r => r.specialty_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                          <template v-slot:selected-option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:option="option">
                            {{ `${option.value}` }}
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                            </template>
                          </template>
                        </v-select>
                      </div>
                      <div class="mb-3 col-md-6" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.institution !== this.form.log_update.Institution }">
                        <label for="colaboradores-instituicao" class="form-label">{{ $t('label.instituicao') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.institution !== this.form.log_update.Institution" class="btn" type="button" @click="analysis($t('label.instituicao'), 'institution')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-instituicao" v-model="form.institution" :disabled="byInstitucional">
                      </div>
                    </div>
                    <div v-if="!byInstitucional" class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.address !== this.form.log_update.Address }">
                      <label for="colaboradores-endereco" class="form-label">{{ $t('label.endereco') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.address !== this.form.log_update.Address" class="btn" type="button" @click="analysis($t('label.endereco'), 'address')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input class="form-control" type="text" id="colaboradores-endereco" v-model="form.address" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.state_id !== this.form.log_update.State_id }">
                      <label for="colaboradores-estado" class="form-label">{{ $t('label.estado') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.state_id !== this.form.log_update.State_id" class="btn" type="button" @click="analysis($t('label.estado'), 'state_id')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <v-select :appendToBody="true" :options="statesList" v-model="form.state_id" label="state" index="state_id"
                        :reduce="r => r.state_id" :clearable="false" class="wf-select vue-select" :disabled="byInstitucional">
                        <template v-slot:selected-option="option">
                          {{ `${option.state}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.state}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.city !== this.form.log_update.City }">
                      <label for="colaboradores-cidade" class="form-label">{{ $t('label.cidade') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.city !== this.form.log_update.City" class="btn" type="button" @click="analysis($t('label.cidade'), 'city')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input class="form-control" type="text" id="colaboradores-cidade" v-model="form.city" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.country !== this.form.log_update.Country }">
                      <label for="colaboradores-pais" class="form-label">{{ $t('label.pais') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.country !== this.form.log_update.Country" class="btn" type="button" @click="analysis($t('label.pais'), 'country')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input class="form-control" type="text" id="colaboradores-pais" v-model="form.country" :disabled="byInstitucional">
                    </div>
                    <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.postcode !== this.form.log_update.Postcode }">
                      <label for="colaboradores-cep" class="form-label">{{ $t('label.cep') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.postcode !== this.form.log_update.Postcode" class="btn" type="button" @click="analysis($t('label.cep'), 'postcode')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input class="form-control" type="text" id="colaboradores-dep" v-model="form.postcode" :disabled="byInstitucional">
                    </div>
                  </div>
                    <div class="col-md-6 border-start" v-if="!byInstitucional">
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.email !== this.form.log_update.Email }">
                      <label for="colaboradores-email" class="form-label">{{ $t('label.email') }}</label>
                      <button v-if="!byInstitucional && pendingAnalysis && this.form.email !== this.form.log_update.Email" class="btn" type="button" @click="analysis($t('label.email'), 'email')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input class="form-control" type="email" id="colaboradores-email" v-model="form.email" :disabled="byInstitucional">
                    </div>
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.phone !== this.form.log_update.Phone }">
                        <label for="colaboradores-telefone" class="form-label">{{ $t('label.telefone') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.phone !== this.form.log_update.Phone" class="btn" type="button" @click="analysis($t('label.telefone'), 'phone')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-telefone" v-model="form.phone" v-mask="['(##) ####-####', '(##) #####-####']" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.phone !== this.form.log_update.Phone }">
                        <label for="colaboradores-telefone2" class="form-label">{{ $t('label.telefone2') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.phone2 !== this.form.log_update.Phone2" class="btn" type="button" @click="analysis($t('label.telefone2'), 'phone2')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-telefone2" v-model="form.phone2" v-mask="['(##) ####-####', '(##) #####-####']" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.url !== this.form.log_update.Url }">
                        <label for="colaboradores-website" class="form-label">{{ $t('label.websiteURL') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.url !== this.form.log_update.Url" class="btn" type="button" @click="analysis($t('label.websiteURL'), 'url')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-website" v-model="form.url" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.social_media_1 !== this.form.log_update.Social_media_1 }">
                        <label for="colaboradores-midia-social" class="form-label">{{ $t('label.midiaSocial1') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.social_media_1 !== this.form.log_update.Social_media_1" class="btn" type="button" @click="analysis($t('label.midiaSocial1'), 'social_media_1')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-midia-social" v-model="form.social_media_1" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.social_media_2 !== this.form.log_update.Social_media_2 }">
                        <label for="colaboradores-midia-social2" class="form-label">{{ $t('label.midiaSocial2') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.social_media_2 !== this.form.log_update.Social_media_2" class="btn" type="button" @click="analysis($t('label.midiaSocial2'), 'social_media_2')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-midia-social2" v-model="form.social_media_2" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.social_media_3 !== this.form.log_update.Social_media_3 }">
                        <label for="colaboradores-midia-social3" class="form-label">{{ $t('label.midiaSocial3') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.social_media_3 !== this.form.log_update.Social_media_3" class="btn" type="button" @click="analysis($t('label.midiaSocial3'), 'social_media_3')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <input class="form-control" type="text" id="colaboradores-midia-social3" v-model="form.social_media_3" :disabled="byInstitucional">
                      </div>
                      <div class="mb-3" :class="{ 'pending_analysis':!byInstitucional && pendingAnalysis && this.form.additional_notes !== this.form.log_update.Additional_notes }">
                        <label for="colaboradores-observacoes-adicionais" class="form-label">{{ $t('label.observacoesAdicionais') }}</label>
                        <button v-if="!byInstitucional && pendingAnalysis && this.form.additional_notes !== this.form.log_update.Additional_notes" class="btn" type="button" @click="analysis($t('label.observacoesAdicionais'), 'additional_notes')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <textarea v-model="form.additional_notes" id="colaboradores-observacoes-adicionais" rows="4" class="box-dashboard p-2 textarea_adm form-control" :disabled="byInstitucional"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
    <!-- Modal -->
    <AdminModalReview :form="form" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import AdminModalReview from '@/components/admin/AdminModalReview'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ColaboradoresForm',
  props: {
    byInstitucional: Boolean,
    idItem: Number,
    viewItem: Boolean
  },
  components: {
    PageTitle, AdminModalReview
  },
  data: function () {
    return {
      pageTitle: `${this.$t('label.colaboradores')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      form: { name: '' },
      statesList: [],
      vocSpecialtiesList: [],
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      }
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    pendingAnalysis: function () {
      return this.form.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    }
  },
  methods: {
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/Contact/${this.form.contact_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    modalValuesSimple: function (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis: async function (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'name':
          this.modalValuesSimple(this.form.name, this.form.log_update.Name, label)
          break
        case 'surname':
          this.modalValuesSimple(this.form.surname, this.form.log_update.Surname, label)
          break
        case 'title':
          this.modalValuesSimple(this.form.title, this.form.log_update.Title, label)
          break
        case 'institution':
          this.modalValuesSimple(this.form.institution, this.form.log_update.Institution, label)
          break
        case 'office':
          this.modalValuesSimple(this.form.office, this.form.log_update.Office, label)
          break
        case 'address':
          this.modalValuesSimple(this.form.address, this.form.log_update.Address, label)
          break
        case 'city':
          this.modalValuesSimple(this.form.city, this.form.log_update.City, label)
          break
        case 'country':
          this.modalValuesSimple(this.form.country, this.form.log_update.Country, label)
          break
        case 'postcode':
          this.modalValuesSimple(this.form.postcode, this.form.log_update.Postcode, label)
          break
        case 'email':
          this.modalValuesSimple(this.form.email, this.form.log_update.Email, label)
          break
        case 'phone':
          this.modalValuesSimple(this.form.phone, this.form.log_update.Phone, label)
          break
        case 'phone2':
          this.modalValuesSimple(this.form.phone2, this.form.log_update.Phone2, label)
          break
        case 'url':
          this.modalValuesSimple(this.form.url, this.form.log_update.Url, label)
          break
        case 'social_media_1':
          this.modalValuesSimple(this.form.social_media_1, this.form.log_update.Social_media_1, label)
          break
        case 'social_media_2':
          this.modalValuesSimple(this.form.social_media_2, this.form.log_update.Social_media_2, label)
          break
        case 'social_media_3':
          this.modalValuesSimple(this.form.social_media_3, this.form.log_update.Social_media_3, label)
          break
        case 'additional_notes':
          this.modalValuesSimple(this.form.additional_notes, this.form.log_update.Additional_notes, label)
          break
        case 'specialty_id':
          newValue = this.vocSpecialtiesList.filter(l => l.specialty_id === this.form.specialty_id)[0]
          oldValue = this.vocSpecialtiesList.filter(l => l.specialty_id === this.form.log_update.Specialty_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
        case 'state_id':
          newValue = this.statesList.filter(l => l.state_id === this.form.state_id)[0]
          oldValue = this.statesList.filter(l => l.state_id === this.form.log_update.State_id)[0]
          this.modalValuesSimple(newValue ? newValue.state : '', oldValue ? oldValue.state : '', label)
          break
      }
    },
    getById: function (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/Contact/${id}`)
        .then(response => {
          this.form = response.data
          this.nameEdit = this.form.surname ? ` ${this.form.surname}` : this.form.name
          this.form.log_update = JSON.parse(this.form.log_update)
        })
    },
    save: function () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.form.log_update = JSON.stringify(this.form.log_update)
      if (this.action === this.$actionEdit) {
        axios.put('/Contact', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.form.log_update = JSON.parse(this.form.log_update)
            this.cancel()
          })
      } else {
        axios.post('/Contact', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/colaboradores')
      }
    },
    getStates: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
        })
    },
    getVocSpecialties: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocSpecialties/getall', { params })
        .then(response => {
          this.vocSpecialtiesList = response.data.items.map(i => {
            const item = {
              specialty_id: i.specialty_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    }
  },
  created: function () {
    this.getStates()
    this.getVocSpecialties()
    if (this.viewItem) {
      this.action = this.$actionEdit
      this.getById(this.idItem)
    } else {
      this.action =
        this.$route.params.id !== this.$actionNew
          ? this.$actionEdit
          : this.$actionNew

      if (this.action === this.$actionEdit) {
        this.pageTitle = `${this.$t('label.colaboradores')} > ${this.$t('label.editando')}:`
        this.getById(this.$route.params.id)
      }
    }
  }
}
</script>
