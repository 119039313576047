<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('comiteCientifico.cabecalhoTitulo') }`" :classProp="'header-single header-comite'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">
          <h2 class="laranja">{{ $t('comiteCientifico.titulo') }}</h2>
          <p>{{ $t('comiteCientifico.paragrafo1') }}</p>

          <p>{{ $t('comiteCientifico.paragrafo2') }} <a href="http://www.cbd.int/decision/cop/?id=7197" target="_blank">http://www.cbd.int/decision/cop/?id=7197</a>).</p>

          <p>{{ $t('comiteCientifico.paragrafo3') }}</p>

          <ul class="mt-2">
            <li>{{ $t('comiteCientifico.marcador1') }}</li>
            <li>{{ $t('comiteCientifico.marcador2') }}</li>
            <li>{{ $t('comiteCientifico.marcador3') }}</li>
            <li>{{ $t('comiteCientifico.marcador4') }}</li>
            <li>{{ $t('comiteCientifico.marcador4b') }}</li>
            <li>{{ $t('comiteCientifico.marcador4c') }}</li>
            <li>{{ $t('comiteCientifico.marcador4d') }}</li>
          </ul>

          <p>{{ $t('comiteCientifico.paragrafo4') }}</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'ComiteCientifico',
  components: { InstitucionalHeaderSingle }
}
</script>
