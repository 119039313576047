<template>
  <span class="edit-label edit-field">
      <input type="text" class="edit-label-input" v-model="item.kingdom" ref="kingdom">
      <ul class="acoes d-flex justify-content-end">
        <li><a @click="salve"><span class="salvar mt-1"></span></a></li>
        <li><a @click="cancel"><span class="cancelar mt-1"></span></a></li>
      </ul>
  </span>
</template>

<script>
export default {
  name: 'EditTaxonomyKingdom',
  props: {
    objKingdom: {}
  },
  data: function () {
    return {
      item: {
        kingdom_id: 0,
        kingdom: ''
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancelEdit')
    },
    salve: function () {
      this.$emit('salveEdit', this.item)
    }
  },
  mounted () {
    this.item = this.objKingdom
    this.$refs.kingdom.focus()
  }
}
</script>
