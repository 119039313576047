<template>
  <span class="add2-label">
      <input type="text" class="add3-label-input" v-model="synonymous.synonymous" ref="synonymous">
      <ul class="acoes d-flex justify-content-end">
        <li><a @click="salve"><span class="salvar mt-1"></span></a></li>
        <li><a @click="cancel"><span class="cancelar mt-1"></span></a></li>
      </ul>
  </span>
</template>

<script>
export default {
  name: 'AddDataSynonyms',
  data: function () {
    return {
      synonymous: {
        synonymous_id: 0,
        synonymous: ''
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancelAddSynonymous')
    },
    salve: function () {
      this.$emit('salveAddSynonymous', this.synonymous)
    }
  },
  mounted () {
    this.$refs.synonymous.focus()
  }
}
</script>
